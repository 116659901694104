import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import TimePicker from "./TimePicker"
import TextArea from "./TextArea"
import AssignSelect from "./AssignSelect"
import InputPassword from "./InputPassword"
const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    TimePicker,
    TextArea,
    AssignSelect,
    InputPassword
}

export default Controls;