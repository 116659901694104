export default { 
  updateExternalQcSubmit: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getExternalAssignedLeads: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getExternalDashboardCount: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getExternalRequestLeads: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
};
