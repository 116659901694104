import { BLOCK_FSE, BLOCK_FSE_ERR, GET_FSE_LEAD, GET_FSE_LEAD_ERR } from "context/actionTypes/FseType"

export default (state, action) => {
    switch (action.type) {
        case BLOCK_FSE:
            return {
                ...state,
                blockFse: action.payload, ...state.data,
            }

        case BLOCK_FSE_ERR:
            return {
                ...state,
                blockFse: action.payload
            }

            case GET_FSE_LEAD:
                return {
                    ...state,
                    fseLead: action.payload, ...state.data,
                }
    
            case GET_FSE_LEAD_ERR:
                return {
                    ...state,
                    fseLead: action.payload
                }

        default:
            return state;
    }

}