import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Icon } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
export default function DatePicker(props) {

    const { name, label, value,error=null,  onChange,disabledFuture, disablePast, minDate, maxDate } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <MuiPickersUtilsProvider  utils={DateFnsUtils}  >
                        
                          <KeyboardDatePicker
                          //  variant="inline"
                           inputVariant="outlined"
                          // disableToolbar
                          style={{marginTop:0}}
                            margin="normal"
                            id="date-picker-dialog" 
                            fullWidth
                            setSelectedDate
                            // maxDate = {new Date()} 
                            // KeyboardButtonProps={{
                            //   'aria-label': 'change date',
                            // }}
                           disableFuture={disabledFuture}
                           disablePast={disablePast}
                           minDate={minDate}
                           maxDate={maxDate}
                            label={label}
                            {...(error && {error:true })}
                            format="dd/MM/yyyy"
                            name={name}
                            value={value}
                            onChange={date =>onChange(convertToDefEventPara(name,date))}
                            keyboardIcon={<ArrowDropDownIcon />}
                          />

                        
                      </MuiPickersUtilsProvider>
    )
}
