import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AuditTableStyle";
import AuditTable from "components/root/audit/auditTable";

const DashboardTable = React.memo((props) => {
  return (
    <>
      <AuditTable />

    </>
  );
});

export default withRouter(DashboardTable);
