export default {
  getLead: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getAnalysis: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getReasonList: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
};
