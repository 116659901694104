import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';
import { localRoute } from "../../routes/localRoutes";
import { styles } from "./OtpStyle";
import { GlobalContext } from "../../context/GlobalState";
import CircularProgress from '@material-ui/core/CircularProgress';
import OtpInput from "react-otp-input";
import { motion } from "framer-motion";
import { otpUser } from "context/actions/userAction/otpUser";
import timeImg from '../../assets/images/time.svg'
import { loginUser } from "context/actions/userAction/loginUser";
import { border } from "@mui/system";

const useStyles = makeStyles(styles);

const OTPPage = React.memo((props) => {
  //console.log('props', props)
  const { userState, userDispatch } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const [counter, setCounter] = useState(59);
  // const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const classes = useStyles();
  const history = useHistory();
  const [otp, setOTP] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(true);

  const handleLogin = async (e) => {
    setMessage("")
    setError(false);
    setCounter(59);
    setLoader(true)
    const postData = {
      number: localStorage.getItem('number'),
      otp: otp
    }
    setTimeout(() => {
      otpUser(postData)(userDispatch)

    }, 1000)
  }

  const onPressEnterLogin = async (e) => {
    if (otp.length !== 4 && e.key === 'Enter') {
      e.preventDefault()
      e.nativeEvent.stopImmediatePropagation();
      return
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  }


  const handleOtpInput = (e) => {
    setOTP(e);
  };

  useEffect(() => {
    // counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    let id;
    if (counter > 0) {
      id = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => clearInterval(id)
  }, [counter]);

  useEffect(() => {
    setLoader(false)
    let status = userState && userState.otpUser && userState.otpUser.data && userState.otpUser.data.status
    if (status === true) {
      if (userState.otpUser.data.data.qc_type == "external" && userState.otpUser.data.data.user_type == "root") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.dashboard)
      }
      else if (userState.otpUser.data.data.qc_type == "internal") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.internalDashboard)
      }

      else if (userState.otpUser.data.data.qc_type == "viewer") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.viewerDashboard)
      }
      else if (userState.otpUser.data.data.qc_type == "agency") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.agencyDashboard)
      }

      else if (userState.otpUser.data.data.qc_type == "external" && userState.otpUser.data.data.user_type == "") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.externalDashboard)
      }

    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      userState.otpUser = {
        loading: false,
        error: null,
        data: null,

      }
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (status === 401) {

      let msg = userState && userState.otpUser && userState.otpUser.data && userState.otpUser.data.data && userState.otpUser.data.data.error && userState.otpUser.data.data.error.errors && userState.otpUser.data.data.error.errors[0].msg
      setCounter(0)
      setMessage(msg || "Opps something went wrong, please try again!")
      userState.otpUser = {
        loading: false,
        error: null,
        data: null,

      }
    }
  }, [userState])

  function validateForm() {
    return otp.length < 4 ? "" : "Min 4 numbers required.";
  }


  const handleResend = () => {
    setMessage("")
    setError(true);
    setCounter(59);
    const postData = {
      number: localStorage.getItem('number'),
    }
    setTimeout(() => {
      loginUser(postData)(userDispatch)

    }, 1000)
  }


  let token = localStorage.getItem("token");
  useEffect(() => {
    //console.log('token')
    if (token) {
      if (localStorage.getItem("qc_type") == "external" && localStorage.getItem("user_type") == "root") {

        history.push(localRoute.dashboard)
      }
      else if (localStorage.getItem("qc_type") == "internal") {

        history.push(localRoute.internalDashboard)
      }
      else if (localStorage.getItem("qc_type") == "viewer") {

        history.push(localRoute.viewerDashboard)
      }

      else if (localStorage.getItem("qc_type") == "external" && localStorage.getItem("user_type") == "") {
        history.push(localRoute.externalDashboard)
      }

    }
  }, [token])


  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={7}
          container
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh', padding: "0px", margin: "0px" }}
        >
          <div className={classes.loginBg}>
          </div>
        </Grid>
        <Grid item xs={5}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh', background: "rgb(246 248 248 / 0%)" }}
        >
          <form className={classes.loginForm} noValidate autoComplete="off">
            <Grid>
              <div className={classes.loginTitle}> Verify OTP </div>
            </Grid>
            <Grid>


              {error ? (
                <p>
                  We are sending you an OTP to verify your phone number
                </p>
              ) : (
                <motion.p
                  animate={{
                    textAlign: "center",
                    x: [0, 0, 0, 0, 100, 0],
                    transition: { duration: 0.5 },
                  }}
                  style={{ color: "red", fontSize: "18px", fontWeight: "600" }}
                >
                  {message}
                </motion.p>
              )}
            </Grid>


            <Grid xs={6} spacing={0}>
              <div onKeyDown={e => onPressEnterLogin(e)}>
                <OtpInput
                  numInputs={4}
                  value={otp}
                  onChange={handleOtpInput}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 10px 0 0",
                    fontSize: "16px",
                    borderRadius: 4,
                    border: error ? "1px solid rgba(0,0,0,0.3)" : "1px solid red",
                    color: "black",
                  }}
                />
              </div>
              <br />

              {/* <TextField xs={6} id="filled-basic" value={otp} onChange={event => handleChangeNumberInput(event)} label="OTP" InputProps={{ classes }} variant="filled" /> */}
            </Grid>

            <Grid xs={12} item style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
              <Button variant="contained" onClick={(e) => handleLogin(e)}
                disabled={!validateForm()}
                size="medium"
                style={{ color: '#fff', background: '#F99746', float: 'end' }}
              // className={classes}
              >
                {
                  loader ? <CircularProgress style={{ color: '#fff' }} size={20} /> : "Verify OTP"
                }

              </Button>

              <Link to="/" style={{ textDecoration: "none" }}  >
                <div style={{ color: "#602994", fontSize: "16px", fontWeight: 700, }} >Back to Login Page</div>
              </Link>

            </Grid>



            <Grid xs={12} container style={{ textAlign: 'center' }}>
              <img
                src={timeImg}
                alt="ott"
                className="timer_logo"
              />
              <p className="timer_text">  &nbsp; 00:{counter}</p>
            </Grid>

            <p className="otp_query">
              Didn't receive the code?
              <span
                className={counter === 0 ? classes.otp_resend : classes.otp_resend1}
                // style={{co}}
                onClick={handleResend}
              >
                &nbsp; Resend OTP
              </span>
            </p>


          </form>
        </Grid>
      </Grid>
    </>
  );
});

export default withRouter(OTPPage);
