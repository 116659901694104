import React, { useEffect, useState, useContext, useMemo } from "react";
import PropTypes from 'prop-types';
import { withRouter, Link, useHistory } from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { styles } from "./EditAuditStyle";
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import { useForm, Form } from '../../../useForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@material-ui/core/TextField';
import moment from "moment";
import FormControl from '@mui/material/FormControl';
import Controls from "../../../controls/Controls";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from "@material-ui/core/ListItemText";
import { GlobalContext } from "context/GlobalState";
import SuccessNotification from "../../../popUpNotification/SuccessNotification";
import FailureNotification from "../../../popUpNotification/FailureNotification";
import { updateAudit } from "context/actions/auditAction/updateAudit";
import { getAudit } from "context/actions/auditAction/getAudit";


const useStyles = makeStyles(styles);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};

const QCOptions = () => ([
    { id: 'internal', title: 'Internal' },
    { id: 'external', title: 'External' }
])


const userTypeOptions = () => ([
    { id: 'normal', title: 'Normal' },
    { id: 'root', title: 'Root' },
    { id: 'viewer', title: 'Viewer' }
])


const EditAudit = (props) => {
    const classes = useStyles();
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');
    const [loader, setLoader] = useState(false);
    const { auditState, auditDispatch } = useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const [failureOpen, setFailureOpen] = React.useState(false);
    const [failureMessage, setFailureMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const initialFValues = Object.freeze({
        full_name: '',
        number: '',
        email_id: '',
        qc_type: '',
        user_type: ''
    });


    // console.log('props.qcAssociateID', props.qcAssociateID)
    useEffect(() => {
        console.log('props.qcAssociateID',props.qcAssociateID)
        if(props.qcAssociateID)
        {
        getAudit(props.qcAssociateID)(auditDispatch)
    }
    }, [props.qcAssociateID])

    useEffect(() => {
        console.log('auditState', auditState.getAudit)
        let status = auditState.getAudit && auditState.getAudit.data && auditState.getAudit.status
        if (status == true) {
            if (auditState.getAudit.data.user_type == '') {
                setValues({
                    ...values,
                    user_type: 'normal',
                    full_name: auditState.getAudit.data.full_name,
                    number: auditState.getAudit.data.number,
                    email_id: auditState.getAudit.data.email_id,
                    qc_type: auditState.getAudit.data.qc_type,
                })
            }
            else {

                setValues({
                    ...values,
                    user_type: auditState.getAudit.data.user_type,
                    full_name: auditState.getAudit.data.full_name,
                    number: auditState.getAudit.data.number,
                    email_id: auditState.getAudit.data.email_id,
                    qc_type: auditState.getAudit.data.qc_type,
                })

            }
            auditState.getAudit = {
                loading: false,
                error: null,
                data: null,
            }
        }

        console.log("auditState.getAudit", auditState.getAudit)
        if (status === 401) {
            // setLoader(false)

            auditState.getAudit = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [auditState])



    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('full_name' in fieldValues)
            temp.full_name = fieldValues.full_name ? "" : "This field is required."
        if ('email_id' in fieldValues)
            temp.email_id = fieldValues.email_id ? "" : "This field is required."
        if ('qc_type' in fieldValues)
            temp.qc_type = fieldValues.qc_type ? "" : "This field is required."
        if ('user_type' in fieldValues)
            temp.user_type = fieldValues.user_type ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handlenumberChange,
        handlepinnumberChange,
        handleaddressChange,
        handlegstnumberChange,
        // handleCapture,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setLoader(true)
            let postData = {
                id: props.qcAssociateID,
                full_name: values.full_name,
                email_id: values.email_id,
                qc_type: values.qc_type,
                // user_type: values.user_type,
                // projects: "amazon_sticker",
                // status: "active"
            }
            if (values.user_type == "normal") {
                postData.user_type = ''
            }
            else {
                postData.user_type = values.user_type
            }

            updateAudit(postData)(auditDispatch)
        }
    }

    useEffect(() => {
        console.log('auditState', auditState.updateAudit)
        let status = auditState.updateAudit && auditState.updateAudit.data && auditState.updateAudit.status
        if (status == true) {
            setLoader(false)
            setSuccessMessage('Associate Updated SuccessFully')
            console.log('res.data.message', auditState.updateAudit.data)
            handleNotificationClickOpen()
            auditState.updateAudit = {
                loading: false,
                error: null,
                data: null,
            }
        }

        console.log("auditState.updateAudit", auditState.updateAudit)
        if (status === 401) {
            // setLoader(false)

            auditState.updateAudit = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [auditState])


    const handleNotificationClickOpen = () => {
        setOpen(true);
    };

    const handleNotificationClose = () => {
        setOpen(false);
        close()
    };

    const handleFailureNotificationClickOpen = () => {
        setFailureOpen(true);
    };

    const handleFailureNotificationClose = () => {
        setFailureOpen(false);
    };

    const close = () => {
        props.handleCloseModal()
        resetForm()
    }
    return (
        <div>
            <Dialog
                open={props.openModal}
                onClose={close}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>

                            <Grid item xs={4}>
                                <div style={{ fontSize: '24px' }}><b>Edit Auditor</b></div>
                            </Grid>



                            <Grid xs={12} className={classes.inputAlign} item>
                                <Controls.Input
                                    label="Full Name *"
                                    name="full_name"
                                    value={values.full_name}
                                    onChange={handleInputChange}
                                    error={errors.full_name}
                                />
                            </Grid>


                            <Grid xs={12} className={classes.inputAlign} item>
                                <Controls.Input
                                    label="Number *"
                                    name="number"
                                    disabled={true}
                                    value={values.number}
                                    onChange={handlenumberChange}
                                    error={errors.number}
                                />
                            </Grid>


                            <Grid xs={12} className={classes.inputAlign} item>
                                <Controls.Input
                                    label="Email*"
                                    name="email_id"
                                    value={values.email_id}
                                    onChange={handleInputChange}
                                    error={errors.email_id}
                                />
                            </Grid>


                            <Grid xs={12} className={classes.inputAlign} item>

                                <Controls.Select
                                    name="qc_type"
                                    label="QC Type *"
                                    fullWidth
                                    value={values.qc_type}
                                    onChange={handleInputChange}
                                    options={QCOptions()}
                                    error={errors.qc_type}
                                />

                            </Grid>

                            <Grid xs={12} className={classes.inputAlign} item>

                                <Controls.Select
                                    name="user_type"
                                    label="User Type *"
                                    fullWidth
                                    value={values.user_type}
                                    onChange={handleInputChange}
                                    options={userTypeOptions()}
                                    error={errors.user_type}
                                />

                            </Grid>




                            <Grid item justifyContent="flex-end" style={{ display: "flex" }} sm={12} >
                                <Button
                                    type="submit"
                                    style={{ textTransform: 'none', color: "#fff", padding: '8px 40px', background: '#141e2d' }}
                                    size="large"
                                >
                                    {
                                        loader ? <CircularProgress color='white' style={{ padding: '2px 10px' }} size={20} /> : "Save"
                                    }
                                </Button>
                            </Grid>
                        </Grid>

                    </Form>
                </DialogContent>
            </Dialog>
            <SuccessNotification
                open={open}
                msg={successMessage}
                handleClose={handleNotificationClose}
            />
            <FailureNotification
                open={failureOpen}
                msg={failureMessage}
                handleClose={handleFailureNotificationClose}
            />
        </div >
    )
}

export default EditAudit