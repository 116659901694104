import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from "./FseTable.module.css";
import { Button } from "@mui/material";
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import SearchBar from "material-ui-search-bar";
import { getFseLead } from 'context/actions/fseAction/getFseLead';
import { GlobalContext } from 'context/GlobalState';
import { LinearProgress } from '@material-ui/core';
import BlockFse from "./BlockFse";
const StyledTableCell = withStyles((theme) => ({
    head: {
        border: 'none',
        color: '#888888',
        background: '#F2F2F2'
    },
    body: {
        fontSize: 14,
        //border: 'none'
    },
}))(TableCell);

const ExternalTable = () => {
    const [tableData, setTableData] = useState({})
    const [data, setData] = useState('');
    const [searched, setSearched] = useState("");
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const [blockFSEopen, setBlockFSEopen] = useState(false);
    const [leadId,setLeadId] =useState('')
    const { fseState, fseDispatch } = useContext(GlobalContext);

    const handleBlockFSEClose = () => {
        setBlockFSEopen(false);
        console.log('leadId',leadId)
        getFseLead(leadId)(fseDispatch)
      };

    useEffect(() => {

        let status = fseState.fseLead.data && fseState.fseLead.status
        if (status == true) {
            console.log('fseState.fseLead.data', fseState.fseLead.data)
            setLoader(false)
if(fseState.fseLead.data.length!=0)
{
            setTableData(fseState.fseLead.data[0])
            setMessage('')
}
else
{
    setTableData({})
    setMessage('No Lead Data Found')
}
            fseState.fseLead = {
                loading: false,
                error: null,
                data: null,
            }
        }
        if (status === 500) {
            setLoader(false)
            setTableData({})
            setMessage('No Lead Data Found')

            fseState.fseLead = {
                loading: false,
                error: null,
                data: [],
            }
        }

        if (status === 401) {
            setLoader(false)

            fseState.fseLead = {
                loading: false,
                error: null,
                data: [],
            }
        }
    }, [fseState])

    const requestSearch = (e) => {
        
        console.log('e',e)
    const onlyNums = e.replace(/[^0-9]/g, '');
   
    console.log('onlyNums',onlyNums)
      setSearched(onlyNums)
    
    };

    const cancelSearch = () => {
        setTableData({})
        setSearched("");
       
    };


    const updateBlock =(status)=>{
        setData(status)
        setBlockFSEopen(true);
    }

    return (
        <>
            <div className={styles.row2}>
                <div >


                    <Grid item xs={12} container spacing={2} style={{display: "flex",justifyContent: "end"}}>

                        <div >
                            <SearchBar
                                 value={searched}
                                placeholder='Search Lead ID'
                                 onChange={requestSearch}
                                onCancelSearch={() => cancelSearch()}
                                style={{ border: "0.5px solid #b2b2b2" }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        console.log(e.target.value);
                                        setLoader(true)
                                        setMessage('')
                                        setLeadId(e.target.value)
                                        getFseLead(e.target.value)(fseDispatch)
                                    }
                                }}
                            />

                        </div>



                    </Grid><br />
                    {loader==true &&
                    <LinearProgress />}
                    {Object.keys(tableData).length!=0 && tableData!=null &&
                    <Grid item xs={12} style={{ width: '100%', overflowX: 'auto', display: 'inline-grid' }}>
                        <TableContainer sx={{ maxHeight: 550 }}>
                            <Table aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>
                                    <StyledTableCell align='left' style={{ minWidth: "80px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Lead ID
                                        </StyledTableCell >
                                        <StyledTableCell align='left' style={{ minWidth: "80px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            User ID
                                        </StyledTableCell >
                                        <StyledTableCell align='left' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            FSE Name
                                        </StyledTableCell >

                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            FSE Number
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Agency ID
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Agency Name
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            TL ID
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            TL Name
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            TL Number
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Supervisor ID
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Supervisor Name
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Supervisor Number
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Fse Block Status
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Action
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>


                                    <TableRow hover role="checkbox" tabIndex={-1} key={tableData.user_id}>
                                    <StyledTableCell align="left">
                                            {tableData.lead_id}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {tableData.user_id}
                                        </StyledTableCell>

                                        <StyledTableCell
                                            align="left"
                                        >
                                            {tableData.full_name}

                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {tableData.mobile_number}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {tableData.agency_id}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {tableData.agency_name}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {tableData.tl_id}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {tableData.tl_name}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {tableData.tl_number}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            {tableData.supervisor_id}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {tableData.supervisor_name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {tableData.supervisor_number}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {tableData.user_block_status}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                        {tableData.user_block_status=="no" ?  
                                           <Button  style={{ textTransform: 'none', padding: '8px 40px', background: "black", color: "white" }}
                                    size="medium" onClick={()=>updateBlock(tableData.mobile_number)  }>Block</Button>:"NA"}
                    
                                        </StyledTableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                       
                        </TableContainer>
                        
                    </Grid>
                    }
                    <h3 style={{textAlign:'center'}}>{message}</h3>

                    <BlockFse
        open={blockFSEopen}
        handleClose={handleBlockFSEClose}
        data={data}
      />

                </div>

            </div>
        </>
    )
}

export default ExternalTable