import React, { useEffect, useState,useContext } from 'react';
import PropTypes from 'prop-types';
import styles from "./ExternalTable.module.css";
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import SearchBar from "material-ui-search-bar";
import { Card, CardContent } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@material-ui/core/Box';
// import { externalLeadsReport, getQcdashboardCount } from '../../utils';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import { GlobalContext } from "context/GlobalState";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment'
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { getExternalReportStats } from 'context/actions/rootAction/getExternalReportStats';
import { getQcDashboardCount } from 'context/actions/rootAction/getQcDashboardCount';
import XLSX from 'xlsx';
import Button from '@material-ui/core/Button'
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const StyledTableCell = withStyles((theme) => ({
    head: {
        border: 'none',
        color: '#888888',
        background: '#F2F2F2'
    },
    body: {
        fontSize: 14,
        //border: 'none'
    },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));


function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};




const ExternalTable = () => {
    const [tableData, setTableData] = useState([])
    const [value, setValue] = React.useState(new Date());
    const [rows, setRows] = useState(tableData);
    const [searched, setSearched] = useState("");
    const [countData, setCountData] = useState([]);
    const [noData, setNoData] = useState(false);
    const [dir, setDir] = useState(true);
    const { rootState, rootDispatch } = useContext(GlobalContext);
    const [auditedLeadDirection, setAuditedLeadDirection] = useState(true)
    const [greenDirection, setGreenDirection] = useState(true);
    const [yellowDirection, setYellowDirection] = useState(true)
    const [redDirection, setRedDirection] = useState(true);
    const [accuracyDirection, setAccuracyDirection] = useState(true)
    const [auditedCount, setAuditedCount] = useState(0);
    const [correctedCount, setCorrectedCount] = useState(0);
    const [correctedRange, setCorrectedRange] = useState(0);

    const handleDirection = (column, direc) => {
        if(column!=='audited_leads') setAuditedLeadDirection(true);
        if(column!=='green') setGreenDirection(true);
        if(column!=='yellow') setYellowDirection(true);
        if(column!=='red') setRedDirection(true);
        if(column!=='accuracy') setAccuracyDirection(true);
        
        setDir(!dir);
        let ot;
        if (!direc) {
            ot = [...tableData].sort((a, b) => (a[column] - b[column]));
        } else {
            ot = [...tableData].sort((a, b) => (b[column] - a[column]))
        }
        setRows(ot)

    }


    const requestSearch = (searchedVal) => {
        const filteredRows = tableData.filter((row) => {
            return row.full_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.stage_1_qc_admin_id.toString().toLowerCase().includes(searchedVal.toLowerCase()) || row.number.toString().toLowerCase().includes(searchedVal.toLowerCase()) || row.audited_leads.toString().toLowerCase().includes(searchedVal.toLowerCase())
        });
        setRows(filteredRows);
        setNoData(true)
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {

        let date = moment(value).format('YYYY-MM-DD')
        getExternalReportStats({date:date})(rootDispatch)
        // getQcDashboardCount({startDate:date,endDate:date,vendorId: selectedAgency})(rootDispatch)
    }, [])

    useEffect(() => {
        console.log('rootState',rootState.getExternalReportStats)
        let status =rootState.getExternalReportStats && rootState.getExternalReportStats.data && rootState.getExternalReportStats.status
        if (status == true) {
            setTableData(rootState.getExternalReportStats.data);
            setRows(rootState.getExternalReportStats.data)
            let auditedCount = 0
            rootState.getExternalReportStats.data.map((item)=>{
                auditedCount = Number(auditedCount) +Number(item.audited_leads)
            })

            let correctedLeadsCount = 0
            let correctedCount = 0
            rootState.getExternalReportStats.data.map((item)=>{
                
                correctedLeadsCount = Number(correctedLeadsCount) +Number(item.re_audit_leads)
                correctedCount  = Number(correctedCount)+ Number(item.corrected_value)
            })

            setAuditedCount(auditedCount)
            setCorrectedCount(correctedLeadsCount)

            let correction_ratio = (Number(auditedCount) - Number(correctedCount)) * 100 / Number(auditedCount)
            setCorrectedRange(correction_ratio)
            if (rootState.getExternalReportStats.data.length === 0) {
                setNoData(true);
            } else {
                setNoData(false);
            }
            rootState.getExternalReportStats = {
                loading: false,
                error: null,
                data: null,
            }
        }

        console.log("rootState.getExternalReportStats", rootState.getExternalReportStats)
        if (status === 401) {
            // setLoader(false)
           
            rootState.getExternalReportStats = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [rootState])


    const handleChange = (newValue) => {
        setValue(newValue);
        setTableData([]);
        setRows([])
        let date = moment(newValue).format('YYYY-MM-DD')
        getExternalReportStats({date:date})(rootDispatch)
        // getQcDashboardCount({startDate:date,endDate:date,vendorId: selectedAgency})(rootDispatch)
    };
    const downloadExcel = () => {
    
        let finalHeader = ["ID","Auditor Name","Auditor Email","Mobile Number","Audited Leads","Green Leads","Yellow Leads","Red Leads","Reaudited Leads","Accuracy"]
        const records = []
        rows && rows.map((item, index) => {
          const innerData = []
    
          innerData.push(item.stage_1_qc_admin_id, item.full_name,item.email_id, item.number, item.audited_leads,item.green,item.yellow,item.red,item.re_audit_leads,item.accuracy)
          records.push([...innerData])
        })
        const data = [finalHeader, ...records]
        const newData = records;
        const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 })
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "External Leads Report")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        let filterStartDate = moment(value).format('DD-MM-YYYY')
       
 
          XLSX.writeFile(workBook,"External-lead-Report.xlsx")
      }
    return (
        <>
            <div className={styles.row2}>
                <div >
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10px", alignItems: "center", gap: "10px" }}>
                        <div style={{ display: "flex", gap: "10px" }} >
                            <div style={{ height: "20px", width: "20px", borderRadius: "50%", background: "#E757AE" }} ></div>
                            <div style={{ fontWeight: "600" }} >Stage 1</div>
                        </div>
                        <div style={{ display: "flex", gap: "10px" }}  >
                            <div style={{ height: "20px", width: "20px", borderRadius: "50%", background: "#9152ED" }} ></div>
                            <div style={{ fontWeight: "600" }} >Stage 2</div>
                        </div>
                    </div>

                    <Grid item xs={12} container spacing={2} style={{margin:0}}>
                            <Grid item xs={3} item>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Select Date"
                                            inputFormat="dd/MM/yyyy"
                                            value={value}
                                            maxDate={new Date()}
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={5} item>
                                <div style={{ color: "white", textAlign: "center", background: 'grey', fontWeight: "600", padding: "10px", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", height: '50%' }} >
                                    <Grid item xs={12} container spacing={2}>
                                        <Grid item xs={4} item>
                                            <div>
                                                <div>{auditedCount}</div>
                                                <hr style={{margin:0}}/>
                                                <div style={{ fontSize: 12 }}>Audited Leads</div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} item >
                                            <div>
                                                <div>{correctedCount}</div>
                                                <hr style={{margin:0}}/>
                                                <div style={{ fontSize: 12 }}>Re-Audited Leads</div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} item>
                                            <div>
                                                <div>{correctedRange.toFixed(2)}%</div>
                                                <hr style={{margin:0}}/>
                                                <div style={{ fontSize: 12 }}>Accuracy Range</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={4} item >
                            <div style={{ width: "100%", display: "flex",  justifyContent: "flex-end", }} >
                                    <SearchBar
                                        value={searched}
                                        onChange={(searchVal) => requestSearch(searchVal)}
                                        onCancelSearch={() => cancelSearch()}
                                        style={{ border: "0.5px solid #b2b2b2" }}
                                    />
                                    &nbsp;
                                    <div style={{ display: "flex", justifyContent: "flex-end", }} >
                                        <Button type="button" variant="outlined" size="small" onClick={() => downloadExcel()}startIcon={<FileDownloadIcon />}>
                                           Download</Button></div>
                                </div>
                                 

                            </Grid>
                        </Grid>
                    <Grid item xs={12} style={{ width: '100%', overflowX: 'auto', display: 'inline-grid' }}>
                        <TableContainer sx={{ maxHeight: 550 }}>
                            <Table aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align='left' style={{ minWidth: "80px", color: 'black', fontWeight: 700,backgroundColor:'rgb(136 136 136 / 55%)' }} >
                                            ID
                                        </StyledTableCell >
                                        <StyledTableCell align='left' style={{ minWidth: "130px", color: 'black', fontWeight: 700,backgroundColor:'rgb(136 136 136 / 55%)'  }} >
                                            Auditor Name
                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700,backgroundColor:'rgb(136 136 136 / 55%)'  }} >
                                            Email ID
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700,backgroundColor:'rgb(136 136 136 / 55%)'  }} >
                                            Mobile Number
                                        </StyledTableCell>

                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700,backgroundColor:'rgb(136 136 136 / 55%)'  }}  >
                                            <div style={{ display: 'flex', alignItems: "center" }}>
                                                <div>Audited Leads</div>
                                                <div><TableSortLabel
                                                    active={true}
                                                    direction={auditedLeadDirection ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setAuditedLeadDirection(!auditedLeadDirection)
                                                        handleDirection('audited_leads', auditedLeadDirection)
                                                    }}

                                                ></TableSortLabel></div>
                                            </div>


                                        </StyledTableCell>

                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: '#FFFFFF', fontWeight: 700, background: "#E757AE" }} >
                                            Green<TableSortLabel
                                                active={true}
                                                direction={greenDirection ? 'asc' : 'desc'}
                                                onClick={() => {
                                                    setGreenDirection(!greenDirection)
                                                    handleDirection('green', greenDirection)
                                                }}

                                            ></TableSortLabel>

                                        </StyledTableCell>

                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: '#FFFFFF', fontWeight: 700, background: "#E757AE" }} >
                                            Yellow<TableSortLabel
                                                active={true}
                                                direction={yellowDirection ? 'asc' : 'desc'}
                                                onClick={() => {
                                                    setYellowDirection(!yellowDirection)
                                                    handleDirection('yellow', yellowDirection)
                                                }}

                                            ></TableSortLabel>

                                        </StyledTableCell>

                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: '#FFFFFF', fontWeight: 700, background: "#E757AE" }} >
                                            Red<TableSortLabel
                                                active={true}
                                                direction={redDirection ? 'asc' : 'desc'}
                                                onClick={() => {
                                                    setRedDirection(!redDirection)
                                                    handleDirection('red', redDirection)
                                                }}

                                            ></TableSortLabel>

                                        </StyledTableCell>

                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: '#FFFFFF', fontWeight: 700, background: "#9152ED" }} >
                                        Re-Audited Leads
                                    </StyledTableCell > 

                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: '#FFFFFF', fontWeight: 700, background: "#9152ED" }} >
                                            Accuracy<TableSortLabel
                                                active={true}
                                                direction={accuracyDirection ? 'asc' : 'desc'}
                                                onClick={() => {
                                                    setAccuracyDirection(!accuracyDirection)
                                                    handleDirection('accuracy', accuracyDirection)
                                                }}

                                            ></TableSortLabel>

                                        </StyledTableCell >
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rows && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row, index) => {
                                        return (

                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                                                <StyledTableCell align="left">
                                                    {row.stage_1_qc_admin_id}
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align="left"
                                                >
                                                    {row.full_name}

                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.email_id}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.number}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.audited_leads}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.green}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.yellow}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.red}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                {row.re_audit_leads}
                                            </StyledTableCell>

                                                <StyledTableCell align="center">

                                                    <div style={{
                                                        color: row.accuracy >= 85 ? "green" : row.accuracy <= 70 ? "red" : 'rgb(190 170 60)'
                                                    }} >
                                                        {row.accuracy.toFixed(2)}%
                                                    </div>
                                                </StyledTableCell>

                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {noData === false &&
                                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}

                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        style={{ borderBottom: "none" }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </div>
                            }
                               {(rows.length === 0 && noData === false) ?
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                            :
                            rows.length==0 && <div style={{textAlign:'center',marginTop:10}}><h4><b>No Data Found</b></h4></div>}
                        </TableContainer>
                    </Grid>
                </div>

            </div>
        </>
    )
}

export default ExternalTable