import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./InternalDashboardTableStyle";
import InternalDashboard from "components/internal/internalDashboard";

const DashboardTable = React.memo((props) => {
  return (
    <>
      <InternalDashboard />

    </>
  );
});

export default withRouter(DashboardTable);
