import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Button, TextField } from '@material-ui/core';
import { localRoute } from "../../routes/localRoutes";
import loginBgImage from '../../assets/images/loginBgImage.svg'
import { styles } from "./LoginStyle";
import { GlobalContext } from "../../context/GlobalState";
import { loginUser } from '../../context/actions/userAction/loginUser'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import { Link } from "@material-ui/core";

const useStyles = makeStyles(styles);

const Login = React.memo((props) => {
  //console.log('props', props)
  const { userState, userDispatch } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  // const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const classes = useStyles();
  const history = useHistory();
  const [mobileNumber, setMobileNumber] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const handleLogin = async (e) => {
    setMessage("")
    setLoader(true)
    const postData = {
      number: mobileNumber
    }
    setTimeout(() => {
      loginUser(postData)(userDispatch)
    }, 1000)
  }

  const onPressEnterLogin = (e) => {
    if (mobileNumber.length < 10 && e.key === 'Enter') {
      e.preventDefault()
      e.nativeEvent.stopImmediatePropagation();
      return;
    }


    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  }

  const handleChangeNumberInput = (event) => {

    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length <= 10) {
      setMobileNumber(event.target.value)
    }
  }



  useEffect(() => {
    setLoader(false)
    let status = userState.loginUser && userState.loginUser.data && userState.loginUser.data.status
    // SUCCESS RESPONSE
    //console.log(' userState.loginUser', userState.loginUser)
    if (status === true) {
      localStorage.setItem('number', mobileNumber)

      history.push(`${localRoute.otp}`)
      userState.loginUser = {
        loading: false,
        error: null,
        data: null,
      }

    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      userState.loginUser = {
        loading: false,
        error: null,
        data: null,

      }
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (status === 401) {

      let msg = userState && userState.loginUser && userState.loginUser.data && userState.loginUser.data.data && userState.loginUser.data.data.error && userState.loginUser.data.data.error.errors && userState.loginUser.data.data.error.errors[0].msg
      setMessage(msg || "Opps something went wrong, please try again!")
      userState.loginUser = {
        loading: false,
        error: null,
        data: null,

      }
    }
  }, [userState])

  function validateForm() {
    return mobileNumber.length < 10 ? "" : "Min 10 numbers required.";
  }

  let token = localStorage.getItem("token");
  useEffect(() => {
    //console.log('token')
    if (token) {
      if (localStorage.getItem("qc_type") == "external" && localStorage.getItem("user_type") == "root") {

        history.push(localRoute.dashboard)
      }
      else if (localStorage.getItem("qc_type") == "internal") {

        history.push(localRoute.internalDashboard)
      }

      else if (localStorage.getItem("qc_type") == "external" && localStorage.getItem("user_type") == "") {
        history.push(localRoute.externalDashboard)
      }

      else if (localStorage.getItem("qc_type") == "viewer") {

        history.push(localRoute.viewerDashboard)
      }

      else if (localStorage.getItem("qc_type") == "agency") {

        history.push(localRoute.agencyDashboard)
      }
    }
  }, [token])


  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={8}
          container
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh', padding: "0px", margin: "0px" }}
        >
          <div className={classes.loginBg}>
          </div>
        </Grid>
        <Grid item xs={4}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh', background: "rgb(246 248 248 / 0%)" }}
        >
          <form className={classes.loginForm} noValidate autoComplete="off">
            <Grid spacing={0}>
              <div className={classes.loginTitle}>Login</div>
            </Grid>
            <Grid xs={6} spacing={0}>
              <TextField xs={6} id="filled-basic" value={mobileNumber} onChange={event => handleChangeNumberInput(event)}
                onKeyDown={(event) => onPressEnterLogin(event)}
                label="Mobile Number" InputProps={{ classes }} variant="filled" />
            </Grid>

            <Grid xs={6} spacing={0}>
              <Button variant="contained" onClick={(e) => handleLogin(e)}
                disabled={!validateForm()}
                size="large"
                className={classes.loginBtn}
              >
                {
                  loader ? <CircularProgress style={{ color: '#fff' }} size={24} /> : "Login"
                }

              </Button>
            </Grid>

            <div className={classes.errorMsg}>
              {message}
            </div>
          </form>
        </Grid>
      </Grid>
    </>
  );
});

export default withRouter(Login);
