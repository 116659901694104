import { GET_ANALYSIS, GET_LEAD,GET_LEAD_ERR ,GET_ANALYSIS_ERR, GET_REASON_LIST, GET_REASON_LIST_ERR} from '../../actionTypes/leadType';

export default (state, action) => {
  switch (action.type) {
    case GET_LEAD:
      return {
        ...state,
        getLead: action.payload, ...state.data,
      }

    case GET_LEAD_ERR:
      return {
        ...state,
        getLead:  action.payload 
      }

      case GET_ANALYSIS:
        return {
          ...state,
          getAnalysis: action.payload, ...state.data,
        }
  
      case GET_ANALYSIS_ERR:
        return {
          ...state,
          getAnalysis:  action.payload 
        }

      case GET_REASON_LIST:
        return {
          ...state,
          getReasonList: action.payload, ...state.data,
        }
  
      case GET_REASON_LIST_ERR:
        return {
          ...state,
          getReasonList:  action.payload 
        }
    default:
      return state;
  }
}
