import { alpha, makeStyles } from '@material-ui/core/styles';
// import LeftImage from '../../../assets/images/left.svg'
// import TopImage from '../../../assets/images/top.svg'
const drawerWidth = 200;
export const styles = (theme) => ({
  // root: {
  //   // textAlign: "center",
  //   display: "flex",
  //   flexDirection: "column",
  //   minHeight: "100vh",
  //   color: "#000133",
  // },
  // appBar: {
  //   background: "#602994",
  //   color: "#FFFFFF",
  //   // height:"60px"
  // },


  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // background: "#693695",
    background: "#121E2E",
    // backgroundImage:`url(${TopImage})`,
    boxShadow: "none",
    height: 40,
    // lineHeight:50,
    fontSize: 12,
    // borderBottom:"1px solid #ddd",
    color: "#f0f0f0",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },

  icon: {
    padding: "10px",
  },
  title: {
    margin: "left",
  },
  container: {
    display: "flex",
    flex: 1,
  },



  // drawer: {
  //   background: "#7946A9",
  //   color: "#fff",
  //   position: "static",
  //   transition: "width .3s",
  //   width: drawerWidth,
  //   flexShrink: 0,
  //   // marginTop:"-4px",
  // },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: 50,
    // background:'#5848af',

  },

  drawerPaper: {
    width: drawerWidth,
    background: '#f6f7f0',
    fontWeight: 700,
    // backgroundImage:`url(${LeftImage})`,
    // color: '#fff',
    borderRight: '0.5px solid #ddd',
    fontSize: 13,
    marginTop: 40,
    // paddingLeft:100,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },


  closed: {
    width: "0px",
  },
  opened: {
    width: "240px",
  },
  main: {
    flex: 1,
    background: "#f7f5f5",
    color: "black",

  },
  footer: {
    background: "#00022E",
    height: "50px",
    color: "#FC86AA",
  },
  drawerIcon: {
    fontWeight: 900,
    marginLeft: "2px",
    color: '#131e2e',
    marginTop: 0,
    // padding:'0px 2px -4px 2px',
    "& svg": {
      fontSize: "18px",
    }
  },
  drawerMenuIten: {
    marginLeft: "6px",
    color: '#131e2e',
    // padding:'0px 2px -4px 2px',
    marginTop: -1,
    "& span": {
      // color: "#f0b94b",
      fontSize: "13px",
      fontWeight: 500,
      paddingBottom: -4,
    },

  },
  drawerList: {
    margin: 0,
    padding: 0,
    fontWeight: 700,
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'scroll',
      marginBottom: "revert",
    },
    marginTop: 30
  },
  logoImage: {
    height: "28px",
    marginTop: "17px",
  },

  large: {
    height: "30px",
    width: "30px",
    background: '#fff',
    color: '#ff9700',
    fontWeight: 600

  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  overrides: {
    RaMenuItemLink: {
      root: {
        color: "#c51162",
      },
      active: {
        color: "#ff4081",
      },
    },
  },

  active:
  {
    background: 'red'
  }
});