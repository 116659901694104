
import React, { useEffect, useState, useContext } from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core/";
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import MuiListItem from "@material-ui/core/ListItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import moneyPerson from "../../assets/images/moneyPerson.svg"
import Logo from '../../assets/images/logo1.png'
import Navigation from "../../components/navigation";
import { localRoute } from "../../routes/localRoutes";
import RecommendIcon from '@mui/icons-material/Recommend';
import { withRouter, useHistory, Link } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useConfirm } from "material-ui-confirm";
import { logOut } from "../../context/actions/userAction/logOut";
import { GlobalContext } from "../../context/GlobalState";
import { styles } from "./DrawerStyle";
import "./Font.css";
// import { getPortalUser } from "context/actions/portalUserAction/getPortalUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onMessageListener } from "../../firebaseInit";
//console.log("baseUrl", baseUrl)
const useStyles = makeStyles(styles);

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#fff2bf",
      margin: '0px 5px 0px 5px',
      borderRadius: '2px',
      border: '0.3px solid #ddd',
      color: "#121E2E !important",
      "& .MuiListItemIcon-root": {
        color: "#121E2E",
        // paddingBottom: 2,
      },
      "&& .makeStyles-drawerMenuIten-19 span":
      {
        fontWeight: 700,
        color: "#121E2E !important",
      }
    },
  },
  selected: {}
})(MuiListItem);



const DrawerUI = (props) => {
  // const { history } = props;


  const { userState, userDispatch } = useContext(GlobalContext);
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const confirm = useConfirm();
  const [isOpened, setIsOpened] = useState(true);
  const [token, setToken] = useState();

  const [pageTitle, setPageTitle] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  // const { portalUserState, portalUserDispatch } = useContext(GlobalContext);
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const userDesignation = localStorage.getItem("token_key_2")
  const [loginName, setLoginName] = React.useState('');
  const [loginRole, setLoginRole] = React.useState('');
  const [loginProfile, setLoginProfile] = React.useState('https://taskmotech.s3.amazonaws.com/d62a79b546c510fee7c51637821113239_Profile_avatar_placeholder_large.png');
  const [notificationList, setNotificationList] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [loadingLoop, setLoadingLoop] = useState(true);
  const [badgeCount, setBadgeCount] = useState('0');
  const { notificationState, notificationDispatch } = useContext(GlobalContext);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [messageCount, setMessageCount] = useState('0');
  const [notification, setNotification] = useState({ title: "", body: "" });

  console.log('show', show, notification);
  const itemsList1 = [
    {
      text: "Home",
      icon: <InsertChartOutlinedIcon />,
      indexes: 0,
      // onClick: () => history.push(localRoute.jobTable)
    },
    {
      text: "QC Associate",
      icon: <PersonIcon />,
      indexes: 1,
      // onClick: () => history.push(localRoute.jobTable)
    },
    {
      text: "Internal Lead Report",
      icon: <LibraryBooksIcon />,
      indexes: 2,
      // onClick: () => history.push(localRoute.jobTable)
    },
    {
      text: "External Lead Report",
      icon: <LibraryBooksIcon />,
      indexes: 3,
      // onClick: () => history.push(localRoute.jobTable)
    },
    {
      text: "FSE",
      icon: <AccountBoxIcon />,
      indexes: 4,
      // onClick: () => history.push(localRoute.jobTable)
    },
    {
      text: "Logout",
      icon: <ExitToAppOutlinedIcon />,
      indexes: 5,
      onClick: () => logout()
    },
  ];

  const [itemsList, setItemList] = useState(itemsList1)




  const handleListItemClick = (event, index) => {
    // alert('ok')
    setSelectedIndex(index);
    if (index == 0) {
      history.push(localRoute.dashboard)
    }
    if (index == 1) {
      history.push(localRoute.qcAssociateTable)
    }
    if (index == 2) {
      history.push(history.push(localRoute.internalDashboardTable))
    }
    if (index == 3) {
      history.push(history.push(localRoute.externalDashboardTable))
    }
    if (index == 4) {
      history.push(history.push(localRoute.fseTable))
    }
    if (index == 5) {
      logout()
    }
  };

  let url

  useEffect(() => {

    //console.log('pathname', window.location.pathname.split('/'));
    let total_url = window.location.pathname.split('/')
    let url = total_url[1];
    if (url == "dashboard") {
      setSelectedIndex(0)
    }
    if (url == "qc-associate") {
      setSelectedIndex(1)
    }
    if (url == "internal-report") {
      setSelectedIndex(2)
    }
    if (url == "external-report") {
      setSelectedIndex(3)
    }
    if (url == "fse") {
      setSelectedIndex(4)
    }
    // if (url == "pincodes" ) {
    //   setSelectedIndex(1)
    // }
    setLoginName(localStorage.getItem('full_name'))
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenuProfileOpen = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    const admin_id = localStorage.getItem('login_id')
    //console.log('get manage user', admin_id)
    // getPortalUser(admin_id)(portalUserDispatch)
    history.push(`${localRoute.editProfile}`)
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logout = () => {
    confirm({ description: `Are You sure you want to logout?` })
      .then(() => {
        localStorage.clear();
        logOut({ loginUser: [] })(userDispatch)
        setToken("");
        history.push("/");
      })
      .catch = (() => console.log("Deletion cancelled."));

  };

  let localToken = localStorage.getItem("token");
  useEffect(() => {
    if (!localToken) {
      history.push("/");
      // history.go(0)
    }



  }, [localToken]);


  let pageTitlePath = history.location && history.location.pathname.split('/')

  useEffect(() => {
    let str = pageTitlePath && pageTitlePath[1].replace("-", " ")
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    setPageTitle(splitStr.join(' '))
  }, [pageTitlePath])

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = (val) => {
    val == true ? setOpen(false) : setOpen(true);
  };



  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuProfileOpen}>Edit Profile</MenuItem>

    </Menu>
  );




  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={badgeCount} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );


  return (
    <div className={classes.root}>
      <ToastContainer

        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />



      <AppBar
        position="fixed"
        size="sm"
        className={clsx(classes.appBar, {
          // [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ border: 'none', marginTop: -12, fontSize: 14, fontWeight: 500 }}>


          {/* <div className={classes.drawerHeader}> */}
          <Typography variant="h7" className={classes.title}>
            <img src={Logo} className={classes.logoImage} alt=''/>
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => handleDrawerOpen(open)}
            edge="start"
            style={{ marginLeft: 0 }}
          // className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {/* </div> */}
          <Typography variant="h7" noWrap>
            <span style={{ fontSize: 16, fontWeight: 600, letterSpacing: '0.3px' }}>{pageTitle.toLocaleUpperCase()}</span>
          </Typography>


          <div className={classes.search}>
            <div className={classes.searchIcon}>
              {/* <SearchIcon /> */}
            </div>

          </div>


          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Typography variant="h7" style={{ marginRight: 8, display: "flex", justifyItems: "center", alignItems: "center" }} noWrap>
              {loginName ? loginName.toLocaleUpperCase() : ''}
            </Typography>
            <Avatar alt="AB UTNAL" onClick={handleProfileMenuOpen} style={{ margin: 0, padding: 0 }} src="" className={classes.large}>
              {loginName[0]} </Avatar>


          </div>


          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>


        </Toolbar>

      </AppBar>




      <Drawer
        // variant="permanent"
        // classes={{
        //   paper: clsx(classes.drawer, {
        //     [classes.closed]: !isOpened,
        //     [classes.opened]: isOpened,
        //   }),
        // }}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}


      >

        <List className={classes.drawerList} style={{ margin: '20px 5px 0px 5px' }}>

          {itemsList.map((item, index) => {
            const { text, icon, onClick, indexes } = item;
            return (
              <ListItem style={{ padding: '6px 0px 2px 10px', margin: '4px 0px 4px 0px' }} button key={text} onClick={(event) => handleListItemClick(event, indexes)} selected={selectedIndex === indexes}>
                <Link style={{ textDecoration: 'none' }}>
                  {icon && <ListItemIcon className={classes.drawerIcon}>{icon}
                    <ListItemText className={classes.drawerMenuIten}> {text} </ListItemText>
                  </ListItemIcon>}
                  {/* <ListItemText className={classes.drawerMenuIten} primary={text.toLocaleUpperCase()} /> */}
                </Link>
              </ListItem>
            );
          })}

          <br />
        </List>
        <div style={{
          position: 'absolute',
          bottom: 0,
          right: 0
        }}>
          <img src={moneyPerson} style={{ height: 150 }} alt="noImage" />
        </div>
      </Drawer>

      {renderMobileMenu}
      <div className={classes.container}>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        // style={{background:'#ddd', margin:'0px 100px'}}
        >
          <div className={classes.drawerHeader} />
          <Typography paragraph>
            <Navigation />
          </Typography>

        </main>

      </div>
    </div>
  );
};
export default withRouter(DrawerUI);