import React, { createContext, useReducer } from 'react';

import RootReducer from './reducers/rootReducer';
import RootInitialState from './intialStates/rootState';
import UserReducer from './reducers/userReducer';
import UsersInitialState from "./intialStates/userState";

import AuditReducer from './reducers/auditReducer';
import AuditInitialState from "./intialStates/auditState";

import LeadReducer from './reducers/leadReducer';
import LeadInitialState from "./intialStates/leadState";

import QcReducer from './reducers/qcReducer';
import QcInitialState from "./intialStates/qcState";

import ExternalDashboardReducer from './reducers/externalDashboardReducer';
import ExternalDashboardInitialState from "./intialStates/externalDashboardState";

import InternalDashboardReducer from './reducers/internalDashboardReducer';
import InternalDashboardInitialState from "./intialStates/internalDashboardState";
import agencyReducer from './reducers/agencyReducer';
import agencyInitialState from './intialStates/agencyInitialState';
import vendorReducer from './reducers/vendorReducer';
import vendorInitialState from './intialStates/vendorInitialState';
import reasonCodeReducer from './reducers/reasonCodeReducer';
import reasonCodeInitialState from './intialStates/reasonCodeInitialState';
import fseReducer from './reducers/fseReducer';
import FseInitialState from './intialStates/FseInitialState';
// Initial State
const initialState = {
  users: [],
}

// Create Context
export const GlobalContext = createContext(initialState);
// Provider Component
export const GlobalProvider = ({ children }) => {
  const [rootState, rootDispatch] = useReducer(RootReducer, RootInitialState);
  const [userState, userDispatch] = useReducer(UserReducer, UsersInitialState);
  const [auditState, auditDispatch] = useReducer(AuditReducer, AuditInitialState);
  const [leadState, leadDispatch] = useReducer(LeadReducer, LeadInitialState);
  const [externalDashboardState, externalDashboardDispatch] = useReducer(ExternalDashboardReducer, ExternalDashboardInitialState);
  const [internalDashboardState, internalDashboardDispatch] = useReducer(InternalDashboardReducer, InternalDashboardInitialState);
  const [qcState, qcDispatch] = useReducer(QcReducer, QcInitialState);
  const [agencyState, agencyDispatch] = useReducer(agencyReducer, agencyInitialState);
  const [vendorState, vendorDispatch] = useReducer(vendorReducer, vendorInitialState);
  const [reasonCodeState, reasonCodeDispatch] = useReducer(reasonCodeReducer, reasonCodeInitialState);
  const [fseState, fseDispatch] = useReducer(fseReducer, FseInitialState);

  let provides = {
    rootState,
    rootDispatch,
    userState,
    userDispatch,
    auditState,
    auditDispatch,
    leadState,
    leadDispatch,
    qcState,
    qcDispatch,
    externalDashboardState,
    externalDashboardDispatch,
    internalDashboardState,
    internalDashboardDispatch,
    agencyState,
    agencyDispatch,
    vendorState,
    vendorDispatch,
    reasonCodeState,
    reasonCodeDispatch,
    fseState,
    fseDispatch
  }

  return (
    <GlobalContext.Provider value={provides}>
      {children}
    </GlobalContext.Provider>
  )
}