import React, { useEffect, useRef, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useHistory,
} from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import BusinessDrawer from "../components/drawer";
import NormalDrawer from "../components/normalDrawer/NormalDrawer";
import InternalDrawer from "../components/internalDrawer/InternalDrawer";
import AgencyDrawer from "../components/agencyDrawer/AgencyDrawer";
import Login from "../pages/login";
import { localRoute } from "../routes/localRoutes";
import { ConfirmProvider } from "material-ui-confirm";
import UnAutorized from "../pages/unAutorized"
import NotFound from "../pages/notFound";
import ForgotPassword from "pages/forgotPassword/ForgotPassword";
import Otp from "pages/otp";
import { GlobalContext } from "context/GlobalState";
import ViewerDrawer from "components/viewerDrawer/ViewerDrawer";

function RouterComponent(props) {
  const history = useHistory();
  let localToken = localStorage.getItem("token");
  let [qcType, setQcType] = useState(localStorage.getItem('qc_type'))
  let [userType, setUserType] = useState(localStorage.getItem('user_type'))
  const { userState, userDispatch } = useContext(GlobalContext);
  useEffect(() => {
    if (!localToken) {
      history.push("/");

    }
  }, [localToken]);


  useEffect(() => {
    // setLoader(false)
    let status = userState && userState.otpUser && userState.otpUser.data && userState.otpUser.data.status
    if (status === true) {
      setQcType(userState.otpUser.data.data.qc_type)
      setUserType(userState.otpUser.data.data.user_type)
      if (userState.otpUser.data.data.qc_type == "external" && userState.otpUser.data.data.user_type == "root") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.dashboard)
      }
      else if (userState.otpUser.data.data.qc_type == "internal") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.internalDashboard)
      }

      else if (userState.otpUser.data.data.qc_type == "viewer") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.viewerDashboard)
      }

      else if (userState.otpUser.data.data.qc_type == "agency") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.agencyDashboard)
      }

      else if (userState.otpUser.data.data.qc_type == "external" && userState.otpUser.data.data.user_type == "") {
        localStorage.setItem("full_name", userState.otpUser.data.data.full_name);
        localStorage.setItem("token", userState.otpUser.data.data.accessToken);
        localStorage.setItem("qc_type", userState.otpUser.data.data.qc_type);
        localStorage.setItem("user_type", userState.otpUser.data.data.user_type);
        localStorage.setItem("email_id", userState.otpUser.data.data.email_id);
        localStorage.setItem("number", userState.otpUser.data.data.number);
        localStorage.setItem("login_id", userState.otpUser.data.data.id);
        history.push(localRoute.externalDashboard)
      }

      userState.otpUser = {
        loading: false,
        error: null,
        data: null,
      }

    }
    // SERVER ERROR RESPONSE
    if (status === 500) {
      userState.otpUser = {
        loading: false,
        error: null,
        data: null,

      }
    }
    // SERVER VALIDATION ERROR RESPONSE  
    if (status === 401) {
      userState.otpUser = {
        loading: false,
        error: null,
        data: null
      }
    }
  }, [userState])

  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <ConfirmProvider>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path={localRoute.otp} component={Otp} />
            <Route exact path={localRoute.forgot} component={ForgotPassword} />
            {qcType == "external" && userType == "root" &&
              <Switch>
                <Route exact path={localRoute.dashboard} component={BusinessDrawer} />
                <Route exact path={localRoute.qcAssociateTable} component={BusinessDrawer} />
                <Route exact path={localRoute.externalDashboardTable} component={BusinessDrawer} />
                <Route exact path={localRoute.internalDashboardTable} component={BusinessDrawer} />
                <Route exact path={`${localRoute.leadDetail}/:lead_id`} component={BusinessDrawer} />
                <Route exact path={localRoute.vendorReportTable} component={BusinessDrawer} />
                <Route exact path={localRoute.fseTable} component={BusinessDrawer} />
                <Route component={NotFound} />
              </Switch>
            }
            {qcType == "external" && userType == "" &&
              <Switch>

                <Route exact path={localRoute.externalDashboard} component={NormalDrawer} />
                <Route exact path={`${localRoute.externalLeadDetail}`} component={NormalDrawer} />
                <Route component={NotFound} />
              </Switch>
            }

            {qcType == "internal" &&
              <Switch>

                <Route exact path={localRoute.internalDashboard} component={InternalDrawer} />
                <Route exact path={`${localRoute.internalLeadDetail}`} component={InternalDrawer} />
                <Route component={NotFound} />
              </Switch>
            }

            {qcType == "agency" &&
              <Switch>

                <Route exact path={localRoute.agencyDashboard} component={AgencyDrawer} />
                <Route exact path={`${localRoute.agencyLeadDetail}`} component={AgencyDrawer} />
                <Route component={NotFound} />
              </Switch>
            }

            {qcType == "viewer" &&
              <Switch>
                <Route exact path={localRoute.viewerDashboard} component={ViewerDrawer} />
                <Route exact path={localRoute.viewerInternalDashboardTable} component={ViewerDrawer} />
                <Route exact path={localRoute.viewerExternalDashboardTable} component={ViewerDrawer} />
                <Route component={NotFound} />
              </Switch>
            }
            <Route component={NotFound} />
          </Switch>
        </ConfirmProvider>
      </Router>
    </React.Fragment>
  );
}

export default withRouter(RouterComponent);
