import { GET_AUDITS, GET_AUDITS_ERR } from 'context/actionTypes/auditType';
import { getAuditsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getAudits = payload => async dispatch => {
    try {
        console.log('payload',payload)
        let results = await axios.get(`${getAuditsApi}/${payload}`, await accessToken())
        let data = results && results.data
        dispatch({
            type: GET_AUDITS,
            payload: data || [],
        });
    } catch (error) {

        dispatch({
            type: GET_AUDITS_ERR,
            payload: error && error.response
        });
    }
}

