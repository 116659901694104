import { GET_FSE_LEAD, GET_FSE_LEAD_ERR } from '../../actionTypes/FseType'
import { getFseLeadApi} from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getFseLead = payload => async dispatch => {
    try {
        ////console.log('payload',payload)
        let results = await axios.get(`${getFseLeadApi}/${payload}`, await accessToken())
        ////console.log("User Detail", results)
        let data = results && results.data
        dispatch({
            type: GET_FSE_LEAD,
            payload: data || {},
        });
    } catch (error) {   
        dispatch({
            type: GET_FSE_LEAD_ERR,
            payload: error && error.response
        });
    }
}


