export default { 
  updateInternalQcSubmit: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getInternalAssignedLeads: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getInternalDashboardCount: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getInternalRequestLeads: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
};
