

export const UPDATE_EXTERNAL_QC_SUBMIT = 'UPDATE_EXTERNAL_QC_SUBMIT'
export const UPDATE_EXTERNAL_QC_SUBMIT_ERR = 'UPDATE_EXTERNAL_QC_SUBMIT'
export const GET_EXTERNAL_ASSIGNED_LEADS = 'GET_EXTERNAL_ASSIGNED_LEADS'
export const GET_EXTERNAL_ASSIGNED_LEADS_ERR = 'GET_EXTERNAL_ASSIGNED_LEADS_ERR'
export const GET_EXTERNAL_DASHBOARD_COUNT = 'GET_EXTERNAL_DASHBOARD_COUNT'
export const GET_EXTERNAL_DASHBOARD_COUNT_ERR = 'GET_EXTERNAL_DASHBOARD_COUNT_ERR'
export const GET_EXTERNAL_REQUEST_LEADS = 'GET_EXTERNAL_REQUEST_LEADS'
export const GET_EXTERNAL_REQUEST_LEADS_ERR = 'GET_EXTERNAL_REQUEST_LEADS_ERR'
