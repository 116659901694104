export const localRoute = {
  notFound: '*',
  notFound500: '*',
  forgot: '/forgot-password',
  notifications: '/notifications',
  otp: '/otp',
  dashboard: '/dashboard',
  qcAssociateTable: '/qc-associate',
  internalDashboardTable: '/internal-report',
  externalDashboardTable: '/external-report',
  leadDetail: '/lead-detail',
  fseTable :'/fse',

  externalDashboard: '/external-dashboard',
  externalLeadDetail: '/lead-detail',
  vendorReportTable: '/vendor-report',

  internalDashboard: '/internal-dashboard',
  internalLeadDetail: '/lead-detail',

  agencyDashboard: '/agency-dashboard',
  agencyLeadDetail: '/lead-detail',


  viewerDashboard: '/viewer-dashboard',
  viewerInternalDashboardTable: '/internal-report',
  viewerExternalDashboardTable: '/external-report',

}

