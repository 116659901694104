import React, { usedata, useEffect, useState, useContext } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
// import AnalysisLeadDialog from "./AnalysisLeadDialog";
// import { data } from "../../../App";
import Swal from "sweetalert2";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { GlobalContext } from "context/GlobalState";
import { useTheme } from '@mui/material/styles';
import { GridList, GridListTile } from "@material-ui/core";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Grid from '@mui/material/Grid';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import SwipeableViews from 'react-swipeable-views';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { autoPlay } from 'react-swipeable-views-utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { updateQcAllSubmit } from "context/actions/qcAction/updateQcAllSubmit";
import { getReasonList } from "context/actions/leadAction/getReasonList";
import moment from 'moment'
import { getAnalysis } from "context/actions/leadAction/getAnalysis";
import { useParams } from "react-router";
import { updateQcAllSubmitRemark } from "context/actions/qcAction/updateQcAllSubmitRemark";
import { updateInternalQcSubmit } from "context/actions/internalDashboardAction/updateInternalQcSubmit";
import { updateLeadReasonSubmit } from "context/actions/qcAction/updateLeadReasonSubmit";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function createData(
  leadId,
  distance,
  fseName,
  fseNumber,
  shopName,
  merchantName,
  upi_id,
  store_front_image,
  qc_status,
  agency_id,
  ref_lead_id,
  created_on,
  stage_1_qc_status
) {
  return {
    leadId,
    distance,
    fseName,
    fseNumber,
    shopName,
    merchantName,
    upi_id,
    store_front_image,
    qc_status,
    agency_id,
    ref_lead_id,
    created_on,
    stage_1_qc_status
  };
}

function createData1(
  leadId,
  distance,
  fseName,
  fseNumber,
  shopName,
  merchantName,
  upi_id,
  store_front_image,
  qc_status,
  agency_id,
  ref_lead_id,
  created_on,
  stage_1_qc_status
) {
  return {
    leadId,
    distance,
    fseName,
    fseNumber,
    shopName,
    merchantName,
    upi_id,
    store_front_image,
    qc_status,
    agency_id,
    ref_lead_id,
    created_on,
    stage_1_qc_status
  };
}


const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },

});

export default function CustomizedTables(data) {
  console.log('pointer data', data)
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams()
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [remarkValue, setRemarkValue] = React.useState('');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [leadDetail, setLeadDetail] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [data, setdata] = usedata(data);
  const [rows, setRows] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [reasonList, setReasonList] = useState("");
  const { leadState, leadDispatch } = useContext(GlobalContext);
  const { qcState, qcDispatch } = useContext(GlobalContext);
  const [selectedTile, setSelectedTile] = React.useState(null);
  const [value, setValue] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const { internalDashboardState, internalDashboardDispatch } = useContext(GlobalContext);

  const handleChange = (event) => {
    setRemarkValue(event.target.value);
  };

  useEffect(() => {
    getReasonList()(leadDispatch)
  }, [])

  useEffect(() => {
    console.log('leadState', leadState.getReasonList)
    let status = leadState.getReasonList && leadState.getReasonList.data && leadState.getReasonList.status
    if (status == true) {

      console.log("leadState.getReasonList", leadState.getReasonList)
      setReasonList(leadState.getReasonList.data)
      leadState.getLead = {
        loading: false,
        error: null,
        data: null,
      }
    }


    if (status === 401) {
      // setLoader(false)

      leadState.getLead = {
        loading: false,
        error: null,
        data: [],
      }
    }


  }, [leadState])


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClickOpen = item => {
    setSelectedTile(item);
    console.log("clicked", item);
    console.log(item);
  };

  const handleClose1 = () => {
    setLoader(false)
    setRemarkValue('')
    setSelectedTile(null);

  };
  useEffect(() => {

    if (data.data && data.data.fse_info) {
      const rows1 = [
        ...data.data.fse_info.map(
          ({
            main_lead_id,
            distance,
            fse_name,
            fse_number,
            shop_name,
            merchant_name,
            upi_id,
            store_front_image,
            qc_status,
            agency_id,
            ref_lead_id,
            created_on,
            stage_1_qc_status
          }) => {
            return createData(
              main_lead_id,
              distance,
              fse_name,
              fse_number,
              shop_name,
              merchant_name,
              upi_id,
              store_front_image,
              qc_status,
              agency_id,
              ref_lead_id,
              created_on,
              stage_1_qc_status
            );
          }
        ),
      ];
      setRows([...rows1]);
    }



    if (data.data && data.data.lead_info) {
      const rows1 = [
        ...data.data.lead_info.map(
          ({
            main_lead_id,
            distance,
            fse_name,
            fse_number,
            shop_name,
            merchant_name,
            upi_id,
            store_front_image,
            qc_status,
            agency_id,
            ref_lead_id,
            created_on,
            stage_1_qc_status
          }) => {
            return createData1(
              main_lead_id,
              distance,
              fse_name,
              fse_number,
              shop_name,
              merchant_name,
              upi_id,
              store_front_image,
              qc_status,
              agency_id,
              ref_lead_id,
              created_on,
              stage_1_qc_status
            );
          }
        ),
      ];
      setRows1([...rows1]);
    }
  }, [data.data]);

  const handleClick = (event, value) => {
    setAnchorEl(event.currentTarget);
    setLeadDetail(value)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('selectedTile',selectedTile)

    if (selectedTile && selectedTile.qc_status == "0" && selectedTile.stage_1_qc_status == "0") {
      let postData = {
        lead_id: selectedTile.leadId,
        id: localStorage.getItem('login_id'),
        qc_remark: remarkValue.reason,
        qc_status: remarkValue.subcode,
        agency_id: selectedTile.agency_id,
        qc_reason_code: remarkValue.id,
        ref_lead_id: selectedTile.ref_lead_id
      }

      setLoader(true)
      console.log('postData if',postData)
     updateQcAllSubmitRemark(postData)(qcDispatch)
    }
    else if (selectedTile && selectedTile.stage_1_qc_status != "0" && selectedTile.qc_status == "0") {
      let postData = {
        lead_id: selectedTile.leadId,
        id: localStorage.getItem('login_id'),
        qc_remark: remarkValue.reason,
        qc_status: remarkValue.subcode,
        agency_id: selectedTile.agency_id,
        qc_reason_code: remarkValue.id,
        ref_lead_id: selectedTile.ref_lead_id
      }

      setLoader(true)
      console.log('postData else if',postData)
       updateInternalQcSubmit(postData)(internalDashboardDispatch)

    }
    else {
      console.log('else')
      let postData = {
        lead_id: selectedTile.leadId,
        qc_remark: remarkValue.reason,
        qc_status: remarkValue.subcode,
        agency_id: selectedTile.agency_id,
        qc_reason_code: remarkValue.id,
        ref_lead_id: selectedTile.ref_lead_id
      }
      console.log('postData else',postData)
       updateLeadReasonSubmit(postData)(qcDispatch)


    }

  }


  useEffect(() => {
    console.log('Stage1 submit', internalDashboardState.updateInternalQcSubmit)
    let status = internalDashboardState.updateInternalQcSubmit && internalDashboardState.updateInternalQcSubmit.status
    if (status == true) {
        setLoader(false)
        console.log("leadState.updateInternalQcSubmit", internalDashboardState.updateInternalQcSubmit)
        Swal.fire(
            'Lead Submitted',
            'Lead has been Submitted.',
            'success'
        )
        getAnalysis(params.lead_id)(leadDispatch)
            handleClose1()
            // console.log('postData', postData)
            data.onClose()
            // getQcLeadDetail(params.lead_id)(qcDispatch)
        internalDashboardState.updateInternalQcSubmit = {
            loading: false,
            error: null,
            data: null,
        }
    }


    if (status === 401) {
        // setLoader(false)

        internalDashboardState.updateInternalQcSubmit = {
            loading: false,
            error: null,
            data: [],
        }
    }


}, [internalDashboardState])


   useEffect(() => {
        // console.log('leadState', qcState.updateQcAllSubmit)
        let status = qcState.updateLeadReasonSubmit && qcState.updateLeadReasonSubmit.status
        if (status == true) {

            console.log("leadState.updateLeadReasonSubmit", qcState.updateLeadReasonSubmit)
            Swal.fire(
                'Lead Submitted',
                'Lead has been Submitted.',
                'success'
            )
            getAnalysis(params.lead_id)(leadDispatch)
            handleClose1()
            // console.log('postData', postData)
            data.onClose()
            // getQcLeadDetail(params.lead_id)(qcDispatch)
            qcState.updateLeadReasonSubmit = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            qcState.updateLeadReasonSubmit = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [qcState])

  useEffect(() => {
    console.log('leadState', qcState.updateQcAllSubmitRemark)
    let status = qcState.updateQcAllSubmitRemark && qcState.updateQcAllSubmitRemark.status
    if (status == true) {
      setLoader(false)
      console.log("leadState.updateQcAllSubmitRemark", qcState.updateQcAllSubmitRemark)
      Swal.fire(
        'Lead Submitted',
        'Lead has been Submitted.',
        'success'
      )
      getAnalysis(params.lead_id)(leadDispatch)
      handleClose1()
      // console.log('postData', postData)
      data.onClose()
      // getQcLeadDetail(params.lead_id)(qcDispatch)
      qcState.updateQcAllSubmitRemark = {
        loading: false,
        error: null,
        data: null,
      }
    }


    if (status === 401) {
      // setLoader(false)

      qcState.updateQcAllSubmitRemark = {
        loading: false,
        error: null,
        data: [],
      }
    }


  }, [qcState])


  const open1 = Boolean(anchorEl);
  const id = open1 ? 'simple-popover' : undefined;
  return (
    <>
      <div style={{ padding: 10 }}>
        {rows.length != 0 && <h2>SAME FSE LEADS ({rows && rows.length})</h2>}
        <GridList cols={6}>
          className={classes.gridList}
          {rows && rows.map(item => (
            <GridListTile key={item.store_front_image} >
              <img src={item.store_front_image} alt={item.store_front_image} onClick={() => handleClickOpen(item)} />
              <GridListTileBar style={{ height: '30px', top: 0, left: " 55%", background: item.qc_status == "1" ? '#8db055' : item.qc_status == "2" ? "rgb(214 218 62)" : item.qc_status == "3" ? "#c2595d" : item.qc_status == "0" ? '#abaca5' : '' }}
                title={<b style={{ fontSize: 12 }}>{item.distance.toFixed(2)}M</b>}
                // subtitle={`${item.distance.toFixed(2)} in Meter`}
                actionIcon={
                  <IconButton
                    aria-label={`info about ${item.title}`}
                    className={classes.icon}
                    value={item.id}
                    onClick={(e) => handleClick(e, item)}
                  >
                    <InfoIcon />
                  </IconButton>
                }


              />
            </GridListTile>
          ))}
        </GridList>
        {rows1.length != 0 && <h2>OTHER LEADS ({rows1 && rows1.length})</h2>}
        <GridList cols={6}>
          className={classes.gridList}
          {rows1 && rows1.map(item => (
            <GridListTile  >
              <img src={item.store_front_image} alt={item.store_front_image} onClick={() => handleClickOpen(item)} />
              <GridListTileBar style={{ height: '30px', top: 0, left: " 55%", background: item.qc_status == "1" ? '#8db055' : item.qc_status == "2" ? "rgb(214 218 62)" : item.qc_status == "3" ? "#c2595d" : item.qc_status == "0" ? '#abaca5' : '' }}
                title={<b style={{ fontSize: 12 }}>{item.distance.toFixed(2)}M</b>}
                // subtitle={`${item.distance.toFixed(2)} in Meter`}
                actionIcon={
                  <IconButton
                    aria-label={`info about ${item.title}`}
                    className={classes.icon}
                    value={item.id}
                    onClick={(e) => handleClick(e, item)}
                  >
                    <InfoIcon />
                  </IconButton>
                }


              />
            </GridListTile>
          ))}
        </GridList>
        <Popover
          id={id}
          open={open1}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography style={{ padding: 10 }}>{`Lead ID : ${leadDetail && leadDetail.leadId}`}<br />
            {`Shop Name : ${leadDetail && leadDetail.shopName}`}<br />
            {`Merchant Name : ${leadDetail && leadDetail.merchantName}`}<br />
            {`FSE Name : ${leadDetail && leadDetail.fseName}`}<br />
            {`FSE No : ${leadDetail && leadDetail.fseNumber}`}<br />
            {`UPI ID : ${leadDetail && leadDetail.upi_id}`}<br />
            {`Agency ID : ${leadDetail && leadDetail.agency_id}`}<br />
            {`Lead Status : ${leadDetail && leadDetail.qc_status == "1" ? "Approved (Green)" : ""}
                     ${leadDetail && leadDetail.qc_status == "0" ? "Pending" : ""}
                     ${leadDetail && leadDetail.qc_status == "3" ? "Rejected (Red)" : ""}
                     ${leadDetail && leadDetail.qc_status == "2" ? "Partially Approved (Yellow)" : ""}`}<br /></Typography>
        </Popover>
        <Dialog
          // fullScreen
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={selectedTile !== null}
          onClose={handleClose1}
          TransitionComponent={Transition}
        >

          <Grid xs={12} container >
            <Grid xs={8} item>
              {selectedTile && (
                <img src={selectedTile.store_front_image} alt={selectedTile.store_front_image} style={{ width: '100%' }} />
              )}
            </Grid>
            <Grid xs={4} item>
              <h4 style={{ padding: 15, textAlign: 'center', background: 'rgb(149 152 154)', margin: 0 }}>Lead Detail</h4>
              <Typography sx={{ p: 2 }} style={{ fontSize: 14 }}> <b>Lead ID :</b>{` ${selectedTile && selectedTile.leadId}`}<br />
                <b>Shop Name :</b>  {`${selectedTile && selectedTile.shopName}`}<br />
                <b>Merchant Name :</b>{` ${selectedTile && selectedTile.merchantName}`}<br />
                <b>FSE Name : </b>{` ${selectedTile && selectedTile.fseName}`}<br />
                <b>FSE No :</b>  {` ${selectedTile && selectedTile.fseNumber}`}<br />
                <b>UPI ID :</b> {` ${selectedTile && selectedTile.upi_id}`}<br />
                <b>Agency ID : </b> {` ${selectedTile && selectedTile.agency_id}`}<br />
                <b>Created On : </b> {` ${selectedTile && moment(selectedTile.created_on).format("DD-MM-YYYY") + ' ' + moment(selectedTile.created_on, 'DD-MM-YYYY hh:mm').format("HH:mm:ss a")}`}<br />

                <b>Lead Status : </b> {` ${selectedTile && selectedTile.qc_status == "1" ? "Approved (Green)" : ""}
                     ${selectedTile && selectedTile.qc_status == "0" ? "Pending" : ""}
                     ${selectedTile && selectedTile.qc_status == "3" ? "Rejected (Red)" : ""}
                     ${selectedTile && selectedTile.qc_status == "2" ? "Partially Approved (Yellow)" : ""}`}<br /></Typography>
              {/* <form onSubmit={handleSubmit}>
                <Card variant="outlined" className={classes.LeadRemarks}>
                  <CardContent>
           
                    <div  >

                      <FormControl fullWidth required >
                        <InputLabel id="demo-simple-select-label">Choose Remark</InputLabel>
                        <Select
                          size="medium"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={remarkValue}
                          label="Choose Remark"
                          onChange={handleChange}
                        >
                          {reasonList && reasonList.map((item) => {
                            return (
                              <MenuItem value={item}>{item.reason}</MenuItem>
                            )
                          })}


                        </Select>
                      </FormControl>
                    </div>
                    <br />
                    {remarkValue != '' &&
                      <div className={classes.psubmit} style={{ textAlign: 'center' }} >
                        <Button
                          type="submit"
                          style={{ textAlign: 'center', textTransform: 'none', color: "#fff", background: '#141e2d' }}
                          size="small"
                        >
                          {
                            loader ? <CircularProgress color='white' style={{ padding: '2px 10px' }} size={20} /> : "Submit"
                          }
                        </Button>

                      </div>
                    }
                  </CardContent>
                </Card>
              </form> */}
            </Grid>
          </Grid>

        </Dialog>
      </div>

    </>
  );
}
