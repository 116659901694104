import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styles } from "./LeadDetailsStyle";
import { makeStyles } from "@material-ui/core/styles";
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(styles);

const LeadDetailsImagesModal = ({
    openModal,
    handleCloseModal,
    storeFrontImage,
    competativeQrImage,
    firstBranding,
    secondBranding,
    amazonQrImage
}) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "0.5px solid #000",
        boxShadow: 24,
        p: 4,
    };
    const classes = useStyles();

    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        {storeFrontImage ?
                            (
                                <div className={classes.imageContainer} >
                                    <div>
                                        <div>
                                            <img src="https://taskmotech1b.s3.amazonaws.com/c3fb1122-40f5-4711-9689-70a6b84296bd.jpeg" alt="" className={classes.img} />
                                        </div>
                                        <div className={classes.arrows} >
                                            <ArrowCircleUpOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleDownOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleLeftOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleRightOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                        <div>
                                            <div>
                                                <Card variant="outlined" className={classes.imageModaltext} >
                                                    <CardContent>
                                                        <div>STORE FRONT IMAGE WITH STICKER</div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            <div>
                                                <Card variant="outlined" className={classes.shopDetailsModal}  >
                                                    <CardContent>
                                                        <div className={classes.merchantName} >Shop Name</div>
                                                        <div>Hari om clinic</div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className={classes.confirmBtn} >
                                            <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                        </div>
                                    </div>
                                </div>
                            ) : competativeQrImage ? (
                                <div className={classes.imageContainer} >
                                    <div>
                                        <div>
                                            <img src="https://taskmotech1b.s3.amazonaws.com/834339fa-e9fb-4f95-996d-cd059e92b61e.jpeg" alt="" className={classes.img} />
                                        </div>
                                        <div className={classes.arrows} >
                                            <ArrowCircleUpOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleDownOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleLeftOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleRightOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                        <div>
                                            <div>
                                                <Card variant="outlined" className={classes.imageModaltext} >
                                                    <CardContent>
                                                        <div>COMPETATIVE QR CODE IMAGE</div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className={classes.confirmBtn} >
                                            <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                        </div>
                                    </div>
                                </div>
                            ) : firstBranding ? (
                                <div className={classes.imageContainer} >
                                    <div>
                                        <div>
                                            <img src="https://taskmotech1b.s3.amazonaws.com/88310789-0419-40f4-9c7b-77aef4656db7.jpeg" alt="" className={classes.img} />
                                        </div>
                                        <div className={classes.arrows} >
                                            <ArrowCircleUpOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleDownOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleLeftOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleRightOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                        <div>
                                            <div>
                                                <Card variant="outlined" className={classes.imageModaltext} >
                                                    <CardContent>
                                                        <div>FIRST BRANDING MATERIAL IMAGE</div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className={classes.confirmBtn} >
                                            <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                        </div>
                                    </div>
                                </div>
                            ) : secondBranding ? (
                                <div className={classes.imageContainer} >
                                    <div>
                                        <div>
                                            <img src="https://taskmotech1b.s3.amazonaws.com/88310789-0419-40f4-9c7b-77aef4656db7.jpeg" alt="" className={classes.img} />
                                        </div>
                                        <div className={classes.arrows} >
                                            <ArrowCircleUpOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleDownOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleLeftOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleRightOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                        <div>
                                            <div>
                                                <Card variant="outlined" className={classes.imageModaltext} >
                                                    <CardContent>
                                                        <div>SECOND BRANDING MATERIAL IMAGE</div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className={classes.confirmBtn} >
                                            <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                        </div>
                                    </div>
                                </div>
                            ) : amazonQrImage ? (
                                <div className={classes.imageContainer} >
                                    <div>
                                        <div>
                                            <img src="https://amazonqc.taskmo.com/images/image-unavailable-icon-1.jpeg" alt="" className={classes.img} />
                                        </div>
                                        <div className={classes.arrows} >
                                            <ArrowCircleUpOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleDownOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleLeftOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                            <ArrowCircleRightOutlinedIcon style={{ fontSize: "40px", color: "#855bf5" }} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                        <div>
                                            <div>
                                                <Card variant="outlined" className={classes.imageModaltext} >
                                                    <CardContent>
                                                        <div>AMAZON QR CODE IMAGE</div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                            <div>
                                                <Card variant="outlined" className={classes.shopDetailsModal}  >
                                                    <CardContent>
                                                        <div className={classes.merchantName} >Upi id</div>
                                                        <div style={{ wordBreak: "break-all" }} >paytmqr2810050501011o0dqatfqwzp@paytm</div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className={classes.confirmBtn} >
                                            <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                        </div>
                                    </div>
                                </div>
                            ) : ("no Image")}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default LeadDetailsImagesModal;
