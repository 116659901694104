import jwtDecode from 'jwt-decode'
export const accessToken = async () =>{
    let token = localStorage.getItem('token')

    const { exp } = jwtDecode(token)
    const expirationTime = (exp * 1000) - 60000
    //console.log('expirationTime',exp)
    if (Date.now() >= expirationTime) {  
    localStorage.clear();
   
    }
    return {
            headers:{
           "x-auth-token": localStorage.getItem('token')
            }
    }
}
