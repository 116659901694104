import { GET_EXTERNAL_REQUEST_LEADS, GET_EXTERNAL_REQUEST_LEADS_ERR } from '../../actionTypes/externalDashboardType'
import { getExternalRequestLeadsApi} from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getExternalRequestLeads = payload => async dispatch => {
    try {
        ////console.log('payload',payload)
        let results = await axios.put(`${getExternalRequestLeadsApi}`, payload, await accessToken())
        ////console.log("User Detail", results)
        let data = results && results.data
        dispatch({
            type: GET_EXTERNAL_REQUEST_LEADS,
            payload: data || {},
        });
    } catch (error) {   
        dispatch({
            type: GET_EXTERNAL_REQUEST_LEADS_ERR,
            payload: error && error.response
        });
    }
}


