import React, { useEffect, useState, useContext } from "react";
import TextField from '@material-ui/core/TextField';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
export default function Input(props) {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const { name, label, value,error=null, onChange } = props;
    return (
        // <TextField
        //     variant="outlined"
        //     label={label}
        //     name={name}
        //     value={value}
        //     onChange={onChange}
        //     id="standard-fullname-input"
        //     fullWidth
        //     {...(error && {error:true,helperText:error})}
        // />
        <TextField
        id="outlined-basic" variant="outlined" 
                        label={label}
                        name={name}
                        value={value}
                        fullWidth
                   
                        onChange={onChange}
                        autoComplete="current-fullname"
                        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              
                        InputProps={{ // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        {...(error && {error:true })}
                      />
    )
}
