import React, { useContext, useState } from 'react'
import { styles } from "./VendorReportPageStyle";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom'
import VendorReport from "../../../components/root/vendorReport/VendorReport"

const useStyles = makeStyles(styles);
const VendorReportPage = React.memo((props) => {
    const classes = useStyles();

    return (
        <div>
            <VendorReport />
        </div>
    )
})

export default withRouter(VendorReportPage)