export default { 
  updateQcAllSubmit: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  getQcLeadDetail: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  updateQcAllSubmitRemark: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  updateDuplicateQcSubmit: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  updateLead: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
  updateLeadReasonSubmit: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
};
