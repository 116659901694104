import axios from "axios";
import { accessToken } from 'api/Token'
import { BLOCK_FSE, BLOCK_FSE_ERR } from 'context/actionTypes/FseType';
import { blockFseApi } from "api/Api";
export const blockFse = (payload) => async (dispatch) => {
    try {
        //console.log(payload)
        let results = await axios.post(`${blockFseApi}`, payload, await accessToken())
        //console.log(results)
        let data = results && results.data
        //console.log('UPDATE STEP 1 JOB', data)
        dispatch({
            type: BLOCK_FSE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: BLOCK_FSE_ERR,
            payload: error.response,
        });
    }
};