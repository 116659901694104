import React from 'react'
import TextField from '@material-ui/core/TextField';

export default function Input(props) {

    const { name, label, value,error=null, onChange } = props;
    return (
        // <TextField
        //     variant="outlined"
        //     label={label}
        //     name={name}
        //     value={value}
        //     onChange={onChange}
        //     id="standard-fullname-input"
        //     fullWidth
        //     {...(error && {error:true,helperText:error})}
        // />
        <TextField
                       
                        label={label}
                        name={name}
                        value={value}
                        fullWidth
                        id="outlined-basic" variant="outlined" 
                        onChange={onChange}
                        autoComplete="current-fullname"
                        {...(error && {error:true })}
                        multiline
                        rows={5}
                      />
    )
}
