import { maxWidth } from '@material-ui/system';
import loginBgImage from '../../assets/images/loginbanner.svg'
export const styles = (theme) => ({
  loginBg: {
    backgroundImage: `url(${loginBgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // backgroundColor:"#602994",
    height: "90vh",
    width: "100%",
  },

  otp_resend: {
    color: '#F99746',
    fontWeight: 600,
    marginLeft: '6px',
    cursor: 'pointer'
  },
  otp_resend1: {
    fontWeight: 600,
    marginLeft: '6px',
    color: '#a19c9c',
    pointerEvents: 'none'
  },




  loginForm: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '28ch',
    },
  },
  logoTitle: {
    color: "#602994",
    fontSize: "38px",
    fontWeight: 700,
  },
  loginTitle: {
    color: "#602994",
    fontSize: "34px",
    fontWeight: 500,
    fontStyle: "normal",
    letterSpacing: "1px",
    // fontFamily:"Mulish",
  },
  loginBtn: {
    background: "#602994",
    color: "#fff",
    marginLeft: "8px",
    width: "134px",
    height: "49px",
    boxShadow: "none",
    marginTop: "8px",
    letterSpacing: "2px",
    '&:hover': {
      backgroundColor: '#5848aa',
      color: '#fff',
    },
    "&:disabled": {
      backgroundColor: "#809fff",
      color: '#ddd',
    }
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    // "&&:after": {
    //   borderBottom: "none"
    // }
  },
  errorMsg: {
    color: "#990000",
    fontSize: "16px",
    fontWeight: 300,
    fontStyle: "normal",
    marginLeft: "8px",
    marginTop: '10px',
    letterSpacing: "0.5px",
  },


});