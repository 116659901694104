import { GET_EXTERNAL_REPORTS_STATS_ERR, GET_INTERNAL_REPORTS_STATS,GET_EXTERNAL_REPORTS_STATS,GET_INTERNAL_REPORTS_STATS_ERR, GET_QC_DASHBOARD_COUNT,GET_QC_DASHBOARD_COUNT_ERR, GET_QC_DOWNLOAD, GET_QC_DOWNLOAD_ERR } from '../../actionTypes/rootType';

export default (state, action) => {
  switch (action.type) {
    case GET_INTERNAL_REPORTS_STATS:
      return {
        ...state,
        getInternalReportStats: action.payload, ...state.data,
      }

    case GET_INTERNAL_REPORTS_STATS_ERR:
      return {
        ...state,
        getInternalReportStats: action.payload 
      }

      case GET_EXTERNAL_REPORTS_STATS:
      return {
        ...state,
        getExternalReportStats: action.payload, ...state.data,
      }

    case GET_EXTERNAL_REPORTS_STATS_ERR:
      return {
        ...state,
        getExternalReportStats:  action.payload 
      }

      case GET_QC_DASHBOARD_COUNT:
        return {
          ...state,
          getQcDashboardCount: action.payload, ...state.data,
        }
  
      case GET_QC_DASHBOARD_COUNT_ERR:
        return {
          ...state,
          getQcDashboardCount:  action.payload 
        }


        case GET_QC_DOWNLOAD:
          return {
            ...state,
            getQcDownload: action.payload, ...state.data,
          }
    
        case GET_QC_DOWNLOAD_ERR:
          return {
            ...state,
            getQcDownload:  action.payload 
          }
    
  }
}
