import React from 'react'
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { Grid } from '@material-ui/core';
export default function Checkbox(props) {

    const { name, label, value, onChange } = props;

    //console.log(value)
    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <FormControl component="fieldset" style={{ float: 'left' }}>

            <RadioGroup
                disabled="true"
                aria-label="Gender"
                name={name}
                value={value}
                onChange={e => onChange(convertToDefEventPara(name, e.target.value))}
            >
                <Grid container xs={12} spacing={5} style={{ float: 'left' }}>
                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div  >Gender</div>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel value="male" control={<Radio style={{ color: '#7946A9' }} />} label="Male" />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel value="female" control={<Radio style={{ color: '#7946A9' }} />} label="Female" />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControlLabel value="other" control={<Radio style={{ color: '#7946A9' }} />} label="Other" />
                    </Grid>
                </Grid>

            </RadioGroup>
        </FormControl>
    )
}
