import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './routes';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import configureStore from './store'
import { GlobalProvider } from "./context/GlobalState";
const store = configureStore();
export const history = createBrowserHistory();

ReactDOM.render(
  // <Provider store={store} history={history}>
   <GlobalProvider>
    <BrowserRouter>
      <Route path="/" />
      <App />
    </BrowserRouter>
     </GlobalProvider>,
  // </Provider>, 
  document.getElementById('root')
);

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
