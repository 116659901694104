import {  GET_INTERNAL_ASSIGNED_LEADS, GET_INTERNAL_ASSIGNED_LEADS_ERR, GET_INTERNAL_DASHBOARD_COUNT, GET_INTERNAL_DASHBOARD_COUNT_ERR, GET_INTERNAL_REQUEST_LEADS, GET_INTERNAL_REQUEST_LEADS_ERR, UPDATE_INTERNAL_QC_SUBMIT, UPDATE_INTERNAL_QC_SUBMIT_ERR } from '../../actionTypes/internalDashboardType';

export default (state, action) => {
  switch (action.type) {


    case GET_INTERNAL_ASSIGNED_LEADS:
      return {
        ...state,
        getInternalAssignedLeads: action.payload, ...state.data,
      }

    case GET_INTERNAL_ASSIGNED_LEADS_ERR:
      return {
        ...state,
        getInternalAssignedLeads:  action.payload 
      }

      case GET_INTERNAL_DASHBOARD_COUNT:
        return {
          ...state,
          getInternalDashboardCount: action.payload, ...state.data,
        }
  
      case GET_INTERNAL_DASHBOARD_COUNT_ERR:
        return {
          ...state,
          getInternalDashboardCount:  action.payload 
        }

        case GET_INTERNAL_REQUEST_LEADS:
          return {
            ...state,
            getInternalRequestLeads: action.payload, ...state.data,
          }
    
        case GET_INTERNAL_REQUEST_LEADS_ERR:
          return {
            ...state,
            getInternalRequestLeads:  action.payload 
          }
  
      case UPDATE_INTERNAL_QC_SUBMIT:
        return {
          ...state,
          updateInternalQcSubmit: action.payload, ...state.data,
        }
  
      case UPDATE_INTERNAL_QC_SUBMIT_ERR:
        return {
          ...state,
          updateInternalQcSubmit:  action.payload 
        }
  

    default:
      return state;
  }
}
