import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./InternalTableStyle";
import InternalTable from "components/root/InternalTable";

const DashboardTable = React.memo((props) => {
  return (
    <>
      <InternalTable />

    </>
  );
});

export default withRouter(DashboardTable);
