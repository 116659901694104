export default {
  getInternalReportStats: {
    loading: false,
    error: null,
    data: [],
  },
  getExternalReportStats: {
    loading: false,
    error: null,
    data: [],
  },
  getQcDashboardCount: {
    loading: false,
    error: null,
    data: [],
  },
  getQcDownload: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
};
