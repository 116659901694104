import React, { useContext, useState } from "react";
import { styles } from "./VendorReportStyle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@mui/material/LinearProgress';
import moment from "moment";
import { GlobalContext } from "context/GlobalState";
import { useEffect } from "react";
import CardHeader from '@mui/material/CardHeader';
import { getAgencies } from "context/actions/agencyAction/getAgencies";
import { getReasonCodeData } from "context/actions/reasonCodeAction/getReasonCodeAction";
import ReactApexChart from "react-apexcharts";
import Grid from "@material-ui/core/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { getVendorReportStats } from "context/actions/vendorAction/getVendorReportStats";
import { vendorStatsReset } from "context/actions/vendorAction/vendorStatesReset";
import { fontSize } from "@mui/system";


const useStyles = makeStyles(styles);
const VendorReport = React.memo((props) => {
    const {monthval,reasonCodeLoader,setReasonCodeLoader,vendorLoader,setVendorLoader,vendorData,setVendorData,setDashboardCounterLoader,dashboardCounterLoader}=props;
    const classes = useStyles();

    const {
        agencyState,
        agencyDispatch,
        reasonCodeState,
        reasonCodeDispatch,
        vendorState,
        vendorDispatch,
    } = useContext(GlobalContext);

    // var todayDate = new Date();
    // const [fromDate, setFromDate] = useState(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
    // const [toDate, setToDate] = useState(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));

    // const [dateValue, setDateValue] = useState(fromDate);

    const [selectedAgency, setSelectedAgency] = useState("all");
    const [agencyArray, setAgencyArray] = React.useState([]);
    const [agencyLoader, setAgencyLoader] = useState(true);

    // const [vendorData, setVendorData] = useState([]);
    // const [vendorLoader, setVendorLoader] = useState(true);
    const [noVendorData, setNoVendorData] = useState(false);
    const [vendorDataGreenLeads, setVendorDataGreenLeads] = useState([]);
    const [vendorDataYellowLeads, setVendorDataYellowLeads] = useState([]);
    const [vendorDataRedLeads, setVendorDataRedLeads] = useState([]);
    const [vendorDataTotalLeads,setVendorDataTotalLeads] = useState([]);
    const [vendorDataPendingLeads,setVendorDataPendingLeads] = useState([]);

    const [vendorStage1DataGreenLeads, setVendorStage1DataGreenLeads] = useState([]);
    const [vendorStage1DataYellowLeads, setVendorStage1DataYellowLeads] = useState([]);
    const [vendorStage1DataRedLeads, setVendorStage1DataRedLeads] = useState([]);
    const [vendorStage1DataPendingLeads,setVendorStage1DataPendingLeads] = useState([]);

    const [reasonCodeData, setReasonCodeData] = useState([]);
    const [reasonCodeColor, setReasonCodeColor] = useState([]);
    const [reasonCodeQcRemark, setReasonCodeQcCodeRemark] = useState([]);
    const [reasonCodeLeadCount, setReasonCodeLeadCount] = useState([]);
    // const [reasonCodeLoader, setReasonCodeLoader] = useState(true);
    const [reasonCodeNoData, setReasonCodeNoData] = useState(false);
    const [dateKey, setDateKey] = React.useState([]);
    const [dateOnlyKey,setDateOnlyKey] = React.useState([]);
    // const monthvalue = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    // const [monthval,setMonthVal] = React.useState(monthvalue[Number(new Date().getMonth())])

    // const handleDateChange = (newValue) => {
    //     console.log(newValue,'newnewnew')

    //     setDateValue(newValue);

    //     var date = newValue;
    //     var monthNum = date.getMonth();
    //     setMonthVal(monthvalue[monthNum])
    //     var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //     var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    //     setToDate(lastDay)
    //     setFromDate(firstDay);

    //     setReasonCodeLoader(true);
    //     setVendorLoader(true);

    //     vendorStatsReset(true)(vendorDispatch)

    //     var newfromDate = moment(new Date(firstDay)).format("YYYY-MM-DD");
    //     var newToDate = moment(new Date(lastDay)).format("YYYY-MM-DD");

    //     getReasonCodeData({ firstDay: newfromDate, lastDay: newToDate,vendorId: selectedAgency })(reasonCodeDispatch);
    //     getVendorReportStats({ dateFrom: newfromDate, dateTo: newToDate, vendorId: selectedAgency })(vendorDispatch)
    // }

    // const handleAgencyChange = (event) => {

    //     setVendorData([]);
    //     setSelectedAgency(event.target.value);
    //     setVendorLoader(true);
    //     vendorStatsReset(true)(vendorDispatch)
    //     var newfromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
    //     var newToDate = moment(new Date(toDate)).format("YYYY-MM-DD");
    //     getReasonCodeData({ firstDay: newfromDate, lastDay: newToDate,vendorId: event.target.value })(reasonCodeDispatch);
    //     getVendorReportStats({ dateFrom: newfromDate, dateTo: newToDate, vendorId: event.target.value })(vendorDispatch);
    // }

    // useEffect(() => {
    //     const page = "all";
    //     getAgencies({ page })(agencyDispatch);

    //     var newfromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
    //     var newToDate = moment(new Date(toDate)).format("YYYY-MM-DD");

    //     getReasonCodeData({ firstDay: newfromDate, lastDay: newToDate ,vendorId: selectedAgency})(reasonCodeDispatch);
    //     getVendorReportStats({ dateFrom: newfromDate, dateTo: newToDate, vendorId: selectedAgency })(vendorDispatch);

    // }, []);

    useEffect(() => {
        let status =
            agencyState && agencyState.getAgencies && agencyState.getAgencies.status;
        if (status === true) {
            setAgencyLoader(false);
            // setDashboardCounterLoader(false)
            setAgencyArray(agencyState.getAgencies.data.res);
        }
    }, [agencyState]);

    useEffect(() => {
        let status =
            reasonCodeState &&
            reasonCodeState.getReasonCodeDataInitialState &&
            reasonCodeState.getReasonCodeDataInitialState.status;

        if (status === true) {
            setReasonCodeLoader(false);
            // setDashboardCounterLoader(false)
            setReasonCodeData(reasonCodeState.getReasonCodeDataInitialState.data);
            if (reasonCodeState.getReasonCodeDataInitialState.data.length === 0) {
                setReasonCodeNoData(true);
            } else {
                setReasonCodeNoData(false);
            }
        }
    }, [reasonCodeState]);

    useEffect(() => {
        let colorArray = [];
        let leadCountArray = [];
        let qcRemarkArray = [];

        reasonCodeData.map((item) => {
            colorArray.push(item.color);
            leadCountArray.push(item.lead_count);
            qcRemarkArray.push(item.qc_remark);
        });

        const y = "#EABE06";
        const g = "#739449";
        const r = "#D4333F";

        for (var i = 0; i < colorArray.length; i++) {
            if (colorArray[i] === "yellow") {
                colorArray[i] = y;
            } else if (colorArray[i] === "green") {
                colorArray[i] = g;
            } else if (colorArray[i] === "red") {
                colorArray[i] = r;
            }
        }
        console.log(colorArray,qcRemarkArray,'clra')
        setReasonCodeColor(colorArray);
        setReasonCodeLeadCount(leadCountArray);
        setReasonCodeQcCodeRemark(qcRemarkArray);
    }, [reasonCodeData]);

    useEffect(() => {
        // console.log(dashboardCounterLoader,'setDashboardCounterLoader')
        let status =
            vendorState &&
            vendorState.getVendorReportStatsInitialState &&
            vendorState.getVendorReportStatsInitialState.status;
        if (status === true) {
            setVendorLoader(false);
            setVendorData(vendorState.getVendorReportStatsInitialState.data);
            if (vendorState.getVendorReportStatsInitialState.data.length === 0) {
                setNoVendorData(true)
            } else {
                setNoVendorData(false);

            }
        }
    }, [vendorState]);


    useEffect(() => {
        let ot = Object.keys(vendorData);
        let ot1=[];
        for(var i=0;i<ot.length;i++){

            ot1.push(ot[i].split('-')[2])
        }
        setDateOnlyKey(ot1)
        setDateKey(ot)

    }, [vendorData]);

    useEffect(() => {

        let greenLeads = [];
        let redLeads = [];
        let yellowLeads = [];
        let pendingLeads = [];
        let totalLeads = [];
        let greenStage1Leads = [];
        let redStage1Leads = [];
        let yellowStage1Leads = [];
        let pendingStage1Leads = [];
        if(dateKey.length==0){
            setVendorDataGreenLeads([]);
            setVendorDataYellowLeads([]);
            setVendorDataRedLeads([]);
            setVendorDataPendingLeads([]);
            setVendorStage1DataGreenLeads([]);
            setVendorStage1DataYellowLeads([]);
            setVendorStage1DataRedLeads([]);
            setVendorStage1DataPendingLeads([]);
            setVendorDataTotalLeads([]);
        }
        if (dateKey.length != 0 && Object.keys(vendorData).length != 0) {
            for (var i = 0; i < dateKey.length; i++) {
                if (vendorData[dateKey[i]]) {
                    for (var j = 0; j < Object.keys(vendorData[dateKey[i]]).length; j++) {
                        greenLeads.push(vendorData[dateKey[i]][j]['total_green_leads']);
                        redLeads.push(vendorData[dateKey[i]][j]['total_red_leads']);
                        yellowLeads.push(vendorData[dateKey[i]][j]['total_yellow_leads']);
                        pendingLeads.push(vendorData[dateKey[i]][j]['total_pending_leads'])
                        totalLeads.push(vendorData[dateKey[i]][j]['total_leads'])
                        greenStage1Leads.push(vendorData[dateKey[i]][j]['total_stage_1_green_leads']);
                        redStage1Leads.push(vendorData[dateKey[i]][j]['total_stage_1_red_leads']);
                        yellowStage1Leads.push(vendorData[dateKey[i]][j]['total_stage_1_yellow_leads']);
                        pendingStage1Leads.push(vendorData[dateKey[i]][j]['total_stage_1_pending_leads'])

                        
                    }
                }

            }

            setVendorDataGreenLeads(greenLeads);
            setVendorDataYellowLeads(yellowLeads);
            setVendorDataRedLeads(redLeads);
            setVendorDataPendingLeads(pendingLeads);
            setVendorStage1DataGreenLeads(greenStage1Leads);
            setVendorStage1DataYellowLeads(yellowStage1Leads);
            setVendorStage1DataRedLeads(redStage1Leads);
            setVendorStage1DataPendingLeads(pendingStage1Leads);
            setVendorDataTotalLeads(totalLeads);

        }

    }, [vendorState, vendorData, dateKey])


    const reasonCodechartData = {
        series: [
            {
                data: reasonCodeLeadCount,
            },
        ],
        options: {
            chart: {
                type: "line",
                height: 380
            },
            legend: {
                show: false
              },
            plotOptions: {
                bar: {
                    // barHeight: '100%',
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom',
                    },
                }
            },
           
            dataLabels: {
                enabled: false,
                textAnchor: 'start',
                style: {
                    colors: ['#000000']
                },
                offsetX: 0,
            },

            colors: reasonCodeColor,

            xaxis: {
                categories: reasonCodeQcRemark,
                labels: {
                    maxWidth: "auto",
                    style: {
                      fontSize: "12px"
                    }
                  },
                  
            },
            yaxis: {
                labels: {
                    maxWidth: "auto",
                    style: {
                      fontSize: "8px"
                    }
                  },
            },

            tooltip: {
                theme: 'dark',
                x: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                },
                y: {
                    title: {
                        formatter: function () {
                            return ''
                        }
                    }
                }
            }
        },
    };

    const vendorReportChartData = {

        series: [
            // {
            //     name: 'Total Leads',
            //     data: vendorDataTotalLeads
            // },
            {
                name: 'Pending Leads',
                data: vendorDataPendingLeads
            },
            {
                name: 'Green Leads',
                data: vendorDataGreenLeads
            },
            {
                name: 'Yellow Leads',
                data: vendorDataYellowLeads
            },
            {
                name: 'Red Leads',
                data: vendorDataRedLeads
            },
        ],

        options: {
            chart: {
                // type: 'line',
                // height: 350,
                stacked: true
            },
            stroke: {
                width: 1,
                colors: ['#fff']
              },
              fill: {
                opacity: 1
              },
            // stroke: {
            //     curve: 'smooth',
            //   },
            
            plotOptions: {
                bar: {
                    // barHeight: '100%',
                    // distributed: true,
                    horizontal: false,
                    dataLabels: {
                        position: 'bottom',
                    },
                }
            },
            hideOverflowingLabels: true,
            dataLabels: {
                enabled: true,
                // textAnchor: 'start',
                style: {
                    colors: ['#000000'],
                    fontSize: '8px',
                   
                },
                // offsetX: 0,
                // position:'bottom'
            },

            colors: ['grey','#739449', '#EABE06', '#D4333F'],

            xaxis: {
                categories: dateOnlyKey
            },

            // tooltip: {
            //     theme: 'dark',
            //     shared: true,
            //     intersect: false,
            //     x: {
            //         title: {
            //             formatter: function (val, opt) {
            //                 return val
            //             }
            //         }
            //     },
            // }
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: false,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  let datas = ''
                  let total=''
                  Object.keys(vendorData).map(function(key, index) {
                    if(dataPointIndex==index)
                    {
                        vendorData[key].map((items)=>
                        {
                            console.log('items',items)
                          total=items.total_leads
                          datas =datas+`<div style="margin-bottom:10px">
                          <div style="font-size:14px">Pending Leads : <b>${items.total_pending_leads}</b></div>
                          <div style="font-size:14px">Green Leads : <b>${items.total_green_leads}</b></div>
                          <div style="font-size:14px">Yellow Leads : <b>${items.total_yellow_leads}</b></div>
                          <div style="font-size:14px">Red Leads : <b>${items.total_red_leads}</b></div></div>`
                        })  
                    }
                  });
                  return(
                    `
                    <div style="padding:10px;position: relative;height:100%">
                    <div ><b>Total Leads - </b><span style="float:right"><b>${total}</b></span></div><hr>
                    <div>${datas}</div></div>`
                        )
                }
              },
        },
    };

    

    const vendorReportChartData1 = {

        series: [
            // {
            //     name: 'Total Leads',
            //     data: vendorDataTotalLeads
            // },
            {
                name: 'Pending Leads',
                data: vendorStage1DataPendingLeads
            },
            {
                name: 'Green Leads',
                data: vendorStage1DataGreenLeads
            },
            {
                name: 'Yellow Leads',
                data: vendorStage1DataYellowLeads
            },
            {
                name: 'Red Leads',
                data: vendorStage1DataRedLeads
            },
        ],

        options: {
            chart: {
                // type: 'line',
                // height: 350,
                stacked: true
            },
            // stroke: {
            //     curve: 'smooth',
            //   },
            stroke: {
                width: 1,
                colors: ['#fff']
              },
              fill: {
                opacity: 1
              },
            
            plotOptions: {
                bar: {
                    // barHeight: '100%',
                    // distributed: true,
                    // horizontal: true,
                    dataLabels: {
                        position: 'bottom',
                    },
                }
            },
            hideOverflowingLabels: true,
            dataLabels: {
                enabled: true,
                // textAnchor: 'start',
                style: {
                    colors: ['#000000'],
                    fontSize: '8px',
                   
                },
                // offsetX: 0,
                // position:'bottom'
            },

            colors: ['grey','#739449', '#EABE06', '#D4333F'],

            xaxis: {
                categories: dateOnlyKey
            },

            // tooltip: {
            //     theme: 'dark',
            //     shared: true,
            //     intersect: false,
            //     x: {
            //         title: {
            //             formatter: function (val, opt) {
            //                 return val
            //             }
            //         }
            //     },
            // }
            tooltip: {
                enabled: true,
                enabledOnSeries: undefined,
                shared: true,
                followCursor: false,
                intersect: false,
                inverseOrder: false,
                custom: undefined,
                fillSeriesColor: false,
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  let datas = ''
                  let total=''
                  Object.keys(vendorData).map(function(key, index) {
                    if(dataPointIndex==index)
                    {
                        vendorData[key].map((items)=>
                        {
                            console.log('items',items)
                          total=items.total_leads
                          datas =datas+`<div style="margin-bottom:10px">
                          <div style="font-size:14px">Pending Leads : <b>${items.total_stage_1_pending_leads}</b></div>
                          <div style="font-size:14px">Green Leads : <b>${items.total_stage_1_green_leads}</b></div>
                          <div style="font-size:14px">Yellow Leads : <b>${items.total_stage_1_yellow_leads}</b></div>
                          <div style="font-size:14px">Red Leads : <b>${items.total_stage_1_red_leads}</b></div></div>`
                        })  
                    }
                  });
                  return(
                    `
                    <div style="padding:10px;position: relative;height:100%">
                    <div ><b>Total Leads - </b><span style="float:right"><b>${total}</b></span></div><hr>
                    <div>${datas}</div></div>`
                        )
                }
              },
        },
    };

    return (

        <div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                <div>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Select Month"
                                inputFormat="MM/yyyy"
                                views={['year', 'month']}
                                value={dateValue}
                                disableFuture
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider> */}
                </div>

                {/* <div>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">
                            Select Agency
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={selectedAgency}
                            onChange={handleAgencyChange}
                            autoWidth
                            label="Select Agency"
                        >
                            {agencyLoader && (
                                <MenuItem
                                    value=""
                                    style={{ display: "flex", justifyContent: "center" }}
                                >
                                    <CircularProgress size={20} />{" "}
                                </MenuItem>
                            )}

                            <MenuItem value="all">All Agencies</MenuItem>
                            {agencyArray &&
                                agencyArray.map((option) => (
                                    <MenuItem key={option.agency_id} value={option.agency_id}>
                                        {option.agency_name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </div> */}
            </div>


            <div>
                <Grid container  >
                    {
                        vendorLoader ? (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress color="secondary" />
                            </Box>
                        ) :
                        noVendorData ? (
                                <div style={{ textAlign: "center", marginTop: 10 }}>
                                    <h4>
                                        <b>No Data Found</b>
                                    </h4>
                                </div>
                            ) : (
                                <Card variant="outlined" style={{ width: "100%", marginBottom: "20px" }} >
                                    <CardHeader
                                    style={{textAlign:'center'}}
                                        // title="Vendor Report"
                                        subheader=" External Daily Audit Progress"
                                    />
                                   
                                    <CardContent>
                                        
                                      <div style={{ width: "100%" }}>
                                            <ReactApexChart
                                                options={vendorReportChartData1.options}
                                                series={vendorReportChartData1.series}
                                                type="bar"
                                                height='300'
                                                
                                            />
                                        </div>
                                        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>{monthval+' month'}</div>
                                    </CardContent>
                                </Card>
                            )}
                </Grid>
            </div>

            <div>
                <Grid container  >
                    {
                        vendorLoader ? (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress color="secondary" />
                            </Box>
                        ) :
                        noVendorData ? (
                                <div style={{ textAlign: "center", marginTop: 10 }}>
                                    <h4>
                                        <b>No Data Found</b>
                                    </h4>
                                </div>
                            ) : (
                                <Card variant="outlined" style={{ width: "100%", marginBottom: "20px" }} >
                                    <CardHeader
                                    style={{textAlign:'center'}}
                                        // title="Vendor Report"
                                        subheader=" Internal Daily Audit Progress"
                                    />
                                   
                                    <CardContent>
                                        <div style={{ width: "100%" }}>
                                            <ReactApexChart
                                                options={vendorReportChartData.options}
                                                series={vendorReportChartData.series}
                                                type="bar"
                                                height='300'
                                            />
                                        </div>
                                        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>{monthval+' month'}</div>
                                    </CardContent>
                                </Card>
                            )}
                </Grid>
            </div>                        

            <div>
                <Grid container  >
                    {
                        reasonCodeLoader ? (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        ) :
                             (
                                <Card variant="outlined" style={{ width: "100%" }} >
                                      <CardHeader
                                    style={{textAlign:'center'}}
                                        // title="Vendor Report"
                                        subheader=" Daily Audit Reason Code Progress"
                                    />
                                    <CardContent>
                                        <ReactApexChart
                                            options={reasonCodechartData.options}
                                            series={reasonCodechartData.series}
                                            type="bar"
                                            height='600'
                                        />
                                    </CardContent>
                                </Card>
                            )}
                </Grid>
            </div>

        </div>
    );
});

export default withRouter(VendorReport);
