import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Button, TextField } from '@material-ui/core';
import { localRoute } from "../../routes/localRoutes";
import loginBgImage from '../../assets/images/loginBgImage.svg'
import { styles } from "./ForgotPasswordStyle";
import { GlobalContext } from "../../context/GlobalState";
import { loginUser } from '../../context/actions/userAction/loginUser'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import { Link } from "@material-ui/core";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OTPInput, { ResendOTP } from "otp-input-react";
const useStyles = makeStyles(styles);

const Login = React.memo((props) => {
  //console.log('props', props)
  const classes = useStyles();
  const history = useHistory();
  const [OTP, setOTP] = useState("");
  const { userState, userDispatch } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const handleLogin = async (e) => {
    setMessage("")
    setLoader(true)
    const postData = {
      username: username,
    }
    setTimeout(() => {
      // loginUser(postData)(userDispatch)
    }, 1000)
  }

  function validateForm() {
    return username.length > 0;
  }

  const back = () => {
    history.push('/')
  }


  return (
    <>


      <Grid item xs={12}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh', background: "#fff" }}
      >
        <form className={classes.loginForm} noValidate autoComplete="off">
          <Grid xs={12}>
            <div className={classes.loginTitle}> Forgot Password</div>

          </Grid><br />
          <Grid xs={12} spacing={0}>

            <div className={classes.loginTitle1}> You can reset your password here</div>
          </Grid>
          <br />

          <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} />
          <br />
          <ResendOTP onResendClick={() => console.log("Resend clicked")} />

          <div className={classes.errorMsg}>
            {message}
          </div>
          <Grid xs={6} spacing={0} >
            <Link style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => back()} style={{ position: 'absolute' }}> <ArrowBackIcon style={{ position: 'relative', top: '7px' }} /> <label>Back to Login</label></Link>
          </Grid>



        </form>
      </Grid>

    </>
  );
});

export default withRouter(Login);
