export default {

    getVendorTableDataInitialState: {
        loading: false,
        error: null,
        data: [],
        status: false,
        message: '',
    },

    getVendorReportStatsInitialState: {
        loading: false,
        error: null,
        data: [],
        status: false,
        message: '',
    }
}