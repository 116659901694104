import React, { useEffect, useState, useContext } from 'react'
import styles from "./InternalTable.module.css"
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import SearchBar from "material-ui-search-bar";
import greenToYellow from "../../../assets/images/greenToYellow.png"
import greenToRed from "../../../assets/images/greenToRed.png"
import yellowToGreen from "../../../assets/images/yellowToGreen.png"
import yellowToRed from "../../../assets/images/yellowToRed.png"
import redToGreen from "../../../assets/images/redToGreen.png"
import redToYellow from "../../../assets/images/redToYellow.png"
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@material-ui/core/Box';
import Stack from '@mui/material/Stack';
import { GlobalContext } from "context/GlobalState";
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment'
import XLSX from 'xlsx'
import Button from '@material-ui/core/Button'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { getInternalReportStats } from 'context/actions/rootAction/getInternalReportStats';
import { getQcDownload } from 'context/actions/rootAction/getQcDownload';
import { CircularProgress } from '@material-ui/core';
const StyledTableCell = withStyles((theme) => ({
    head: {
        border: 'none',
        color: 'rgb(136 136 136 / 55%)',
        background: '#F2F2F2'
    },
    body: {
        fontSize: 14,
        //border: 'none'
    },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
    {
        id: 'id',
        label: 'ID',
        minWidth: 60,
        align: 'left',
        icon: < LastPageIcon />,
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'name',
        label: 'Name',
        minWidth: 60,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'contactNumber',
        label: 'Contact Number',
        minWidth: 60,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },

    {
        id: 'leadsDone',
        label: 'Audited Leads',
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'correctionLeads',
        label: 'Correction Leads',
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },

    {
        id: 'greenToYellow',
        label: <img src={greenToYellow} alt="/" style={{ width: "100px", height: "50px" }} />,
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'greenToRed',
        label: <img src={greenToRed} alt="/" style={{ width: "100px", height: "50px" }} />,
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'yellowToGreen',
        label: <img src={yellowToGreen} alt="/" style={{ width: "100px", height: "50px" }} />,
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'yellowToRed',
        label: <img src={yellowToRed} alt="/" style={{ width: "100px", height: "50px" }} />,
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'redToGreen',
        label: <img src={redToGreen} alt="/" style={{ width: "100px", height: "50px" }} />,
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'redToYellow',
        label: <img src={redToYellow} alt="/" style={{ width: "100px", height: "50px" }} />,
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'correctionRatio',
        label: 'Correction Ratio',
        minWidth: 60,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
];

const InternalTable = () => {
    const [tableData, setTableData] = useState([])
    const [value, setValue] = React.useState(new Date());
    const [rows, setRows] = useState(tableData);
    const [searched, setSearched] = useState("");
    const [countData, setCountData] = useState([]);
    const [noData, setNoData] = useState(false);
    const [auditedLeads, setAuditedLeads] = useState(true);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [auditedCount, setAuditedCount] = useState(0);
    const [correctedCount, setCorrectedCount] = useState(0);
    const [correctedRange, setCorrectedRange] = useState(0);
    const { rootState, rootDispatch } = useContext(GlobalContext);
    const [gy, setGy] = useState(true);
    const [gr, setGr] = useState(true);
    const [yr, setYr] = useState(true);
    const [yg, setYg] = useState(true);
    const [rg, setRg] = useState(true);
    const [ry, setRy] = useState(true);
    const [correctionRatio, setCorrectionRatio] = useState(true);
    const [dir, setDir] = useState(true)
    const handleDirection = (column, direc) => {
        console.log(direc,column, 'direc')
        if(column!=='correction_ratio')setCorrectionRatio(true);
        if(column!=='green_to_yellow')setGy(true);
        if(column!=='green_to_red')setGr(true);
        if(column!=='yellow_to_green')setYg(true);
        if(column!=='yellow_to_red')setYr(true);
        if(column!=='red_to_green')setRg(true);
        if(column!=='red_to_yellow')setRy(true);

        setDir(!dir);
        let ot;
        if (!direc) {
            ot = [...tableData].sort((a, b) => (a[column] - b[column]));
        } else {
            ot = [...tableData].sort((a, b) => (b[column] - a[column]))
        }
        setRows(ot)

    }
    const correctionLeadsFunc = (row) => {
        return (row.green_to_yellow + row.green_to_red + row.yellow_to_green + row.yellow_to_red + row.red_to_green + row.red_to_yellow)

    }

    const requestSearch = (searchedVal) => {
        const filteredRows = tableData.filter((row) => {
            return row.full_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.qc_admin_id.toString().toLowerCase().includes(searchedVal.toLowerCase()) || row.number.toString().toLowerCase().includes(searchedVal.toLowerCase()) || row.audited_leads.toString().toLowerCase().includes(searchedVal.toLowerCase()) || (row.audited_leads - (row.green_to_yellow + row.green_to_red + row.yellow_to_green + row.yellow_to_red + row.red_to_green + row.red_to_yellow)).toString().toLowerCase().includes(searchedVal.toLowerCase())
        });
        setRows(filteredRows);
        setNoData(true)
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {

        let date = moment(value).format('YYYY-MM-DD')
        getInternalReportStats({ date: date })(rootDispatch)
    }, [])

    useEffect(() => {
        console.log('rootState', rootState.getInternalReportStats)
        let status = rootState.getInternalReportStats && rootState.getInternalReportStats.data && rootState.getInternalReportStats.status
        if (status == true) {
            setTableData(rootState.getInternalReportStats.data);
            setRows(rootState.getInternalReportStats.data)

            let auditedCount = 0
            rootState.getInternalReportStats.data.map((item)=>{
                auditedCount = Number(auditedCount) +Number(item.audited_leads)
            })

            let correctedLeadsCount = 0
            rootState.getInternalReportStats.data.map((item)=>{
                correctedLeadsCount = Number(correctedLeadsCount) + Number(item.green_to_yellow + item.green_to_red + item.yellow_to_green + item.yellow_to_red + item.red_to_green + item.red_to_yellow)
            })

            setAuditedCount(auditedCount)
            setCorrectedCount(correctedLeadsCount)

            let correction_ratio = Number(correctedLeadsCount) * 100 / Number(auditedCount)
            setCorrectedRange(correction_ratio)
            if (rootState.getInternalReportStats.data.length === 0) {
                setNoData(true);
            } else {
                setNoData(false);
            }
            rootState.getInternalReportStats = {
                loading: false,
                error: null,
                data: null,
            }
        }

        console.log("rootState.getInternalReportStats", rootState.getInternalReportStats)
        if (status === 401) {
            // setLoader(false)

            rootState.getInternalReportStats = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [rootState])

    useEffect(() => {
        console.log('rootState', rootState.getQcDownload)
        let status = rootState.getQcDownload && rootState.getQcDownload.data && rootState.getQcDownload.status
        if (status == true) {
            setDownloadLoader(false)
            console.log('rootState.getQcDownload', rootState.getQcDownload)

            let finalHeader = ["Lead ID", "Stage 1 Auditor ID", "Stage 1 Auditor Name", "Stage 1 QC Remark", "Stage 1 QC Status","Stage 2 Auditor ID", "Stage 2 Auditor Name", "Stage 2 QC Remark", "Stage 2 QC Status"]
            const records = []
            rootState.getQcDownload && rootState.getQcDownload.data.map((item, index) => {
                const innerData = []

                innerData.push(item.lead_id, item.stage_1_qc_admin_id, item.stage_1_qc_name, item.stage_1_qc_remark, item.stage_1_qc_status,item.stage_2_qc_admin_id, item.stage_2_qc_name, item.stage_2_qc_remark, item.stage_2_qc_status)
                records.push([...innerData])
            })
            const data = [finalHeader, ...records]
            const newData = records;
            const workSheet = XLSX.utils.json_to_sheet(data, { skipHeader: 1 })
            const workBook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workBook, workSheet, "Internal Auditor Report")
            //Buffer
            let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
            //Download

            XLSX.writeFile(workBook, "internal-auditor-Report.xlsx")
            rootState.getQcDownload = {
                loading: false,
                error: null,
                data: null,
            }
        }

        // console.log("rootState.getQcDownload", rootState.getQcDownload)
        if (status === 401) {
            // setLoader(false)

            rootState.getQcDownload = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [rootState])




    const handleChange = (newValue) => {
        setValue(newValue);
        setTableData([]);
        setRows([])
        // setNoData(true)
        let date = moment(newValue).format('YYYY-MM-DD')
        getInternalReportStats({ date: date })(rootDispatch)
    };

    const downloadExcel = () => {
        setDownloadLoader(true)
        let date = moment(value).format('YYYY-MM-DD')
        getQcDownload({ qc_admin_id: "all", pervious_qc_status: 'all', current_qc_status: "all", date: date })(rootDispatch)

    }

    return (
        <>
            <div className={styles.row2}>
                <div>
                    <div style={{ width: "100%", display: "flex" }} >
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={3} item>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            label="Select Date"
                                            inputFormat="dd/MM/yyyy"
                                            value={value}
                                            maxDate={new Date()}
                                            onChange={handleChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={5} item>
                                <div style={{ color: "white", textAlign: "center", background: 'grey', fontWeight: "600", padding: "10px", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", height: '50%' }} >
                                    <Grid item xs={12} container spacing={2} style={{margin:0}}>
                                        <Grid item xs={4} item>
                                            <div>
                                                <div>{auditedCount}</div>
                                                <hr style={{margin:0}}/>
                                                <div style={{ fontSize: 12 }}>Audited Leads</div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} item >
                                            <div>
                                                <div>{correctedCount}</div>
                                                <hr style={{margin:0}}/>
                                                <div style={{ fontSize: 12 }}>Corrected Leads</div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} item>
                                            <div>
                                                <div>{correctedRange.toFixed(2)}%</div>
                                                <hr style={{margin:0}}/>
                                                <div style={{ fontSize: 12 }}>Correction Range</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={4} item >
                                <div style={{ width: "100%", display: "flex", marginBottom: "20px", justifyContent: "flex-end", }} >
                                    <SearchBar
                                        value={searched}
                                        onChange={(searchVal) => requestSearch(searchVal)}
                                        onCancelSearch={() => cancelSearch()}
                                        style={{ border: "0.5px solid #b2b2b2" }}
                                    />
                                    &nbsp;
                                    <div style={{ display: "flex", justifyContent: "flex-end", }} >
                                        <Button type="button" variant="outlined" size="small" onClick={() => downloadExcel()}startIcon={<FileDownloadIcon />}>
                                            {downloadLoader == true ? <CircularProgress size={20} /> : "Download"}</Button></div>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                    <Grid item xs={12} style={{ width: '100%', overflowX: 'auto', display: 'inline-grid' }}>
                        <TableContainer sx={{ maxHeight: 550 }}>
                            <Table aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'black', fontWeight: 700, background: 'rgb(136 136 136 / 55%)', backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            ID
                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'black', fontWeight: 700, background: 'rgb(136 136 136 / 55%)', backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Name
                                        </StyledTableCell >

                                        <StyledTableCell align='center' style={{ minWidth: "130px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Email ID
                                        </StyledTableCell>
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'black', fontWeight: 700, background: 'rgb(136 136 136 / 55%)', backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            Contact Number
                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'black', fontWeight: 700, background: 'rgb(136 136 136 / 55%)', backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div>ReAudited Leads</div>
                                                <div><TableSortLabel
                                                    style={{ color: 'black !important' }}
                                                    active={true}
                                                    direction={auditedLeads ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setAuditedLeads(!auditedLeads)
                                                        handleDirection('audited_leads', auditedLeads)
                                                        setDir(!dir)
                                                    }}
                                                ></TableSortLabel></div>
                                            </div>
                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                                <div>Corrected Leads</div>
                                                {/* <div><TableSortLabel active={false} /></div> */}
                                            </div>


                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'black', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >


                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>Correction Ratio</div>
                                                <div><TableSortLabel
                                                    active={true}
                                                    direction={correctionRatio ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setCorrectionRatio(!correctionRatio)
                                                        handleDirection('correction_ratio', correctionRatio)
                                                        setDir(!dir)
                                                    }}></TableSortLabel></div>
                                            </div>
                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'white', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >

                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center' }}>
                                                <div><img src={greenToYellow} alt="/" style={{ width: "100px", height: "50px" }} /></div>
                                                <div><TableSortLabel
                                                    active={true}
                                                    direction={gy ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setGy(!gy)
                                                        handleDirection('green_to_yellow', gy)
                                                        setDir(!dir)
                                                    }}></TableSortLabel></div>
                                            </div>

                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'white', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div><img src={greenToRed} alt="/" style={{ width: "100px", height: "50px" }} /></div>
                                                <div><TableSortLabel
                                                    active={true}
                                                    direction={gr ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setGr(!gr)
                                                        handleDirection('green_to_red', gr)
                                                        setDir(!dir)
                                                    }}></TableSortLabel></div>
                                            </div>
                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'white', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div><img src={yellowToGreen} alt="/" style={{ width: "100px", height: "50px" }} /></div>
                                                <div> <TableSortLabel
                                                    active={true}
                                                    direction={yg ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setYg(!yg)
                                                        handleDirection('yellow_to_green', yg)
                                                        setDir(!dir)
                                                    }}></TableSortLabel></div>
                                            </div>
                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'white', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div><img src={yellowToRed} alt="/" style={{ width: "100px", height: "50px" }} /></div>
                                                <div><TableSortLabel
                                                    active={true}
                                                    direction={yr ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setYr(!yr)
                                                        handleDirection('yellow_to_red', yr)
                                                        setDir(!dir)
                                                    }}></TableSortLabel></div>
                                            </div>

                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'white', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div><img src={redToGreen} alt="/" style={{ width: "100px", height: "50px" }} /></div>
                                                <div><TableSortLabel
                                                    active={true}
                                                    direction={rg ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setRg(!rg)
                                                        handleDirection('red_to_green', rg)
                                                        setDir(!dir)
                                                    }}></TableSortLabel></div>
                                            </div>
                                        </StyledTableCell >
                                        <StyledTableCell align='center' style={{ minWidth: "60px", color: 'white', fontWeight: 700, backgroundColor: 'rgb(136 136 136 / 55%)' }} >

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div><img src={redToYellow} alt="/" style={{ width: "100px", height: "50px" }} /></div>
                                                <div><TableSortLabel
                                                    active={true}
                                                    direction={ry ? 'asc' : 'desc'}
                                                    onClick={() => {
                                                        setRy(!ry)
                                                        handleDirection('red_to_yellow', ry)
                                                        setDir(!dir)
                                                    }}></TableSortLabel></div>
                                            </div>

                                        </StyledTableCell >

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rows && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows && rows
                                    ).map((row, index) => {
                                        return (

                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                                                <StyledTableCell align="left">
                                                    {row.qc_admin_id}
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    align="left"

                                                >
                                                    {row.full_name}

                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.email_id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.number}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.audited_leads}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {/* {row.audited_leads - (row.green_to_yellow + row.green_to_red + row.yellow_to_green + row.yellow_to_red + row.red_to_green + row.red_to_yellow)} */}
                                                    {correctionLeadsFunc(row)}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">

                                                    <div style={{
                                                        color: row.correction_ratio >= 85 ? "green" : row.correction_ratio <= 70 ? "red" : '#e8e815'
                                                    }} >
                                                        {row.correction_ratio.toFixed(2)}%
                                                    </div>

                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.green_to_yellow}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.green_to_red}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.yellow_to_green}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.yellow_to_red}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.red_to_green}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.red_to_yellow}
                                                </StyledTableCell>


                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>

                            {noData === false &&
                                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}

                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        style={{ borderBottom: "none" }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </div>
                            }

                        </TableContainer>
                        {(rows && rows.length === 0 && noData === false) ?
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                            :
                            rows && rows.length == 0 && <div style={{ textAlign: 'center', marginTop: 10 }}><h4><b>No Data Found</b></h4></div>}
                    </Grid>

                </div>
            </div>
        </>
    )
}

export default InternalTable