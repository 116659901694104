import { CREATE_AUDIT, CREATE_AUDIT_ERR, GET_AUDITS, GET_AUDITS_ERR, GET_AUDIT, GET_AUDIT_ERR, UPDATE_AUDIT, UPDATE_AUDIT_ERR } from '../../actionTypes/auditType';

export default (state, action) => {
  switch (action.type) {
    case GET_AUDIT:
      return {
        ...state,
        getAudit: action.payload, ...state.data,
      }

    case GET_AUDIT_ERR:
      return {
        ...state,
        getAudit:  action.payload 
      }

    case GET_AUDITS:
      return {
        ...state,
        getAudits: action.payload, ...state.data,
      }

    case GET_AUDITS_ERR:
      return {
        ...state,
        getAudits:  action.payload 
      }

    case UPDATE_AUDIT:
      return {
        ...state,
        updateAudit: action.payload, ...state.data,
      }

    case UPDATE_AUDIT_ERR:
      return {
        ...state,
        updateAudit:  action.payload 
      }

    case CREATE_AUDIT:
      return {
        ...state,
        createAudit: action.payload, ...state.data,
      }

    case CREATE_AUDIT_ERR:
      return {
        ...state,
        createAudit: action.payload, ...state.data,
      }
    default:
      return state;
  }
}
