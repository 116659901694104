export const GET_AUDITS = 'GET_AUDITS'
export const GET_AUDITS_ERR = 'GET_AUDITS_ERR'

export const GET_AUDIT = 'GET_AUDIT'
export const GET_AUDIT_ERR = 'GET_AUDIT_ERR'

export const UPDATE_AUDIT = 'UPDATE_AUDIT'
export const UPDATE_AUDIT_ERR = 'UPDATE_AUDIT_ERR'

export const CREATE_AUDIT = 'CREATE_AUDIT'
export const CREATE_AUDIT_ERR = 'CREATE_AUDIT_ERR'