let Base_Url = ``;
if (process.env.REACT_APP_NODE_ENV === "production") {
    Base_Url = `https://amazon.web.api.taskmo.co/api`;
} else if (process.env.REACT_APP_NODE_ENV === "development") {
    Base_Url = `https://amazon.web.api.taskmo.co/api`;
} else {
    Base_Url = `http://localhost:8012/api`;
}


export const loginUserApi = `${Base_Url}/qc-login`;
export const otpUserApi = `${Base_Url}/qc-verify-new`;
export const getInternalReportStatsApi = `${Base_Url}/internal-qc-stats`;
export const getExternalReportStatsApi = `${Base_Url}/external-qc-stats`;
export const getQcDashboardCountApi = `${Base_Url}/qc-dashboard-count`;
export const getUsersApi = `${Base_Url}/users`;
export const getUserApi = `${Base_Url}/user`;

export const createAuditApi = `${Base_Url}/create-qc-access`;
export const getAuditApi = `${Base_Url}/qc-access`;
export const getAuditsApi = `${Base_Url}/get-all-qc-access`;
export const updateAuditApi = `${Base_Url}/update-qc-access`;

export const getLeadApi = `${Base_Url}/lead`;
export const updateLeadApi = `${Base_Url}/update-lead`;
export const updateLeadReasonSubmitApi = `${Base_Url}/update-lead-remark`;
export const getAnalysisApi = `${Base_Url}/fse-wise-qc-analytics`;
export const getReasonListApi = `${Base_Url}/reason-list`;


export const getQcLeadDetailApi = `${Base_Url}/qc-lead-details`;
export const updateQcAllSubmitApi = `${Base_Url}/update-qc-all-submit`
export const updateQcDuplicateSubmitApi = `${Base_Url}/qc-duplicate-submit`

export const getExternalAssignedLeadsApi = `${Base_Url}/qc-stage-1-assigned-leads`;
export const getExternalDashboardCountApi = `${Base_Url}/stage-1-qc-stats`;
export const getExternalRequestLeadsApi = `${Base_Url}/stage-1-request-new-lead`;
export const updateExternalQcSubmitApi = `${Base_Url}/update-stage-1-qc-submit`

export const getInternalAssignedLeadsApi = `${Base_Url}/qc-assigned-leads`;
export const getInternalDashboardCountApi = `${Base_Url}/qc-stats`;
export const getInternalRequestLeadsApi = `${Base_Url}/qc-request-new-lead`;
export const updateInternalQcSubmitApi = `${Base_Url}/update-qc-submit`
export const qcDownloadApi = `${Base_Url}/qc-download`


export const getVendorReportApi = `${Base_Url}/vendor-report`;
export const getAgenciesApi = `${Base_Url}/all-agencies`;
export const getResonCodeApi = `${Base_Url}/external-qc-remark`;
export const getVendorReportStatsApi = `${Base_Url}/daily-audit-stats`;

export const blockFseApi = `${Base_Url}/block-fse`;

export const getFseLeadApi = `${Base_Url}/fse-lead`;
