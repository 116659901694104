import { GET_AGENCIES, GET_AGENCIES_ERR } from "context/actionTypes/agencyType"

export default (state, action) => {
    switch (action.type) {
        case GET_AGENCIES:
            return {
                ...state,
                getAgencies: action.payload, ...state.data,
            }

        case GET_AGENCIES_ERR:
            return {
                ...state,
                getAgencies: action.payload
            }
    }
}