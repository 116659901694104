import { GET_QC_DASHBOARD_COUNT, GET_QC_DASHBOARD_COUNT_ERR } from '../../actionTypes/rootType'
import { getQcDashboardCountApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getQcDashboardCount = payload => async dispatch => {
    try {
        let results = await axios.get(`${getQcDashboardCountApi}/${payload.vendorId}/${payload.startDate}/${payload.endDate}`, await accessToken())
        //console.log("GET Dashboard Stats", results)
        let data = results && results.data
        dispatch({
            type: GET_QC_DASHBOARD_COUNT,
            payload: data || [],
        });
    } catch (error) {
        if ([403].includes(error && error.response && error.response.status)) {
            localStorage.clear();
        }
        dispatch({
            type: GET_QC_DASHBOARD_COUNT_ERR,
            payload: error && error.response
        });
    }
}