import { getResonCodeApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
import { GET_REASON_CODE_DATA, GET_REASON_CODE_DATA_ERR } from 'context/actionTypes/reasonCodeActionType';

export const getReasonCodeData = payload => async dispatch => {
    try {
        // console.log('payload', payload)
        let results = await axios.get(`${getResonCodeApi}/${payload.vendorId}/${payload.firstDay}/${payload.lastDay}`, await accessToken())
        let data = results && results.data
        dispatch({
            type: GET_REASON_CODE_DATA,
            payload: data || [],
        });
    } catch (error) {

        dispatch({
            type: GET_REASON_CODE_DATA_ERR,
            payload: error && error.response
        });
    }
}
