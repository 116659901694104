import React from 'react'
import { useState } from 'react';
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { useContext } from 'react';
import { GlobalContext } from 'context/GlobalState';
import { Form, useForm } from 'components/useForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { Button } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SuccessNotification from 'components/popUpNotification/SuccessNotification';
import FailureNotification from 'components/popUpNotification/FailureNotification';
import { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { blockFse } from 'context/actions/fseAction/blockFseAction';
const BlockFse = React.memo((props) => {

    const [failureOpen, setFailureOpen] = React.useState(false);
    const [failureMessage, setFailureMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { fseState, fseDispatch } = useContext(GlobalContext);
    const [open1, setOpen1] = React.useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('sm');
    const [loader, setLoader] = useState(false);
    const [status, setStatus] = useState('');

    const initialFValues = Object.freeze({
        fse_number: '',
    });


    useEffect(() => {
        if (props && props.data) {
            console.log('props.data', props.data)
            setValues({
                ...values,
                fse_number: props.data
            })
        }
    }, [props])


    const validate = (fieldValues = values) => {
        let temp = { ...errors }

        if ('fse_number' in fieldValues)
            temp.fse_number = fieldValues.fse_number ? "" : "This field is required."

        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handlenumberChange,
    } = useForm(initialFValues, true, validate);

    const HandleSubmit = e => {
        e.preventDefault()
        setLoader(true)
        let postData = {
            mobile_number: values.fse_number,
            block_reason: status
        }
        setTimeout(() => {
            console.log(postData, "checkPostData")
            blockFse(postData)(fseDispatch)
        }, 500)

    }

    const handleNotificationClickOpen = () => {
        setOpen1(true);

    };

    const handleNotificationClose = () => {
        setOpen1(false)
        setValues('')
        setStatus('')
        props.handleClose()
    };

    const handleFailureNotificationClickOpen = () => {
        setFailureOpen(true);
    };

    const handleFailureNotificationClose = () => {
        setFailureOpen(false);

    };

    useEffect(() => {

        let status = fseState.blockFse.data && fseState.blockFse.status
        if (status == true) {
            setLoader(false)
            setSuccessMessage(fseState.blockFse.message)
            handleNotificationClickOpen()

            fseState.blockFse = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            setLoader(false)
            setFailureMessage(fseState.blockFse.data.error.errors[0].msg)
            handleFailureNotificationClickOpen()

            fseState.blockFse = {
                loading: false,
                error: null,
                data: [],
            }
        }
    }, [fseState])


    const handleChange = (e) => {
        setStatus(e.target.value)
    }

    return (
        <div>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent style={{ padding: "15px" }}>
                    <Form onSubmit={HandleSubmit}>

                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <div style={{ fontSize: '24px' }}><b>Block FSE</b></div>
                            </Grid>

                            <Grid item xs={12}>

                                <TextField disabled
                                    id="outlined-basic"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="FSE Number"
                                    name="fse_number"
                                    value={values.fse_number}
                                    onChange={handlenumberChange}
                                    error={errors.fse_number}
                                />

                            </Grid>
                            <Grid xs={12} item>
                                <FormControl fullWidth required>
                                    <InputLabel id="demo-simple-select-label">Select Reason</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        label="Select Reason"
                                        onChange={handleChange}
                                        required
                                    >
                                        <MenuItem value="Using Fake QR Code">Using Fake QR Code</MenuItem>
                                        <MenuItem value="Uploading Mobile Capture images">Uploading Mobile Capture images</MenuItem>
                                        <MenuItem value="Creating Duplicate leads">Creating Duplicate leads</MenuItem>
                                        <MenuItem value="On boarding Closed shops">On boarding Closed shops</MenuItem>
                                        <MenuItem value="Intentionally creating fake leads/Not pasting the stickers as per stickering guidelines">Intentionally creating fake leads/Not pasting the stickers as per stickering guidelines</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item justifyContent="flex-end" style={{ display: "flex" }} sm={12} >
                                <Button type="submit"
                                    style={{ textTransform: 'none', padding: '8px 40px', background: "black", color: "white" }}
                                    size="large"
                                    startIcon={<SaveIcon />}
                                >
                                    {loader ? <CircularProgress size={20} style={{ color: "white" }} /> : "Block FSE"}
                                </Button>
                            </Grid>
                        </Grid>

                    </Form>
                </DialogContent>
            </Dialog>

            <SuccessNotification
                open={open1}
                msg={successMessage}
                handleClose={handleNotificationClose}
            />

            <FailureNotification
                open={failureOpen}
                msg={failureMessage}
                handleClose={handleFailureNotificationClose}
            />
        </div>
    )
})

export default withRouter(BlockFse)