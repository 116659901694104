

export const UPDATE_QC_ALL_SUBMIT = 'UPDATE_QC_ALL_SUBMIT'
export const UPDATE_QC_ALL_SUBMIT_ERR = 'UPDATE_QC_ALL_SUBMIT_ERR'
export const GET_QC_LEAD_DETAIL = 'GET_QC_LEAD_DETAIL'
export const GET_QC_LEAD_DETAIL_ERR = 'GET_QC_LEAD_DETAIL_ERR'
export const UPDATE_QC_ALL_SUBMIT_REMARK = 'UPDATE_QC_ALL_SUBMIT_REMARK'
export const UPDATE_QC_ALL_SUBMIT_REMARK_ERR = 'UPDATE_QC_ALL_SUBMIT_REMARK_ERR'
export const UPDATE_DUPLICATE_QC_SUBMIT = 'UPDATE_DUPLICATE_QC_SUBMIT'
export const UPDATE_DUPLICATE_QC_SUBMIT_ERR = 'UPDATE_DUPLICATE_QC_SUBMIT_ERR'
export const UPDATE_LEAD = 'UPDATE_LEAD'
export const UPDATE_LEAD_ERR = 'UPDATE_LEAD_ERR'
export const UPDATE_LEAD_REASON_SUBMIT = 'UPDATE_LEAD_REASON_SUBMIT'
export const UPDATE_LEAD_REASON_SUBMIT_ERR = 'UPDATE_LEAD_REASON_SUBMIT_ERR'
