import { STATE_RESET, STATE_RESET_ERR } from "context/actionTypes/stateResetType";

const { GET_VENDOR_TABLE_DATA, GET_VENDOR_TABLE_DATA_ERR, GET_VENDOR_REPORT_STATS, GET_VENDOR_REPORT_STATS_ERR, } = require("context/actionTypes/vendorType");

export default (state, action) => {
    switch (action.type) {
        case GET_VENDOR_TABLE_DATA:
            return {
                ...state,
                getVendorTableDataInitialState: action.payload, ...state.data,
            }

        case GET_VENDOR_TABLE_DATA_ERR:
            return {
                ...state,
                getVendorTableDataInitialState: action.payload
            }

        case GET_VENDOR_REPORT_STATS:
            return {
                ...state,
                getVendorReportStatsInitialState: action.payload, ...state.data,
            }

        case GET_VENDOR_REPORT_STATS_ERR:
            return {
                ...state,
                getVendorReportStatsInitialState: action.payload
            }

        case STATE_RESET:
            return {
                ...state,
                getVendorTableDataInitialState: {
                    loading: false,
                    error: null,
                    data: [],
                    status: false,
                    message: '',
                },
                getVendorReportStatsInitialState: {
                    loading: false,
                    error: null,
                    data: [],
                    status: false,
                    message: '',
                },

            }

        default:
            return state
    }

}