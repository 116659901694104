import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Button, TextField } from '@material-ui/core';
import { localRoute } from "../../routes/localRoutes";
import loginBgImage from '../../assets/images/loginBgImage.svg'
import { styles } from "./ForgotPasswordStyle";
import { GlobalContext } from "../../context/GlobalState";
import { loginUser } from '../../context/actions/userAction/loginUser'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import { Link } from "@material-ui/core";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const useStyles = makeStyles(styles);

const Login = React.memo((props) => {
  //console.log('props',props)
  const { userState, userDispatch } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
const handleClickShowPassword = () => setShowPassword(!showPassword);
const handleMouseDownPassword = () => setShowPassword(!showPassword);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);
const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const handleLogin = async (e) => {
    setMessage("")
    setLoader(true)
    const postData = {
      username: username,
    }
    setTimeout(() => {
      // loginUser(postData)(userDispatch)
    }, 1000)
  }

  function validateForm() {
    return username.length > 0;
  }

  const back=()=>
  {
    history.push('/')
  }
  

  return (
    <>
      
    
        <Grid item xs={12}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh', background: "#fff" }}
        >
          <form className={classes.loginForm} noValidate autoComplete="off">
            <Grid xs={12}>
              <div className={classes.loginTitle}> Forgot Password</div>
             
            </Grid><br />
            <Grid xs={12} spacing={0}>
        
              <div className={classes.loginTitle1}> You can reset your password here</div>
            </Grid>
<br />
            <Grid xs={12} spacing={0}>
            <TextField style={{    width: '100%'}} xs={12} id="filled-basic" value={password} onChange={e => setPassword(e.target.value)} label="Enter Password" InputProps={{ classes }} variant="filled" 
               type={showPassword ? "text" : "password"} // <-- This is where the magic happens
              
               InputProps={{ // <-- This is where the toggle button is added.
                 endAdornment: (
                   <InputAdornment position="end">
                     <IconButton
                       aria-label="toggle password visibility"
                       onClick={handleClickShowPassword}
                       onMouseDown={handleMouseDownPassword}
                     >
                       {showPassword ? <Visibility /> : <VisibilityOff />}
                     </IconButton>
                   </InputAdornment>
                 )
               }}/>
                
            </Grid>
            <Grid xs={12} spacing={0}>
                <TextField xs={12} style={{width: '100%'}} id="filled-basic" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} label="Enter Confirm Password" InputProps={{ classes }} variant="filled" 
               type={showConfirmPassword ? "text" : "password"} // <-- This is where the magic happens
              
               InputProps={{ // <-- This is where the toggle button is added.
                 endAdornment: (
                   <InputAdornment position="end">
                     <IconButton
                       aria-label="toggle password visibility"
                       onClick={handleClickShowConfirmPassword}
                       onMouseDown={handleMouseDownConfirmPassword}
                     >
                       {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                     </IconButton>
                   </InputAdornment>
                 )
               }}/>
            </Grid>
            
            <Grid xs={12} spacing={0}>
              <Button variant="contained" onClick={(e) => handleLogin(e)} fullWidth
                disabled={!validateForm()}
                size="medium"
                style={{width:'100%'}}
                className={classes.loginBtn}
              >
                {
                  loader ? <CircularProgress style={{ color: '#fff' }} size={24} /> : "Save Password"
                }

              </Button>
            </Grid>


            <div className={classes.errorMsg}>
              {message}
            </div>
            <Grid xs={6} spacing={0} >
             <Link  style={{marginLeft:'8px',cursor: 'pointer'}} onClick={()=>back()} style={{position:'absolute'}}> <ArrowBackIcon style={{    position: 'relative',top: '7px'}}/> <label>Back to Login</label></Link>
            </Grid>



          </form>
        </Grid>
      
    </>
  );
});

export default withRouter(Login);
