export default {
  users: {
    loading: false,
    error: null,
    data: [],
  },
  addUser: {
    loading: false,
    error: null,
    data: [],
  },
  removeUser: {
    loading: false,
    error: null,
    data: [],
  },
  editUser: {
    loading: false,
    error: null,
    data: [],
  },
  loginUser: {
    loading: false,
    error: null,
    data: [],
  },
  otpUser: {
    loading: false,
    error: null,
    data: [],
  },
  createImageUrl: {
    loading: false,
    error: null,
    data: [],
  },
  getSessionStatus: {
    loading: false,
    error: null,
    data: [],
  },
  updateAttendanceCheck: {
    loading: false,
    error: null,
    data: [],
  },
  getPortalUserLog: {
    loading: false,
    error: null,
    data: [],
  },
  createProfileImageUrl: {
    loading: false,
    error: null,
    data: [],
  },
  uploadChatImageUrl: {
    loading: false,
    error: null,
    data: [],
  },
  downloadAmazonReport: {
    loading: false,
    error: null,
    data: [],
  },
};
