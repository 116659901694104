import React, { useEffect, useState, useContext, } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from "@material-ui/core/Grid";
import { Card, CardContent } from '@material-ui/core';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddAudit from '../addAudit/AddAudit';
import axios from 'axios';
import { GlobalContext } from "context/GlobalState";
// import { getAllQcAccessActive, getAllQcAccessAll, getAllQcAccessInactive, updateQcAccess } from '../../utils';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styles from "./AuditorTable.module.css"
import LinearProgress from '@mui/material/LinearProgress';
import SearchBar from "material-ui-search-bar";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TablePagination from '@material-ui/core/TablePagination';
import EditAudit from '../editAudit/EditAudit';
import { getAudits } from 'context/actions/auditAction/getAudits';
import { updateAudit } from 'context/actions/auditAction/updateAudit';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const StyledTableCell = withStyles((theme) => ({
    head: {
        border: 'none',
        color: '#888888',
        background: 'rgba(136, 136, 136, 0.55)'
    },
    body: {
        fontSize: 14,
        //border: 'none'
    },
}))(TableCell);

const columns = [
    {
        id: 'name',
        label: 'Name',
        minWidth: 80,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'number',
        label: 'Number',
        minWidth: 80,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'email',
        label: 'Email',
        minWidth: 100,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'user-type',
        label: 'User Type',
        minWidth: 70,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'Qc-type',
        label: 'QC Type',
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    // {
    //     id: 'red',
    //     label: 'Red',
    //     minWidth: 150,
    //     align: 'center',
    //     format: (value) => value.toLocaleString('en-US'),
    // },
    {
        id: 'status',
        label: 'Status',
        minWidth: 80,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: 30,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },
];

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};





const AuditorTable = () => {
    const [openEdit, setOpenEdit] = useState(false)
    const [openModal, setOpenModal] = React.useState(false);
    const [noData, setNoData] = useState(false);
    const [noActiveData, setNoActiveData] = useState(false);
    const [noInactiveData, setNoInactiveData] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false)
        if (value == 0) {
            getAudits("all")(auditDispatch)

        }
        else if (value == 1) {
            getAudits("active")(auditDispatch)

        }
        else if (value == 2) {
            getAudits("inactive")(auditDispatch)
        }
    };

    const [tableDataActive, setTableDataActive] = useState([])
    const [qcAssociateID, setQCAssociateID] = useState('')
    const [tableDataAll, setTableDataAll] = useState([])
    const [tableDataInactive, setTableDataInactive] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 0) {
            getAudits("all")(auditDispatch)

        }
        else if (newValue == 1) {
            getAudits("active")(auditDispatch)

        }
        else if (newValue == 2) {
            getAudits("inactive")(auditDispatch)
        }
    };
    const open = Boolean(anchorEl);

    const handleClick = (event, value) => {
        setAnchorEl(event.currentTarget);
        setQCAssociateID(value);
    };

    // console.log('qcAssociateID', qcAssociateID)

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [rowsDataActive, setRowsDataActive] = useState(tableDataActive);
    const [rowsDataAll, setRowsDataAll] = useState([]);
    const [rowsDataInactive, setRowsDataInactive] = useState(tableDataInactive);
    const { auditState, auditDispatch } = useContext(GlobalContext);
    const [searchedActive, setSearchedActive] = useState("");
    const [searchedAll, setSearchedAll] = useState("");
    const [searchedInactive, setSearchedInactive] = useState("");



    const requestSearchAll = (searchedVal) => {
        
        // console.log('tableDataAll',tableDataAll)
        const filteredRows = tableDataAll.filter((row) => {
            return row.full_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.number.toString()
                .toLowerCase()
                .includes(searchedVal.toLowerCase()) || row.email_id.toLowerCase().includes(searchedVal.toLowerCase()) || row.user_type.toLowerCase().includes(searchedVal.toLowerCase()) || row.qc_type.toLowerCase().includes(searchedVal.toLowerCase());
        });
        // console.log('filteredRows',filteredRows)
        setRowsDataAll(filteredRows);
        setNoData(true)
    };


    const cancelSearchAll = () => {
        setSearchedAll("");
        requestSearchAll(searchedAll);
    };


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getAudits("all")(auditDispatch)

    }, [])

    useEffect(() => {
        // console.log('auditState', auditState.getAudits)
        let status = auditState.getAudits && auditState.getAudits.data && auditState.getAudits.status
        if (status == true) {
            setTableDataAll(auditState.getAudits.data)
            setRowsDataAll(auditState.getAudits.data)
            if (auditState.getAudits.data.length === 0) {
                setNoData(true);
            } else {
                setNoData(false);
            }
            auditState.getAudits = {
                loading: false,
                error: null,
                data: null,
            }
        }

        // console.log("auditState.getAudits", auditState.getAudits)
        if (status === 401) {
            // setLoader(false)

            auditState.getAudits = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [auditState])

    useEffect(() => {
        // console.log('auditState', auditState.updateAudit)
        let status = auditState.updateAudit && auditState.updateAudit.data && auditState.updateAudit.status
        if (status == true) {
            if (value == 0) {
                getAudits("all")(auditDispatch)
    
            }
            else if (value == 1) {
                getAudits("active")(auditDispatch)
    
            }
            else if (value == 2) {
                getAudits("inactive")(auditDispatch)
            }
            auditState.updateAudit = {
                loading: false,
                error: null,
                data: null,
            }
        }

        // console.log("auditState.updateAudit", auditState.updateAudit)
        if (status === 401) {
            // setLoader(false)

            auditState.updateAudit = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [auditState])

    const handleMoveActive = (e) => {
        setAnchorEl(null);
            let postData = {
                id: qcAssociateID,
                 status: e,
            }
            updateAudit(postData)(auditDispatch)
    }

    // console.log('openEdit', openEdit)
    const handleOpenEdit = () => {
        setAnchorEl(null);
        setOpenEdit(true)
    }
    const handleCloseEdit = () => {
        setOpenEdit(false)
        if (value == 0) {
            getAudits("all")(auditDispatch)

        }
        else if (value == 1) {
            getAudits("active")(auditDispatch)

        }
        else if (value == 2) {
            getAudits("inactive")(auditDispatch)
        }
    }
    return (
        <div >
            <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <SearchBar style={{ float: 'right' }}
                            value={searchedAll}
                            onChange={(searchVal) => requestSearchAll(searchVal)}
                            onCancelSearch={() => cancelSearchAll()}
                            style={{ border: "0.5px solid #b2b2b2" }}
                        /> &nbsp; &nbsp; &nbsp;
                <Button onClick={handleOpenModal} variant='contained' style={{ display: 'flex', justifyContent: 'flex-end', background: '#121E2E' }}
                >Add Auditor</Button>
            </div><br />
            <AppBar position="static" elevation={0} style={{ background: '#f0eeee', color: 'black' }}>

                <Tabs elevation={0} inkBarStyle={{ background: 'red' }} value={value} onChange={handleChange} aria-label="simple tabs example" variant='outlined' TabIndicatorProps={{ style: { background: '#121E2E' } }}>
                    <Tab label="All" {...a11yProps(0)} />
                    <Tab label="Active" {...a11yProps(1)} />
                    <Tab label="Inactive" {...a11yProps(2)} />
                    {/* <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "10px" }} > */}
                        
                    {/* </div> */}
                </Tabs>
               
            </AppBar>
            <TabPanel value={value} index={0}>
                <div>

                    <Grid item xs={12} style={{ width: '100%', overflowX: 'auto', display: 'inline-grid' }}>
                        <TableContainer sx={{ maxHeight: 550 }}>
                            <Table stickyHeader aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <StyledTableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, background: 'rgba(136, 136, 136, 0.55)', color: '#000', fontWeight: 700 }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rowsDataAll.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rowsDataAll
                                    ).map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                                                <StyledTableCell
                                                    align="left"
                                                >
                                                    {row.full_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.number}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.email_id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.user_type == "" ? "normal" : row.user_type}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.qc_type}
                                                </StyledTableCell>


                                                <StyledTableCell align="center" style={{ display: "flex", justifyContent: "center" }}  >
                                                    <span className={row.status == "active" ? styles.activeStatus : styles.inactiveStatus}  >
                                                        {row.status}
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <IconButton style={{ padding: '0px' }} onClick={(e) => handleClick(e, row.id)}>
                                                        <MoreVertIcon size="small" style={{ fontSize: '20px' }} />
                                                    </IconButton>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        elevation={1}
                                                        onClose={handleClose}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                    >
                                                        <MenuItem onClick={handleOpenEdit}>Edit Associate</MenuItem>
                                                    </Menu>
                                                </StyledTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                            </Table>
                            {noData === false &&
                                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}

                                        count={rowsDataAll.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        style={{ borderBottom: "none" }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </div>
                            }


                        </TableContainer>
                        {(rowsDataAll.length === 0 && noData === false) ?
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                            :
                            rowsDataAll.length == 0 && <div style={{ textAlign: 'center', marginTop: 10 }}><h4><b>No Data Found</b></h4></div>}


                    </Grid>
                </div>

            </TabPanel>
            <TabPanel value={value} index={1}>

                <div>

                    <Grid item xs={12} style={{ width: '100%', overflowX: 'auto', display: 'inline-grid' }}>
                        <TableContainer sx={{ maxHeight: 550 }}>
                            <Table stickyHeader aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <StyledTableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, background: 'rgba(136, 136, 136, 0.55)', color: '#000', fontWeight: 700 }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rowsDataAll.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rowsDataAll
                                    ).map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                                                <StyledTableCell
                                                    align="left"
                                                >
                                                    {row.full_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.number}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.email_id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.user_type == "" ? "normal" : row.user_type}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {row.qc_type}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" style={{ display: "flex", justifyContent: "center" }}  >
                                                    <span className={row.status == "active" ? styles.activeStatus : styles.inactiveStatus}  >
                                                        {row.status}
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <IconButton style={{ padding: '0px' }} onClick={(e) => handleClick(e, row.id)}>
                                                        <MoreVertIcon size="small" style={{ fontSize: '20px' }} />
                                                    </IconButton>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        elevation={1}
                                                        onClose={handleClose}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => handleMoveActive('inactive')}>Move to In-Active</MenuItem>
                                                        <MenuItem onClick={handleOpenEdit}>Edit Associate</MenuItem>
                                                    </Menu>
                                                </StyledTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {noData === false &&
                                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}

                                        count={rowsDataAll.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        style={{ borderBottom: "none" }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </div>
                            }


                        </TableContainer>
                        {(rowsDataAll.length === 0 && noData === false) ?
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                            :
                            rowsDataAll.length == 0 && <div style={{ textAlign: 'center', marginTop: 10 }}><h4><b>No Data Found</b></h4></div>}
                    </Grid>
                </div>

            </TabPanel>
            <TabPanel value={value} index={2}>
                <div>

                    <Grid item xs={12} style={{ width: '100%', overflowX: 'auto', display: 'inline-grid' }}>
                        <TableContainer sx={{ maxHeight: 550 }}>
                            <Table stickyHeader aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <StyledTableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth, background: 'rgba(136, 136, 136, 0.55)', color: '#000', fontWeight: 700 }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rowsDataAll.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rowsDataAll
                                    ).map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                                                <StyledTableCell
                                                    align="left"
                                                >
                                                    {row.full_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.number}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.email_id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.user_type == "" ? "normal" : row.user_type}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.qc_type}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" style={{ display: "flex", justifyContent: "center" }}  >
                                                    <span className={row.status == "active" ? styles.activeStatus : styles.inactiveStatus}  >
                                                        {row.status}
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <IconButton style={{ padding: '0px' }} onClick={(e) => handleClick(e, row.id)}>
                                                        <MoreVertIcon size="small" style={{ fontSize: '20px' }} />
                                                    </IconButton>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => handleMoveActive('active')}>Move to Active</MenuItem>
                                                        <MenuItem onClick={handleOpenEdit}>Edit Associate</MenuItem>
                                                    </Menu>
                                                </StyledTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {noData === false &&
                                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}

                                        count={rowsDataAll.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true,
                                        }}
                                        style={{ borderBottom: "none" }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </div>
                            }


                        </TableContainer>
                        {(rowsDataAll.length === 0 && noData === false) ?
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                            :
                            rowsDataAll.length == 0 && <div style={{ textAlign: 'center', marginTop: 10 }}><h4><b>No Data Found</b></h4></div>}
                    </Grid>
                </div>

            </TabPanel>

            <AddAudit
                openModal={openModal}
                handleCloseModal={handleCloseModal}
            />

            <EditAudit
                openModal={openEdit}
                handleCloseModal={handleCloseEdit}
                qcAssociateID={qcAssociateID}
            />
        </div>
    )
}

export default AuditorTable