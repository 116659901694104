export const styles = (theme) => ({
    pleadStatus: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        alignItems: "center",
        fontSize: "12px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }
    },
    leadDetails: {
        width: "70%",
        margin: "auto",
        // marginTop: "50px",
        paddingBottom: "20px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: "80%"
        }
    },
    viewSimilarImages: {
        display: "flex",
        justifyContent: "flex-end",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }
    },
    brandingMaterialImages: {
        marginTop: "5px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            gap: "15px"
        }
    },
    leadStatus: {
        display: "flex",
        gap: "5px",
        justifyContent: "center",
        alignItems: "center"
    },
    leadId: {
        fontSize: "15px",
        fontWeight: 600,
        background: "#c1acf8",
        border: "1px solid #855bf5",
        padding: "5px",
        borderRadius: "5px"
    },
    leadCurrentStatus: {
        background: "rgba(255,208,25,.349)",
        color: "#958b00",
        padding: "5px",
        borderRadius: "5px"
    },
    timer: {
        marginLeft: "5px",
        color: "#673695",
        fontSize: "16px",
        fontWeight: 600
    },
    merchantDetails: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5"
    },
    merchantName: {
        fontSize: "11px",
        color: "gray",
        marginBottom: "5px"
    },
    viewSimilarImagesButton: {
        background: "#855bf5",
        color: "white",
        padding: "10px",
        border: "none",
        borderRadius: "5px",
        fontSize: "16px",
        cursor: "pointer"
    },
    merchantNote: {
        display: "flex",
        gap: "5px",
        fontSize: "11px",
        marginTop: "20px"
    },
    shopDetails: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5"
    },
    shopDetailsModal: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5",
        width: "390px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: "100%"
        }
    },
    imageModaltext: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5",
        width: "390px",
        marginBottom: "15px",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: "100%"
        }
    },
    storeFront: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5"
    },
    storeFrontText: {
        fontSize: "11px",
        color: "gray"
    },
    storeFrontSticker: {
        marginTop: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    upi: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5"
    },
    upiId: {
        fontSize: "11px",
        color: "gray",
        marginBottom: "5px"
    },
    branding: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5"
    },
    brandingText: {
        fontSize: "11px",
        color: "gray"
    },
    firstBranding: {
        fontSize: "11px",
        marginBottom: "5px",
        color: "#855bf5"
    },
    qr: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5"
    },
    qrText: {
        fontSize: "11px",
        color: "gray"
    },
    qrImage: {
        marginTop: "5px",
        display: "flex",
        justifyContent: "center", alignItems: "center"
    },
    required: {
        marginTop: "20px",
        background: "#855bf533",
        border: "1px solid #855bf5"
    },
    isRequiredText: {
        fontSize: "11px",
        color: "gray",
        marginBottom: "5px",
    },
    LeadRemarks: {
        marginTop: "20px",
        border: "1px solid #855bf5"
    },
    leadRemarksSelect: {
        width: "50%",
        height: "40px",
        borderRadius: "5px"
    },
    psubmit: {
        // width: "50%",
        marginTop: "10px",
        display: "flex",
        justifyContent: "center"
    },
    submitButton: {

        background: "#855bf5",
        border: "1px solid #855bf5",
        color: "white",
        fontSize: "16px"
    },
    imageContainer: {
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: "20px",
        }

    },
    rotateImage: {
        height: "400px",
        width: "400px",
        borderRadius: "5px",
        cursor: "pointer",
        transform: "rotate(0deg)",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            height: "160px",
            width: "100%",
        }
    },
    arrows: {
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            justifyContent: "center",
            flexDirection: "column"
        }
    },
    confirmBtn: {
        display: "flex",
        justifyContent: "flex-end",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            justifyContent: "center",
            marginTop: "20px"
        }
    },
    shopNameTextField: {
        width: "50%",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: "100%"
        }
    }
})