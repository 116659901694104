import { OTP_USER, OTP_USER_ERR } from '../../actionTypes/userType'
import { otpUserApi } from 'api/Api';
import axios from "axios";
export const otpUser = (payload) => async (dispatch) => {
    try {
        
        let results = await axios.post(`${otpUserApi}`, payload)
        let data = results && results.data
        dispatch({
            type: OTP_USER,
            payload: data,
        });
    } catch (error) {
       
        dispatch({
            type: OTP_USER_ERR,
            payload: error.response,
        });
    }
};


