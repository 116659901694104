import { getAgenciesApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
import { GET_AGENCIES, GET_AGENCIES_ERR } from '../../actionTypes/agencyType';

export const getAgencies = payload => async dispatch => {
    try {
        //console.log('payload',payload)
        let results = await axios.get(`${getAgenciesApi}/${payload.page}`, await accessToken())
        let data = results && results.data
        dispatch({
            type: GET_AGENCIES,
            payload: data || [],
        });
    } catch (error) {

        dispatch({
            type: GET_AGENCIES_ERR,
            payload: error && error.response
        });
    }
}
