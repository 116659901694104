import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./DashboardTableStyle";
import Dashboard from "components/viewer/dashboard";

const DashboardTable = React.memo((props) => {
  return (
    <>
      <Dashboard />

    </>
  );
});

export default withRouter(DashboardTable);
