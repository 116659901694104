export const LOGIN_FORM = 'LOGIN_FORM';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_ERR = 'LOGIN_USER_ERR';

export const OTP_USER = 'OTP_USER';
export const OTP_USER_ERR = 'OTP_USER_ERR';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_ERR = 'GET_USERS_ERR';
export const GET_USER = 'GET_USER';
export const GET_USER_ERR = 'GET_USER_ERR';
export const UPDATE_PAN_STATUS = 'UPDATE_PAN_STATUS';
export const UPDATE_PAN_STATUS_ERR = 'UPDATE_PAN_STATUS_ERR';
export const UPDATE_ADHAR_STATUS = 'UPDATE_ADHAR_STATUS';
export const UPDATE_ADHAR_STATUS_ERR = 'UPDATE_ADHAR_STATUS_ERR';
export const CREATE_IMAGE_URL = 'CREATE_IMAGE_URL';
export const CREATE_IMAGE_URL_ERR = 'CREATE_IMAGE_URL_ERR';
export const CHECK_USER_SESSION = 'CHECK_USER_SESSION';
export const CHECK_USER_SESSION_ERR = 'CHECK_USER_SESSION_ERR';
export const UPDATE_ATTENDANCE_CHECK= 'UPDATE_ATTENDANCE_CHECK';
export const UPDATE_ATTENDANCE_CHECK_ERR = 'UPDATE_ATTENDANCE_CHECK_ERR';
export const GET_PORTAL_USER_LOG= 'GET_PORTAL_USER_LOG';
export const GET_PORTAL_USER_LOG_ERR = 'GET_PORTAL_USER_LOG_ERR';
export const CREATE_PROFILE_IMAGE_URL = 'CREATE_PROFILE_IMAGE_URL';
export const CREATE_PROFILE_IMAGE_URL_ERR = 'CREATE_PROFILE_IMAGE_URL_ERR';
export const UPLOAD_CHAT_IMAGE_URL = 'UPLOAD_CHAT_IMAGE_URL';
export const UPLOAD_CHAT_IMAGE_URL_ERR = 'UPLOAD_CHAT_IMAGE_URL_ERR';

export const DOWNLOAD_AMAZON_REPORT = 'DOWNLOAD_AMAZON_REPORT';
export const DOWNLOAD_AMAZON_REPORT_ERR = 'DOWNLOAD_AMAZON_REPORT_ERR';
