import { STATE_RESET, STATE_RESET_ERR } from "context/actionTypes/stateResetType";

export const vendorStatsReset = payload => async dispatch => {
    try {
        dispatch({
            type: STATE_RESET,
            payload: payload,
        });
    } catch (error) {
        dispatch({
            type: STATE_RESET_ERR,
            payload: error.response
        });
    }
}
