import {  GET_QC_LEAD_DETAIL, GET_QC_LEAD_DETAIL_ERR, UPDATE_DUPLICATE_QC_SUBMIT, UPDATE_DUPLICATE_QC_SUBMIT_ERR, UPDATE_LEAD, UPDATE_LEAD_ERR, UPDATE_LEAD_REASON_SUBMIT, UPDATE_LEAD_REASON_SUBMIT_ERR, UPDATE_QC_ALL_SUBMIT, UPDATE_QC_ALL_SUBMIT_ERR, UPDATE_QC_ALL_SUBMIT_REMARK ,UPDATE_QC_ALL_SUBMIT_REMARK_ERR} from '../../actionTypes/qcType';

export default (state, action) => {
  switch (action.type) {


    case UPDATE_QC_ALL_SUBMIT:
      return {
        ...state,
        updateQcAllSubmit: action.payload, ...state.data,
      }

    case UPDATE_QC_ALL_SUBMIT_ERR:
      return {
        ...state,
        updateQcAllSubmit:  action.payload 
      }

      case UPDATE_DUPLICATE_QC_SUBMIT:
        return {
          ...state,
          updateDuplicateQcSubmit: action.payload, ...state.data,
        }
  
      case UPDATE_DUPLICATE_QC_SUBMIT_ERR:
        return {
          ...state,
          updateDuplicateQcSubmit:  action.payload 
        }

      case UPDATE_QC_ALL_SUBMIT_REMARK:
        return {
          ...state,
          updateQcAllSubmitRemark: action.payload, ...state.data,
        }
  
      case UPDATE_QC_ALL_SUBMIT_REMARK_ERR:
        return {
          ...state,
          updateQcAllSubmitRemark:  action.payload 
        }
  
      case GET_QC_LEAD_DETAIL:
        return {
          ...state,
          getQcLeadDetail: action.payload, ...state.data,
        }
  
      case GET_QC_LEAD_DETAIL_ERR:
        return {
          ...state,
          getQcLeadDetail:  action.payload 
        }

        case UPDATE_LEAD:
          return {
            ...state,
            updateLead: action.payload, ...state.data,
          }
    
        case UPDATE_LEAD_ERR:
          return {
            ...state,
            updateLead:  action.payload 
          }

          case UPDATE_LEAD_REASON_SUBMIT:
            return {
              ...state,
              updateLeadReasonSubmit: action.payload, ...state.data,
            }
      
          case UPDATE_LEAD_REASON_SUBMIT_ERR:
            return {
              ...state,
              updateLeadReasonSubmit:  action.payload 
            }
    
  

    default:
      return state;
  }
}
