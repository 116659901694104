import { GET_REASON_LIST, GET_REASON_LIST_ERR } from '../../actionTypes/leadType'
import { getReasonListApi} from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getReasonList = payload => async dispatch => {
    try {
        ////console.log('payload',payload)
        let results = await axios.get(`${getReasonListApi}`, await accessToken())
        ////console.log("User Detail", results)
        let data = results && results.data
        dispatch({
            type: GET_REASON_LIST,
            payload: data || {},
        });
    } catch (error) {   
        dispatch({
            type: GET_REASON_LIST_ERR,
            payload: error && error.response
        });
    }
}


