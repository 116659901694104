export default {
  createAudit: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },

  getAudits: {
    loading: false,
    error: null,
    data: [],
    status: false,
    message: '',
  },

  getAudit: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },

  updateAudit: {
    loading: false,
    error: null,
    data: {},
    status: false,
    message: '',
  },
};
