import { UPDATE_AUDIT, UPDATE_AUDIT_ERR } from 'context/actionTypes/auditType';
import { updateAuditApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const updateAudit = (payload) => async (dispatch) => {
    try {
        //console.log(payload)
        let results = await axios.patch(`${updateAuditApi}`, payload, await accessToken())
        //console.log(results)
        let data = results && results.data
        //console.log('UPDATE STEP 1 JOB', data)
        dispatch({
            type: UPDATE_AUDIT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_AUDIT_ERR,
            payload: error.response,
        });
    }
};