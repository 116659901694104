import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./AgencyDashboardTableStyle";
import AgencyDashboard from "components/agency/agencyDashboard";

const DashboardTable = React.memo((props) => {
  return (
    <>
      <AgencyDashboard />

    </>
  );
});

export default withRouter(DashboardTable);
