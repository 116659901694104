import { LOGIN_FORM } from '../../actionTypes/userType'
export const logOut = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: LOGIN_FORM,
            payload: payload,
        });
    } catch (error) {
        
        dispatch({
            type: '',
            payload: error.response,
        });
    }
};


