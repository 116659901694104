import { LOGIN_USER, LOGIN_USER_ERR } from '../../actionTypes/userType'
import { loginUserApi } from 'api/Api';
import axios from "axios";
export const loginUser = (payload) => async (dispatch) => {
    try {
        
        let results = await axios.post(`${loginUserApi}`, payload)
        let data = results && results.data
        dispatch({
            type: LOGIN_USER,
            payload: data,
        });
    } catch (error) {
       
        dispatch({
            type: LOGIN_USER_ERR,
            payload: error.response,
        });
    }
};


