import { UPDATE_LEAD_REASON_SUBMIT, UPDATE_LEAD_REASON_SUBMIT_ERR } from 'context/actionTypes/qcType';
import { updateLeadReasonSubmitApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const updateLeadReasonSubmit = (payload) => async (dispatch) => {
    try {
        //console.log(payload)
        let results = await axios.patch(`${updateLeadReasonSubmitApi}`, payload, await accessToken())
        //console.log(results)
        let data = results && results.data
        //console.log('UPDATE STEP 1 JOB', data)
        dispatch({
            type: UPDATE_LEAD_REASON_SUBMIT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_LEAD_REASON_SUBMIT_ERR,
            payload: error.response,
        });
    }
};