import { LOGIN_USER, LOGIN_USER_ERR, LOGIN_FORM, GET_USERS, GET_USERS_ERR, GET_USER, GET_USER_ERR, UPDATE_ADHAR_STATUS, UPDATE_ADHAR_STATUS_ERR, UPDATE_PAN_STATUS, UPDATE_PAN_STATUS_ERR, CREATE_IMAGE_URL, CREATE_IMAGE_URL_ERR, CHECK_USER_SESSION, CHECK_USER_SESSION_ERR, UPDATE_ATTENDANCE_CHECK, UPDATE_ATTENDANCE_CHECK_ERR, GET_PORTAL_USER_LOG, GET_PORTAL_USER_LOG_ERR, CREATE_PROFILE_IMAGE_URL, CREATE_PROFILE_IMAGE_URL_ERR, UPLOAD_CHAT_IMAGE_URL, UPLOAD_CHAT_IMAGE_URL_ERR, OTP_USER, OTP_USER_ERR, DOWNLOAD_AMAZON_REPORT, DOWNLOAD_AMAZON_REPORT_ERR } from '../../actionTypes/userType'
// export default (state, action) => {
export default (state, { type, payload }) => {
  switch (type) {

    case LOGIN_FORM: {
      return {
        ...state,
        ...payload,
      };
    }


    case LOGIN_USER:
      return {
        ...state,
        loginUser: { data: payload }
      }

    case LOGIN_USER_ERR:
      return {
        ...state,
        loginUser: { data: payload }
      }

    case OTP_USER:
      return {
        ...state,
        otpUser: { data: payload }
      }

    case OTP_USER_ERR:
      return {
        ...state,
        otpUser: { data: payload }
      }



    case GET_USERS:
      return {
        ...state,
        users: { data: payload },
      }
    case GET_USERS_ERR:
      return {
        ...state,
        users: { data: payload }
      }

    case UPDATE_ADHAR_STATUS:
      return {
        ...state,
        user_aadhar: { data: payload },
      }
    case UPDATE_ADHAR_STATUS_ERR:
      return {
        ...state,
        user_aadhar: { data: payload }
      }

    case GET_USER:
      return {
        ...state,
        user: { data: payload },
      }
    case GET_USER_ERR:
      return {
        ...state,
        user: { data: payload }
      }

    case UPDATE_PAN_STATUS:
      return {
        ...state,
        user_pan: { data: payload },
      }
    case UPDATE_PAN_STATUS_ERR:
      return {
        ...state,
        user_pan: { data: payload }
      }
    case CREATE_IMAGE_URL:
      return {
        ...state,
        createImageUrl: { data: payload },
      }
    case CREATE_IMAGE_URL_ERR:
      return {
        ...state,
        createImageUrl: { data: payload }
      }
    case UPLOAD_CHAT_IMAGE_URL:
      return {
        ...state,
        uploadChatImageUrl: { data: payload }
      }
    case UPLOAD_CHAT_IMAGE_URL_ERR:
      return {
        ...state,
        uploadChatImageUrl: { data: payload },
      }
    case CREATE_PROFILE_IMAGE_URL:
      return {
        ...state,
        createProfileImageUrl: { data: payload },
      }


    case CREATE_PROFILE_IMAGE_URL_ERR:
      return {
        ...state,
        createProfileImageUrl: { data: payload }
      }
    case CHECK_USER_SESSION:
      return {
        ...state,
        getSessionStatus: { data: payload },
      }
    case CHECK_USER_SESSION_ERR:
      return {
        ...state,
        getSessionStatus: { data: payload }
      }
    case UPDATE_ATTENDANCE_CHECK:
      return {
        ...state,
        updateAttendanceCheck: { data: payload },
      }

    case UPDATE_ATTENDANCE_CHECK_ERR:
      return {
        ...state,
        updateAttendanceCheck: { data: payload },
      }
    case GET_PORTAL_USER_LOG:
      return {
        ...state,
        getPortalUserLog: { data: payload },
      }

    case GET_PORTAL_USER_LOG_ERR:
      return {
        ...state,
        getPortalUserLog: { data: payload },
      }


    case DOWNLOAD_AMAZON_REPORT:
      return {
        ...state,
        downloadAmazonReport: { data: payload },
      }

    case DOWNLOAD_AMAZON_REPORT_ERR:
      return {
        ...state,
        downloadAmazonReport: { data: payload },
      }


    default:
      return state;
  }
}