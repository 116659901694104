import React, { useState } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styles } from "./LeadDetailsStyle";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(styles);

const LeadDetailsImagesModal = ({
    openModal,
    handleCloseModal,
    storeFrontImage,
    competativeQrImage,
    firstBranding,
    secondBranding,
    amazonQrImage
}) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "0.5px solid #000",
        boxShadow: 24,
        p: 4,
    };
    const classes = useStyles();
    const [rotates, setRotates] = useState(0)
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');

    const closeModal = () => {
        handleCloseModal();
        setRotates(0)
    }
    const handleTransform = () => {
        if (rotates === 360) {
            setRotates(90)
        } else {
            setRotates(rotates + 90)
        }
    }

    return (
        <div>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Grid container justifyContent="flex-end"><CancelOutlinedIcon onClick={closeModal} style={{ fontSize: "40px", color: "#855bf5", cursor: "pointer" }} /></Grid>


                    {storeFrontImage ?
                        (
                            <Grid container justifyContent="space-around" className={classes.imageContainer} >
                                <Grid item >
                                    <div>
                                        <img src="https://taskmotech1b.s3.amazonaws.com/c3fb1122-40f5-4711-9689-70a6b84296bd.jpeg" alt="" className={
                                            classes.rotateImage} style={{ transform: `rotate(${rotates}deg)` }} />
                                    </div>
                                    <div className={classes.arrows} >
                                        <div style={{ fontWeight: "600" }} >
                                            Click on icon to rotate image
                                        </div>
                                        <RefreshOutlinedIcon onClick={handleTransform} style={{ fontSize: "40px", color: "#855bf5", cursor: "pointer" }} />
                                    </div>
                                </Grid>

                                <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                    <div>
                                        <Card variant="outlined" className={classes.imageModaltext} >
                                            <CardContent>
                                                <div>STORE FRONT IMAGE WITH STICKER</div>
                                            </CardContent>
                                        </Card>

                                        <Card variant="outlined" className={classes.shopDetailsModal}  >
                                            <CardContent>
                                                <div className={classes.merchantName} >Shop Name</div>
                                                <div>Hari om clinic</div>
                                            </CardContent>
                                        </Card>
                                    </div>

                                    <div className={classes.confirmBtn} >
                                        <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                    </div>
                                </Grid>

                            </Grid>
                        ) : competativeQrImage ? (
                            <Grid container justifyContent="space-around" className={classes.imageContainer} >
                                <Grid item>
                                    <div>
                                        <img src="https://taskmotech1b.s3.amazonaws.com/834339fa-e9fb-4f95-996d-cd059e92b61e.jpeg" alt="" className={
                                            classes.rotateImage} style={{ transform: `rotate(${rotates}deg)` }} />
                                    </div>
                                    <div className={classes.arrows} >
                                        <div style={{ fontWeight: "600" }} >
                                            Click on icon to rotate image
                                        </div>
                                        <RefreshOutlinedIcon onClick={handleTransform} style={{ fontSize: "40px", color: "#855bf5", cursor: "pointer" }} />
                                    </div>
                                </Grid>
                                <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                    <Card variant="outlined" className={classes.imageModaltext} >
                                        <CardContent>
                                            <div>COMPETATIVE QR CODE IMAGE</div>
                                        </CardContent>
                                    </Card>
                                    <div className={classes.confirmBtn} >
                                        <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        ) : firstBranding ? (
                            <Grid container justifyContent="space-around" className={classes.imageContainer} >
                                <Grid item>
                                    <div>
                                        <img src="https://taskmotech1b.s3.amazonaws.com/88310789-0419-40f4-9c7b-77aef4656db7.jpeg" alt="" className={
                                            classes.rotateImage} style={{ transform: `rotate(${rotates}deg)` }} />
                                    </div>
                                    <div className={classes.arrows} >
                                        <div style={{ fontWeight: "600" }} >
                                            Click on icon to rotate image
                                        </div>
                                        <RefreshOutlinedIcon onClick={handleTransform} style={{ fontSize: "40px", color: "#855bf5", cursor: "pointer" }} />
                                    </div>
                                </Grid>
                                <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                    <Card variant="outlined" className={classes.imageModaltext} >
                                        <CardContent>
                                            <div>FIRST BRANDING MATERIAL IMAGE</div>
                                        </CardContent>
                                    </Card>

                                    <div className={classes.confirmBtn} >
                                        <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        ) : secondBranding ? (
                            <Grid container justifyContent="space-around" className={classes.imageContainer} >
                                <Grid item>
                                    <div>
                                        <img src="https://taskmotech1b.s3.amazonaws.com/88310789-0419-40f4-9c7b-77aef4656db7.jpeg" alt="" className={
                                            classes.rotateImage} style={{ transform: `rotate(${rotates}deg)` }} />
                                    </div>
                                    <div className={classes.arrows} >
                                        <div style={{ fontWeight: "600" }} >
                                            Click on icon to rotate image
                                        </div>
                                        <RefreshOutlinedIcon onClick={handleTransform} style={{ fontSize: "40px", color: "#855bf5", cursor: "pointer" }} />
                                    </div>
                                </Grid>
                                <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >

                                    <Card variant="outlined" className={classes.imageModaltext} >
                                        <CardContent>
                                            <div>SECOND BRANDING MATERIAL IMAGE</div>
                                        </CardContent>
                                    </Card>

                                    <div className={classes.confirmBtn} >
                                        <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        ) : amazonQrImage ? (
                            <Grid container justifyContent="space-around" className={classes.imageContainer} >
                                <Grid item>
                                    <div>
                                        <img src="https://amazonqc.taskmo.com/images/image-unavailable-icon-1.jpeg" alt="" className={
                                            classes.rotateImage} style={{ transform: `rotate(${rotates}deg)` }} />
                                    </div>
                                    <div className={classes.arrows} >
                                        <div style={{ fontWeight: "600" }} >
                                            Click on icon to rotate image
                                        </div>
                                        <RefreshOutlinedIcon onClick={handleTransform} style={{ fontSize: "40px", color: "#855bf5", cursor: "pointer" }} />

                                    </div>
                                </Grid>
                                <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} >
                                    <div>
                                        <Card variant="outlined" className={classes.imageModaltext} >
                                            <CardContent>
                                                <div>AMAZON QR CODE IMAGE</div>
                                            </CardContent>
                                        </Card>
                                        <Card variant="outlined" className={classes.shopDetailsModal}  >
                                            <CardContent>
                                                <div className={classes.merchantName} >Upi id</div>
                                                <div style={{ wordBreak: "break-all" }} >paytmqr2810050501011o0dqatfqwzp@paytm</div>
                                            </CardContent>
                                        </Card>
                                    </div>

                                    <div className={classes.confirmBtn} >
                                        <Button style={{ color: "white", background: "#855bf5" }} >Confirm</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        ) : ("no Image")}

                </DialogContent>
            </Dialog>
        </div >
    );
};

export default LeadDetailsImagesModal;
