import {  GET_EXTERNAL_ASSIGNED_LEADS, GET_EXTERNAL_ASSIGNED_LEADS_ERR, GET_EXTERNAL_DASHBOARD_COUNT, GET_EXTERNAL_DASHBOARD_COUNT_ERR, GET_EXTERNAL_REQUEST_LEADS, GET_EXTERNAL_REQUEST_LEADS_ERR, UPDATE_EXTERNAL_QC_SUBMIT, UPDATE_EXTERNAL_QC_SUBMIT_ERR } from '../../actionTypes/externalDashboardType';

export default (state, action) => {
  switch (action.type) {


    case GET_EXTERNAL_ASSIGNED_LEADS:
      return {
        ...state,
        getExternalAssignedLeads: action.payload, ...state.data,
      }

    case GET_EXTERNAL_ASSIGNED_LEADS_ERR:
      return {
        ...state,
        getExternalAssignedLeads:  action.payload 
      }

      case GET_EXTERNAL_DASHBOARD_COUNT:
        return {
          ...state,
          getExternalDashboardCount: action.payload, ...state.data,
        }
  
      case GET_EXTERNAL_DASHBOARD_COUNT_ERR:
        return {
          ...state,
          getExternalDashboardCount:  action.payload 
        }

        case GET_EXTERNAL_REQUEST_LEADS:
          return {
            ...state,
            getExternalRequestLeads: action.payload, ...state.data,
          }
    
        case GET_EXTERNAL_REQUEST_LEADS_ERR:
          return {
            ...state,
            getExternalRequestLeads:  action.payload 
          }
  
      case UPDATE_EXTERNAL_QC_SUBMIT:
        return {
          ...state,
          updateExternalQcSubmit: action.payload, ...state.data,
        }
  
      case UPDATE_EXTERNAL_QC_SUBMIT_ERR:
        return {
          ...state,
          updateExternalQcSubmit:  action.payload 
        }
  

    default:
      return state;
  }
}
