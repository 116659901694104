import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  useHistory,
} from "react-router-dom";
import { localRoute } from "../../routes/localRoutes";
import Dashboard from "../../pages/root/dashboard";
import ExternalTable from "../../pages/root/externalTable";
import InternalTable from "../../pages/root/internalTable";
import AuditTable from "../../pages/root/audit/auditTable";
import LeadDetail from "pages/root/leadDetail";

import ExternalDashboard from "../../pages/external/externalDashboard";
import ExternalLeadDetail from "pages/external/leadDetail";

import InternalDashboard from "../../pages/internal/internalDashboard";
import InternalLeadDetail from "pages/internal/leadDetail";


import ViewerDashboard from "../../pages/viewer/dashboard";
import ViewerExternalTable from "../../pages/viewer/externalTable";
import ViewerInternalTable from "../../pages/viewer/internalTable";
import VendorReportPage from "../../pages/root/vendorReportPage";
/****************************ERROR PAGE ************************************ */
import NotFound from "../../pages/notFound";
import NotFound500 from "../../pages/notFound500";
import fseTable from "pages/root/fseTable";

import AgencyDashboard from "pages/agency/agencyDashboard/AgencyDashboard";
import AgencyLeadDetail from "pages/agency/leadDetail/LeadDetail";


function RouterComponent(props) {
  const history = useHistory();
  let [qcType, setQcType] = useState(localStorage.getItem('qc_type'))
  let [userType, setUserType] = useState(localStorage.getItem('user_type'))
  return (
    <React.Fragment>
      <Switch>
        {qcType == "external" && userType == "root" &&
          <Switch>
            <Route exact path={`${localRoute.dashboard}`} component={Dashboard} />
            <Route exact path={`${localRoute.qcAssociateTable}`} component={AuditTable} />
            <Route exact path={`${localRoute.externalDashboardTable}`} component={ExternalTable} />
            <Route exact path={`${localRoute.leadDetail}/:lead_id`} component={LeadDetail} />
            <Route exact path={`${localRoute.internalDashboardTable}`} component={InternalTable} />
            <Route exact path={`${localRoute.vendorReportTable}`} component={VendorReportPage} />
            <Route exact path={`${localRoute.fseTable}`} component={fseTable} />
          </Switch>}
        {qcType == "external" && userType == "" &&
          <Switch>
            <Route exact path={`${localRoute.externalDashboard}`} component={ExternalDashboard} />
            <Route exact path={`${localRoute.externalLeadDetail}`} component={ExternalLeadDetail} />
          </Switch>
        }

        {qcType == "internal" &&
          <Switch>
            <Route exact path={`${localRoute.internalDashboard}`} component={InternalDashboard} />
            <Route exact path={`${localRoute.internalLeadDetail}`} component={InternalLeadDetail} />

          </Switch>
        }

        {qcType == "agency" &&
          <Switch>
            <Route exact path={`${localRoute.agencyDashboard}`} component={AgencyDashboard} />
            <Route exact path={`${localRoute.agencyLeadDetail}`} component={AgencyLeadDetail} />

          </Switch>
        }
        {qcType == "viewer" &&
          <Switch>
            <Route exact path={`${localRoute.viewerDashboard}`} component={ViewerDashboard} />
            <Route exact path={`${localRoute.viewerInternalDashboardTable}`} component={ViewerInternalTable} />
            <Route exact path={`${localRoute.viewerExternalDashboardTable}`} component={ViewerExternalTable} />

          </Switch>
        }
        <Route
          exact
          path={`${localRoute.notFound}`}
          component={NotFound}
        />
        <Route
          exact
          path={`${localRoute.notFound500}`}
          component={NotFound500}
        />
      </Switch>
    </React.Fragment>
  );
}

export default withRouter(RouterComponent);
