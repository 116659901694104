import { GET_REASON_CODE_DATA, GET_REASON_CODE_DATA_ERR } from 'context/actionTypes/reasonCodeActionType';

export default (state, action) => {
    switch (action.type) {
        case GET_REASON_CODE_DATA:
            return {
                ...state,
                getReasonCodeDataInitialState: action.payload, ...state.data,
            }

        case GET_REASON_CODE_DATA_ERR:
            return {
                ...state,
                getReasonCodeDataInitialState: action.payload
            }
    }
}