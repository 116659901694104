import React,{useEffect} from "react";
import { withRouter } from "react-router-dom";
import { localRoute } from "../../routes/localRoutes";
import { Link } from "react-router-dom";
import "./unAutorizedStyle.jsx";
import noPages from "../../assets/images/no_page.svg"

const NotFound = React.memo((props) => {
  useEffect(()=>{
    document.title = `Un Autorized User`
  },[])
  return (
    <>
    
    <center style={{marginTop:"5%"}}>  
    <hr/>
       <img src={noPages} />
       <h1 style={{color:"#ccc"}}>Un Autorized for this Page</h1>
       <hr/>
     </center>
    </>
  );
});

export default withRouter(NotFound);
