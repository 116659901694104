import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import "./Analysis.css";
import LinearProgress from '@mui/material/LinearProgress';
import { GlobalContext } from "context/GlobalState";
import AnalysisTable from "./AnalysisTable/AnalysisTable";
import StoreAnalysisTable from "./StoreAnalysisTable/StoreAnalysisTable";
import MerchantAnalysisTable from "./MerchantAnalysisTable/MerchantAnalysisTable";
import UpiAnalysisTable from "./UpiAnalysisTable/UpiAnalysisTable";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  article: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "grid",
    placeItems: "center"
  },
  header: {
    fontSize: "80px",
    color: "white",
    textAlign: "center",
  }
}));

function Analysis() {
  const [analysis, setAnalysis] = useState({
    location_match_count: -1,
    shop_name_match_count: -1,
    merchant_name_match_count: -1,
    upi_id_match_count: -1,
    lead_info: [],
  });
  const [analysisType,setAnalysisType] = useState('');
  const { leadState, leadDispatch } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const classes = useStyles();
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  useEffect(() => {
    // console.log('leadState', leadState.getAnalysis)
    let status = leadState.getAnalysis && leadState.getAnalysis.data && leadState.getAnalysis.status
    if (status == true) {
      setLoader(false)
      console.log("leadState.getAnalysis", leadState.getAnalysis)
      setAnalysis(leadState.getAnalysis.data)
      leadState.getAnalysis = {
        loading: false,
        error: null,
        data: null,
      }
    }


    if (status === 401) {
      // setLoader(false)
      setLoader(false)
      leadState.getAnalysis = {
        loading: false,
        error: null,
        data: [],
      }
    }


  }, [leadState])


  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      {loader == true &&
        <LinearProgress />}
      <div
        className="analysis-container"
       
      >
        <div className="primary-text" style={{ marginBottom: 10 }}>Analysis</div>
        <div className="card-row"  onClick={() => {
          setAnalysisType('Location')
          setOpen(true);
        }}>
          {/* col 1 */}
          <div
            className={`base-card ${analysis.location_match_count === 0
                ? "card-green"
                : `${analysis.location_match_count < 0 ||
                  analysis.location_match_count > 4
                  ? "card-red"
                  : "card-orange"
                }`
              }`}
          >
            <div className="myRow">
              <div className="badge">{analysis.location_match_count}</div>
              <div className="fix100">Location</div>
              <div className="card-description">
                {analysis.location_match_count < 0 ? (
                  <>error</>
                ) : (
                  <>
                    {analysis.location_match_count === 0
                      ? "Unique"
                      : " Multiple leads from same location."}
                  </>
                )}
              </div>
            </div>
          </div>
        </div><br />
        <div className="card-row"  onClick={() => {
          setAnalysisType('Shop')
          setOpen(true);
        }}>
          {/* col 2 */}
          <div
            className={`base-card ${analysis.shop_name_match_count === 0
                ? "card-green"
                : `${analysis.shop_name_match_count < 0 ||
                  analysis.shop_name_match_count > 4
                  ? "card-red"
                  : "card-orange"
                }`
              }`}
          >
            <div className="myRow">
              <div className="badge">{analysis.shop_name_match_count}</div>
              <div className="fix100">Store Name</div>
              <div className="card-description">
                {analysis.shop_name_match_count < 0 ? (
                  <>error</>
                ) : (
                  <>
                    {analysis.shop_name_match_count === 0
                      ? "Unique"
                      : " Multiple leads with same store name."}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="card-row"  onClick={() => {
          setAnalysisType('Merchant Name')
          setOpen(true);
        }}>
          {/* col 2 */}
          <div
            className={`base-card ${analysis.merchant_name_match_count === 0
                ? "card-green"
                : `${analysis.merchant_name_match_count < 0 ||
                  analysis.merchant_name_match_count > 4
                  ? "card-red"
                  : "card-orange"
                }`
              }`}
          >
            <div className="myRow">
              <div className="badge">{analysis.merchant_name_match_count}</div>
              <div className="fix100">Merchant Name</div>
              <div className="card-description">
                {analysis.merchant_name_match_count < 0 ? (
                  <>error</>
                ) : (
                  <>
                    {analysis.merchant_name_match_count === 0
                      ? "Unique"
                      : " Multiple leads with same merchant name."}
                  </>
                )}
              </div>
            </div>
          </div>
        </div><br />
        <div className="card-row"  onClick={() => {
          setAnalysisType('UPI ID')
          setOpen(true);
        }}>
          {/* col 2 */}
          <div
            className={`base-card ${analysis.upi_id_match_count === 0
                ? "card-green"
                : `${analysis.upi_id_match_count < 0 ||
                  analysis.upi_id_match_count > 4
                  ? "card-red"
                  : "card-orange"
                }`
              }`}
          >
            <div className="myRow">
              <div className="badge">{analysis.upi_id_match_count}</div>
              <div className="fix100">UPI ID</div>
              <div className="card-description">
                {analysis.upi_id_match_count < 0 ? (
                  <>error</>
                ) : (
                  <>
                    {analysis.upi_id_match_count === 0
                      ? "Unique"
                      : " Multiple leads with same upi id."}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <>
          {/* <div className="card-row">
            <div className="base-card card-orange">
              <div className="myRow">
                <div className="badge">04</div>
                <div>Category match</div>
                <div className="card-description">
                  Same FSE with same category in same location
                </div>
              </div>
            </div>
          </div> */}
        </>
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      // TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
            {analysisType}  Analysis Log
            </Typography>
          </Toolbar>
        </AppBar>
        {analysisType=='Location' &&
        <AnalysisTable data={analysis && analysis} />
        }
        {analysisType=='Shop' &&
        <StoreAnalysisTable data={analysis} />
        }
        {analysisType=='Merchant Name' &&
        <MerchantAnalysisTable data={analysis} />
        }
        {analysisType=='UPI ID' &&
        <UpiAnalysisTable data={analysis && analysis} />
        }
      </Dialog>
    </>
  );
}

export default Analysis;
