export const styles = (theme) => ({

    request: {
        // marginTop: "50px",
        display: "flex",
        justifyContent: "center"
    },
    requestBtn: {
        backgroundColor: "#855bf5",
        borderRadius: "6px",
        color: "#fff",
        fontSize: "22px",
        padding: '10px',
        border: "none",
        cursor: "pointer"
    },
    pLeads: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "50px",
        marginTop: "30px",

        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            display: "flex",
            flexDirection: "column"
        }
    },

    totalApprovedCard: {
        background: "linear-gradient(to bottom, #739449 0%, #006600 100%)",
        width: "250px",
        height: "150px",
        border: "0.5px solid #add8e6",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    leadTableCard: {
        width: "60%",
        margin: "auto",
        marginTop: "50px",
        paddingBottom: "20px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            width: "90%",
        }
    },
    tableHeading: {
        display: "flex",
        justifyContent: "space-between",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            flexDirection: "column"
        }
    },
    headingText: {
        maxWidth: "max-content",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            margin: "auto"
        }
    }

})