import { GET_LEAD, GET_LEAD_ERR } from '../../actionTypes/leadType'
import { getLeadApi} from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getLead = payload => async dispatch => {
    try {
        ////console.log('payload',payload)
        let results = await axios.get(`${getLeadApi}/${payload}`, await accessToken())
        ////console.log("User Detail", results)
        let data = results && results.data
        dispatch({
            type: GET_LEAD,
            payload: data || {},
        });
    } catch (error) {   
        dispatch({
            type: GET_LEAD_ERR,
            payload: error && error.response
        });
    }
}


