import React, { useState, useContext, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { styles } from "./InternalDashboardStyle";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchBar from "material-ui-search-bar";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Swal from "sweetalert2";
import TablePagination from '@mui/material/TablePagination';
import { GlobalContext } from "context/GlobalState";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getInternalDashboardCount } from "context/actions/internalDashboardAction/getInternalDashboardCount";
import { getInternalAssignedLeads } from "context/actions/internalDashboardAction/getInternalAssignedLeads";
import { getInternalRequestLeads } from "context/actions/internalDashboardAction/getInternalRequestLeads";
import { localRoute } from "routes/localRoutes";


const useStyles = makeStyles(styles);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0.5px solid #000',
    boxShadow: 24,
    p: 4,
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: 'none',
        color: '#888888',
        background: '#F2F2F2'
    },
    body: {
        fontSize: 14,
        //border: 'none'
    },
}))(TableCell);



const columns = [
    {
        id: 'qc',
        label: 'QC',
        minWidth: 60,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },

    {
        id: 'leadId',
        label: 'Lead Id',
        minWidth: 60,
        align: 'center',
        format: (value) => value.toLocaleString('en-US'),
    },

    {
        id: 'merchantName',
        label: 'Merchant Name',
        minWidth: 60,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
];


const NormalQcUser = React.memo((props) => {
    const history = useHistory()
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [loader, setLoader] = useState(false)
    const [countShow, setCountShow] = useState(false)
    const [dashboardCount, setDashboardCount] = useState('')
    const { internalDashboardState, internalDashboardDispatch } = useContext(GlobalContext);
    const [tableData, setTableData] = useState([])
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [requestLoader, setRequestLoader] = useState(false)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    const requestClick = () => {
        setRequestLoader(true)
        let postData = {
            id: localStorage.getItem('login_id')
        }
        getInternalRequestLeads(postData)(internalDashboardDispatch)
    }

    useEffect(() => {

        getInternalAssignedLeads(localStorage.getItem('login_id'))(internalDashboardDispatch)
    }, [])

    const checkCount = () => {
        setLoader(true)

        getInternalDashboardCount(localStorage.getItem('login_id'))(internalDashboardDispatch)
    }

    useEffect(() => {

        let status = internalDashboardState.getInternalDashboardCount && internalDashboardState.getInternalDashboardCount.data && internalDashboardState.getInternalDashboardCount.status
        if (status == true) {
            console.log('internalDashboardState', internalDashboardState.getInternalDashboardCount)
            setCountShow(true)
            setLoader(false)
            setDashboardCount(internalDashboardState.getInternalDashboardCount.data[0]);
            internalDashboardState.getInternalDashboardCount = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            internalDashboardState.getInternalDashboardCount = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [internalDashboardState])


    useEffect(() => {

        let status = internalDashboardState.getInternalAssignedLeads && internalDashboardState.getInternalAssignedLeads.data && internalDashboardState.getInternalAssignedLeads.status
        if (status == true) {
            console.log('internalDashboardState', internalDashboardState.getInternalAssignedLeads)
            setTableData(internalDashboardState.getInternalAssignedLeads.data);
            internalDashboardState.getInternalAssignedLeads = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)
            internalDashboardState.getInternalAssignedLeads = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [internalDashboardState])


    useEffect(() => {

        let status = internalDashboardState.getInternalRequestLeads && internalDashboardState.getInternalRequestLeads.data && internalDashboardState.getInternalRequestLeads.status
        if (status == true) {
            setRequestLoader(false)
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Added Leads to Bucket",
                showConfirmButton: false,
                timer: 1500,
            });
            getInternalAssignedLeads(localStorage.getItem('login_id'))(internalDashboardDispatch)
            internalDashboardState.getInternalRequestLeads = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            setRequestLoader(false)
            Swal.fire({
                position: "center",
                icon: "warning",
                title: internalDashboardState.getInternalRequestLeads.data.error.errors[0].msg,
            });

            internalDashboardState.getInternalRequestLeads = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [internalDashboardState])

    const openLeadDetail = (id) => {
        localStorage.setItem('lead', id)
        history.push(`${localRoute.leadDetail}`)
    }
    return (
        <div>
            <div className={classes.request} >
                <button className={classes.requestBtn} onClick={requestClick} >
                    {requestLoader == true ? <CircularProgress size={20} /> : "Request New Leads"}
                </button> &nbsp;&nbsp;
                <button className={classes.requestBtn} onClick={checkCount} >
                    {loader == true ? <CircularProgress size={20} /> : "Check Lead Count"}
                </button>
            </div>
            {countShow == true &&
                <>
                    <div className={classes.pLeads}>
                        <Card className={classes.totalApprovedCard} variant="outlined" >
                            <CardContent>
                                <div style={{ fontSize: "18px", color: "#fff", fontWeight: 500 }} >Today Approved Count</div>
                                <div style={{ fontSize: "30px", color: "#fff", fontWeight: "600", marginTop: "15px", width: "100%", display: "flex", justifyContent: "center" }} >{dashboardCount.today_qc_approved}</div>
                            </CardContent>
                        </Card>
                        <Card style={{ background: "linear-gradient(to bottom, #ffcc66 0%, #ff6600 100%)", width: "250px", height: "150px", border: "0.5px solid #F8C95A", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center" }} variant="outlined" >
                            <CardContent>
                                <div style={{ fontSize: "18px", color: "#fff", fontWeight: 500 }} >Today Poor Count</div>
                                <div style={{ fontSize: "30px", color: "#fff", fontWeight: "600", marginTop: "15px", width: "100%", display: "flex", justifyContent: "center" }} >{dashboardCount.today_poor_qc}</div>
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "50px", marginTop: "30px" }} >
                        <Card style={{ background: "linear-gradient(to bottom, #ff99cc 0%, #ff5050 100%)", width: "250px", height: "150px", border: "0.5px solid #F75B77", borderRadius: "10px", display: "flex", alignItems: "center", justifyContent: "center" }} variant="outlined" >
                            <CardContent>
                                <div style={{ fontSize: "18px", color: "#fff", fontWeight: 500 }} >Today Rejected Count</div>
                                <div style={{ fontSize: "30px", color: "#fff", fontWeight: "600", marginTop: "15px", width: "100%", display: "flex", justifyContent: "center" }} >{dashboardCount.today_qc_rejected}</div>
                            </CardContent>
                        </Card>
                    </div>
                </>
            }

            <div >
                <Card className={classes.leadTableCard} variant="outlined" >
                    <CardContent>
                        <div className={classes.tableHeading}>
                            <div className={classes.headingText} >
                                <h3 style={{ color: "#4858e9", borderBottom: "4px solid #4858e9" }}>Amazon Stickering</h3>
                            </div>

                            <div  >
                                <Grid item xs={12}>
                                    {/* <SearchBar
                                        // value={searched}
                                        // onChange={(searchVal) => requestSearch(searchVal)}
                                        // onCancelSearch={() => cancelSearch()}
                                        style={{ border: "0.5px solid #b2b2b2" }}
                                    /> */}
                                </Grid>
                            </div>
                        </div>

                        <div>
                            <div>
                                {tableData.length === 0 ? (
                                    <div style={{ display: "flex", justifyContent: "center" }} >
                                        There are no records to display</div>
                                ) : (
                                    <div  >
                                        <Grid item xs={12} style={{ width: '100%', overflowX: 'auto', display: 'inline-grid' }}>
                                            <TableContainer sx={{ maxHeight: 550 }}>
                                                <Table className={classes.table} stickyHeader aria-label="sticky table" >
                                                    <TableHead>
                                                        <TableRow className={classes.custRow} >
                                                            {columns.map((column) => (
                                                                <StyledTableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth, background: '#eeeeee', color: '#000', fontWeight: 700 }}
                                                                >
                                                                    {column.label}
                                                                </StyledTableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                .map((row, index) => {

                                                                    return (

                                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id} onClick={() => openLeadDetail(row.lead_id)}>
                                                                            <StyledTableCell
                                                                                align="left"

                                                                            >
                                                                                <Link to={`${localRoute.leadDetail}`} style={{ textDecoration: "none" }} >
                                                                                    <div style={{ backgroundColor: "rgba(255,208,25,.349)", color: "#958b00", padding: "5px", maxWidth: "min-content", borderRadius: "10px", fontSize: 10 }} >

                                                                                        {row.qc_status}

                                                                                    </div>
                                                                                </Link>
                                                                            </StyledTableCell>

                                                                            <StyledTableCell align="center">
                                                                                <Link to={`${localRoute.leadDetail}`} style={{ textDecoration: "none" }} >
                                                                                    <div style={{ color: '#000000' }}>
                                                                                        {row.lead_id}
                                                                                    </div>
                                                                                </Link>

                                                                            </StyledTableCell>

                                                                            <StyledTableCell align="right">
                                                                                <Link to={`${localRoute.leadDetail}`} style={{ textDecoration: "none" }} >
                                                                                    <div style={{ color: '#000000', fontWeight: "600" }} >
                                                                                        {row.merchant_name}
                                                                                    </div>
                                                                                </Link>
                                                                            </StyledTableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                    </TableBody>
                                                </Table>
                                                {tableData.length === 0 ?
                                                    <h2 style={{ textAlign: 'center', marginTop: '5%', color: '#1818184a' }}>No Data Found</h2> : ''
                                                }
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={tableData.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>



            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }} >
                            <div><CheckCircleIcon style={{ color: "green", fontSize: "50px" }} /></div>
                            <div style={{ color: "green", fontSize: "40px", fontWeight: "600" }} >Success</div>
                        </div>
                        <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }} >
                            <h1>Added Leads to Bucket</h1>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div >
    );
});

export default withRouter(NormalQcUser);
