import { GET_QC_DOWNLOAD, GET_QC_DOWNLOAD_ERR } from '../../actionTypes/rootType'
import { qcDownloadApi} from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getQcDownload = payload => async dispatch => {
    try {
        ////console.log('payload',payload)
        let results = await axios.get(`${qcDownloadApi}/${payload.qc_admin_id}/${payload.pervious_qc_status}/${payload.current_qc_status}/${payload.date}`, await accessToken())
        ////console.log("User Detail", results)
        let data = results && results.data
        dispatch({
            type: GET_QC_DOWNLOAD,
            payload: data || {},
        });
    } catch (error) {   
        dispatch({
            type: GET_QC_DOWNLOAD_ERR,
            payload: error && error.response
        });
    }
}


