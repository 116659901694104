

export const UPDATE_INTERNAL_QC_SUBMIT = 'UPDATE_INTERNAL_QC_SUBMIT'
export const UPDATE_INTERNAL_QC_SUBMIT_ERR = 'UPDATE_INTERNAL_QC_SUBMIT'
export const GET_INTERNAL_ASSIGNED_LEADS = 'GET_INTERNAL_ASSIGNED_LEADS'
export const GET_INTERNAL_ASSIGNED_LEADS_ERR = 'GET_INTERNAL_ASSIGNED_LEADS_ERR'
export const GET_INTERNAL_DASHBOARD_COUNT = 'GET_INTERNAL_DASHBOARD_COUNT'
export const GET_INTERNAL_DASHBOARD_COUNT_ERR = 'GET_INTERNAL_DASHBOARD_COUNT_ERR'
export const GET_INTERNAL_REQUEST_LEADS = 'GET_INTERNAL_REQUEST_LEADS'
export const GET_INTERNAL_REQUEST_LEADS_ERR = 'GET_INTERNAL_REQUEST_LEADS_ERR'
