import VendorReport from "../vendorReport/VendorReport";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import "./DashboardStyle";
import moment from "moment";
import Card from "@mui/material/Card";
import styles from "./DashboardStyle";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { GlobalContext } from "context/GlobalState";
import CircularProgress from "@material-ui/core/CircularProgress";
import CountDialog from "./CountDialog";
import Button from "@mui/material/Button";
import SearchBar from "material-ui-search-bar";
import { getQcDashboardCount } from "context/actions/rootAction/getQcDashboardCount";
import { localRoute } from "routes/localRoutes";
import { useHistory } from "react-router";
import Divider from '@mui/material/Divider';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { vendorStatsReset } from "context/actions/vendorAction/vendorStatesReset";
import { getReasonCodeData } from "context/actions/reasonCodeAction/getReasonCodeAction";
import { getVendorReportStats } from "context/actions/vendorAction/getVendorReportStats";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LinearProgress from '@mui/material/LinearProgress';
import { getAgencies } from "context/actions/agencyAction/getAgencies";
import MyComponent from 'react-fullpage-custom-loader'
import BlockFse from "./BlockFse";



function Home({ setLoading }) {
  const history = useHistory();
  const [internalLeads, setInternalLeads] = useState(0);
  const [externalLeads, setExternalLeads] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [correction, setCorrection] = useState(0);
  const [allLeads, setallLeads] = useState({});
  const [searchedAll, setSearchedAll] = useState("");
  const { rootState, rootDispatch, vendorDispatch, agencyState,
    agencyDispatch,
    reasonCodeState,
    reasonCodeDispatch,
    vendorState, } = useContext(GlobalContext);
  const [loader, setLoader] = React.useState(false);
  const [countShow, setCountShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [blockFSEopen, setBlockFSEopen] = useState(false);

  const [Fromvalue, setFromValue] = React.useState("");
  const [Tovalue, setToValue] = React.useState("");
  var todayDate = new Date();
  const [fromDate, setFromDate] = useState(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
  const [toDate, setToDate] = useState(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));

  const [dateValue, setDateValue] = useState(fromDate);
  const [agencyLoader, setAgencyLoader] = useState(true);
  const [agencyArray, setAgencyArray] = React.useState([]);
  const [selectedAgency, setSelectedAgency] = useState("all");
  const [dashboardCounterLoader, setDashboardCounterLoader] = useState(true)





  // const [dateValue, setDateValue] = useState(fromDate);
  // var todayDate = new Date();
  // const [fromDate, setFromDate] = useState(new Date(todayDate.getFullYear(), todayDate.getMonth(), 1));
  // const [toDate, setToDate] = useState(new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0));

  const monthvalue = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [monthval, setMonthVal] = React.useState(monthvalue[Number(new Date().getMonth())])
  const [reasonCodeLoader, setReasonCodeLoader] = useState(true);
  const [vendorLoader, setVendorLoader] = useState(true);
  // const [selectedAgency, setSelectedAgency] = useState("all");
  const [vendorData, setVendorData] = useState([]);

  const handleBlockFSEOpen = () => {
    setBlockFSEopen(true);
  };

  const handleBlockFSEClose = () => {
    setBlockFSEopen(false);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };

  // useEffect(() => {

  //   var newfromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
  //   var newToDate = moment(new Date(toDate)).format("YYYY-MM-DD");

  //   getQcDashboardCount({ startDate: newfromDate, endDate: newToDate, vendorId: selectedAgency })(rootDispatch);
  // }, []);

  const handleClose = (value) => {
    console.log("value", value);
    setLoader(true);

    let from = moment(value.from).format("YYYY-MM-DD");
    let to = moment(value.to).format("YYYY-MM-DD");
    setFromValue(from);
    setToValue(to);
    setOpen(false);
    getQcDashboardCount({ startDate: from, endDate: to, vendorId: selectedAgency })(rootDispatch);
  };

  useEffect(() => {
    console.log("rootState", rootState.getQcDashboardCount);
    let status =
      rootState.getQcDashboardCount &&
      rootState.getQcDashboardCount.data &&
      rootState.getQcDashboardCount.status;
    if (status == true) {
      setCountShow(true);
      setDashboardCounterLoader(false)
      setLoader(false);
      setallLeads(rootState.getQcDashboardCount.data[0]);
      setExternalLeads(
        Number(
          rootState.getQcDashboardCount.data[0].total_stage_1_green_leads
        ) +
        Number(
          rootState.getQcDashboardCount.data[0].total_stage_1_red_leads
        ) +
        Number(
          rootState.getQcDashboardCount.data[0].total_stage_1_yellow_leads
        )
      );
      setInternalLeads(
        Number(
          rootState.getQcDashboardCount.data[0].total_final_green_leads +
          rootState.getQcDashboardCount.data[0].total_final_red_leads +
          rootState.getQcDashboardCount.data[0].total_final_yellow_leads
        )
      );
      let total_correction_value =
        Number(rootState.getQcDashboardCount.data[0].green_to_yellow) +
        Number(rootState.getQcDashboardCount.data[0].yellow_to_green) +
        Number(rootState.getQcDashboardCount.data[0].green_to_red) +
        Number(rootState.getQcDashboardCount.data[0].red_to_green) +
        Number(rootState.getQcDashboardCount.data[0].yellow_to_red) +
        Number(rootState.getQcDashboardCount.data[0].red_to_yellow);

      let total_internal_leads =
        Number(rootState.getQcDashboardCount.data[0].total_final_green_leads) +
        Number(rootState.getQcDashboardCount.data[0].total_final_red_leads) +
        Number(rootState.getQcDashboardCount.data[0].total_final_yellow_leads);

      let correctionRatio =
        (total_correction_value / total_internal_leads) * 100;

      let NanStatus = Number.isNaN(correctionRatio);

      console.log("NanStatus", NanStatus);
      if (NanStatus != true) {
        setCorrection(correctionRatio);
      }

      let accuracyValue =
        (Number(
          rootState.getQcDashboardCount.data[0].total_stage_1_green_leads +
          rootState.getQcDashboardCount.data[0].total_stage_1_red_leads +
          rootState.getQcDashboardCount.data[0].total_stage_1_yellow_leads
        ) - Number(
          rootState.getQcDashboardCount.data[0].corrected_value
        )) * 100 /
        Number(
          rootState.getQcDashboardCount.data[0].total_stage_1_green_leads +
          rootState.getQcDashboardCount.data[0].total_stage_1_red_leads +
          rootState.getQcDashboardCount.data[0].total_stage_1_yellow_leads
        )

      console.log("accuracyValue", accuracyValue);
      let NanStatus1 = Number.isNaN(accuracyValue);

      console.log("NanStatus", NanStatus1);
      if (NanStatus1 != true) {
        setAccuracy(accuracyValue);
      }

      rootState.getQcDashboardCount = {
        loading: false,
        error: null,
        data: null,
      };
    }

    // console.log("rootState.getQcDashboardCount", rootState.getQcDashboardCount)
    if (status === 401) {
      // setLoader(false)

      rootState.getQcDashboardCount = {
        loading: false,
        error: null,
        data: [],
      };
    }
  }, [rootState]);

  const requestSearchAll = (searchedVal) => {
    console.log(searchedVal);
    const re = /^[0-9\b]+$/;
    if (re.test(searchedVal)) {
      setSearchedAll(searchedVal);
    }
  };

  const cancelSearchAll = () => {
    setSearchedAll("");
  };

  const handleClick2 = () => {
    // setLoader(true)
  };

  const redirectLead = (data) => {
    history.push(`${localRoute.leadDetail}/${data}`);
  };
  const handleDateChange = (newValue) => {
    console.log(newValue, 'newnewnew')


    setDateValue(newValue);
    setDashboardCounterLoader(true)

    var date = newValue;
    var monthNum = date.getMonth();
    setMonthVal(monthvalue[monthNum])
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setToDate(lastDay)
    setFromDate(firstDay);

    setReasonCodeLoader(true);
    setVendorLoader(true);

    vendorStatsReset(true)(vendorDispatch)

    var newfromDate = moment(new Date(firstDay)).format("YYYY-MM-DD");
    var newToDate = moment(new Date(lastDay)).format("YYYY-MM-DD");
    getQcDashboardCount({ startDate: newfromDate, endDate: newToDate, vendorId: selectedAgency })(rootDispatch)
    getReasonCodeData({ firstDay: newfromDate, lastDay: newToDate, vendorId: selectedAgency })(reasonCodeDispatch);
    getVendorReportStats({ dateFrom: newfromDate, dateTo: newToDate, vendorId: selectedAgency })(vendorDispatch)
  }
  const handleAgencyChange = (event) => {
    setDashboardCounterLoader(true)
    setVendorData([]);
    setSelectedAgency(event.target.value);
    setVendorLoader(true);
    vendorStatsReset(true)(vendorDispatch)

    if(moment(new Date(fromDate)).format("YYYY-MM-DD")==moment(new Date()).format("YYYY-MM-DD"))
    {
      var newfromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
      var newToDate = moment(new Date(toDate)).format("YYYY-MM-DD");
      var myCurrentDate=new Date();
      var myPastDate=new Date(myCurrentDate);
        myPastDate.setDate(myPastDate.getDate() - 2);
        console.log('myPastDate',myPastDate)
        newfromDate = moment(new Date(myPastDate)).format("YYYY-MM-DD");
        getQcDashboardCount({ startDate: newfromDate, endDate: newToDate, vendorId: event.target.value })(rootDispatch)
        getReasonCodeData({ firstDay: newfromDate, lastDay: newToDate, vendorId: event.target.value })(reasonCodeDispatch);
        getVendorReportStats({ dateFrom: newfromDate, dateTo: newToDate, vendorId: event.target.value })(vendorDispatch);
    }
    else
    {
      var newfromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
      var newToDate = moment(new Date(toDate)).format("YYYY-MM-DD");
      getQcDashboardCount({ startDate: newfromDate, endDate: newToDate, vendorId: event.target.value })(rootDispatch)
      getReasonCodeData({ firstDay: newfromDate, lastDay: newToDate, vendorId: event.target.value })(reasonCodeDispatch);
      getVendorReportStats({ dateFrom: newfromDate, dateTo: newToDate, vendorId: event.target.value })(vendorDispatch);
    }


  }

  useEffect(() => {
    let status =
      agencyState && agencyState.getAgencies && agencyState.getAgencies.status;
    if (status === true) {
      setAgencyLoader(false);
      setAgencyArray(agencyState.getAgencies.data.res);
    }
  }, [agencyState]);

  useEffect(() => {
    const page = "all";
    getAgencies({ page })(agencyDispatch);

    if(moment(new Date(fromDate)).format("YYYY-MM-DD")==moment(new Date()).format("YYYY-MM-DD"))
    {
      var newfromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
      var newToDate = moment(new Date(toDate)).format("YYYY-MM-DD");
      var myCurrentDate=new Date();
      var myPastDate=new Date(myCurrentDate);
        myPastDate.setDate(myPastDate.getDate() - 2);
        console.log('myPastDate',myPastDate)
        newfromDate = moment(new Date(myPastDate)).format("YYYY-MM-DD");
        getReasonCodeData({ firstDay: newfromDate, lastDay: newToDate, vendorId: selectedAgency })(reasonCodeDispatch);
    getVendorReportStats({ dateFrom: newfromDate, dateTo: newToDate, vendorId: selectedAgency })(vendorDispatch);
    getQcDashboardCount({ startDate: newfromDate, endDate: newToDate, vendorId: selectedAgency })(rootDispatch);
    }
    else
    {
      var newfromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
      var newToDate = moment(new Date(toDate)).format("YYYY-MM-DD");
      getReasonCodeData({ firstDay: newfromDate, lastDay: newToDate, vendorId: selectedAgency })(reasonCodeDispatch);
      getVendorReportStats({ dateFrom: newfromDate, dateTo: newToDate, vendorId: selectedAgency })(vendorDispatch);
      getQcDashboardCount({ startDate: newfromDate, endDate: newToDate, vendorId: selectedAgency })(rootDispatch);
    }


   

  }, []);

  return (
    <>
      <div>
        {dashboardCounterLoader && <MyComponent sentences={['loading']} height='100%' />}
        <div>
          <div style={{ float: "left" }}>
            {/* Date Filter : {moment(Fromvalue).format("DD-MM-YYYY")} TO{" "}
            {moment(Tovalue).format("DD-MM-YYYY")}{" "} */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  label="Select Month"
                  inputFormat="MM/yyyy"
                  views={['year', 'month']}
                  value={dateValue}
                  disableFuture
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <div>
              <Button
              size="large"
                flexDirection="row"
                variant="outlined"
                style={{
                  textTransform: "none",
                  background: '#141e2d',
                  color: '#fff',
                  float: "right",
                  marginRight: "20px"
                }}
                onClick={handleBlockFSEOpen}
              >
                Block FSE
              </Button>
            </div>

            <SearchBar
              style={{ float: "right", border: "0.5px solid #b2b2b2" }}
              placeholder="Search Lead ID"
              value={searchedAll}
              onChange={(searchVal) => requestSearchAll(searchVal)}
              onCancelSearch={() => cancelSearchAll()}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  console.log(e.target.value);
                  history.push(`${localRoute.leadDetail}/${e.target.value}`);
                }
              }}
            />
            &nbsp; &nbsp; &nbsp;
            <div style={{ float: 'right', marginTop: '-6px' }}>
              <FormControl sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select Agency
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={selectedAgency}
                  onChange={handleAgencyChange}
                  autoWidth
                  label="Select Agency"
                >
                  {agencyLoader && (
                    <MenuItem
                      value=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <CircularProgress size={20} />{" "}
                    </MenuItem>
                  )}

                  <MenuItem value="all">All Agencies</MenuItem>
                  {agencyArray &&
                    agencyArray.map((option) => (
                      <MenuItem key={option.agency_id} value={option.agency_id}>
                        {option.agency_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            {/* <Button
              onClick={() => {
                handleClick2();
                handleClickOpen();
              }}
              variant="contained"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                background: "#121E2E",
              }}
            >
              {" "}
              {loader == true ? <CircularProgress size={20} /> : "Filter Stats"}
            </Button> */}

          </div>
          <br />
        </div>
        <div></div>
      </div>
      <Card style={{ padding: 20, marginBottom: 10 }} elevation={0}>
        <div>
          <div
            className={styles.cards}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div
              style={{
                color: "white",
                background: "#236A97",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "600",
                padding: "10px",
                width: "100%",
                minHeight: "100px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div style={{ fontSize: 16 }}>Total Leads</div>
                <div>{allLeads.total_leads}</div>
              </div>
            </div>



            <div
              style={{
                color: "white",
                background: "grey",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "600",
                padding: "10px",
                width: "100%",
                minHeight: "100px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <div style={{ fontSize: 14 }}> External Audited Leads</div>
                <div>{externalLeads}</div>
              </div>
              <hr style={{ width: '100%' }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "190px",
                }}
              >
                <div style={{ fontSize: 10 }}>
                  <div>Pending</div>
                  <div style={{ fontSize: 10 }}>{allLeads.total_stage_1_pending_leads}</div>
                </div>
                <div style={{ width: '100%', borderLeft: '1px solid white', marginLeft: '7px' }}></div>
                <div style={{ fontSize: 10 }}>
                  <div>Red</div>
                  <div>{allLeads.total_stage_1_red_leads}</div>
                </div>
                <div style={{ width: '100%', borderLeft: '1px solid white', marginLeft: '7px' }}></div>
                <div style={{ fontSize: 10 }}>
                  <div>Green</div>
                  <div>{allLeads.total_stage_1_green_leads}</div>
                </div>
                <div style={{ width: '100%', borderLeft: '1px solid white', marginLeft: '7px' }}></div>
                <div style={{ fontSize: 10 }}>
                  <div>Yellow</div>
                  <div>{allLeads.total_stage_1_yellow_leads}</div>
                </div>

              </div>
            </div>


            <div
              style={{
                color: "white",
                background: "teal",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "600",
                padding: "10px",
                width: "100%",
                minHeight: "100px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <div style={{ fontSize: 14 }}> Internal Audited Leads</div>
                <div>{internalLeads}</div>
              </div>
              <hr style={{ width: '100%' }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "190px",
                }}
              >
                <div style={{ fontSize: 10 }}>
                  <div>Pending</div>
                  <div style={{ fontSize: 10 }}>{allLeads.total_final_pending_leads}</div>
                </div>
                <div style={{ width: '100%', borderLeft: '1px solid white', marginLeft: '7px' }}></div>
                <div style={{ fontSize: 10 }}>
                  <div>Red</div>
                  <div>{allLeads.total_final_red_leads}</div>
                </div>
                <div style={{ width: '100%', borderLeft: '1px solid white', marginLeft: '7px' }}></div>
                <div style={{ fontSize: 10 }}>
                  <div>Green</div>
                  <div>{allLeads.total_final_green_leads}</div>
                </div>
                <div style={{ width: '100%', borderLeft: '1px solid white', marginLeft: '7px' }}></div>
                <div style={{ fontSize: 10 }}>
                  <div>Yellow</div>
                  <div>{allLeads.total_final_yellow_leads}</div>
                </div>

              </div>
            </div>


            <div
              style={{
                color: "white",
                background: "rgb(209 182 67)",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "600",
                padding: "10px",
                width: "100%",
                minHeight: "100px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div style={{ fontSize: 16 }}>Accuracy Range</div>
                <div>{accuracy.toFixed(2)}%</div>
              </div>
            </div>

            <div
              style={{
                color: "white",
                background: "rgb(136 49 56)",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "600",
                padding: "10px",
                width: "100%",
                minHeight: "100px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div style={{ fontSize: 16 }}>Correction Range</div>
                <div>{correction.toFixed(2)}%</div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <VendorReport monthval={monthval} reasonCodeLoader={reasonCodeLoader} setReasonCodeLoader={setReasonCodeLoader} vendorLoader={vendorLoader} setVendorLoader={setVendorLoader} vendorData={vendorData} setVendorData={setVendorData} setDashboardCounterLoader={setDashboardCounterLoader} dashboardCounterLoader={dashboardCounterLoader} />

      {/* <CountDialog open={open} handleClose={handleClose} /> */}

      <BlockFse
        open={blockFSEopen}
        handleClose={handleBlockFSEClose}
      />

    </>
  );
}

export default Home;
