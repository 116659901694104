import { UPDATE_EXTERNAL_QC_SUBMIT, UPDATE_EXTERNAL_QC_SUBMIT_ERR } from 'context/actionTypes/externalDashboardType';
import { updateExternalQcSubmitApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const updateExternalQcSubmit = (payload) => async (dispatch) => {
    try {
        //console.log(payload)
        let results = await axios.put(`${updateExternalQcSubmitApi}`, payload, await accessToken())
        //console.log(results)
        let data = results && results.data
        //console.log('UPDATE STEP 1 JOB', data)
        dispatch({
            type: UPDATE_EXTERNAL_QC_SUBMIT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_EXTERNAL_QC_SUBMIT_ERR,
            payload: error.response,
        });
    }
};