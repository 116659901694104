import React, { useState, useEffect, useContext } from "react";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { styles } from "./LeadDetailsStyle";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@mui/material/Grid';
import timeImg from "../../../assets/images/time.svg"
import { Button, IconButton, Tooltip } from "@material-ui/core";
import Analysis from "../analysis/Analysis";
// import Header from "components/header";
import Swal from "sweetalert2";
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GlobalContext } from "context/GlobalState";
import LeadDetailsImagesModal from "./LeadDetailsImagesModal";
import { getLead } from "context/actions/leadAction/getLead";
import { getAnalysis } from "context/actions/leadAction/getAnalysis";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getReasonList } from "context/actions/leadAction/getReasonList";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import TextField from '@mui/material/TextField';
import DoneIcon from '@mui/icons-material/Done';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from "@mui/material/InputAdornment";
import { updateQcAllSubmit } from "context/actions/qcAction/updateQcAllSubmit";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { getQcLeadDetail } from "context/actions/qcAction/getQcLeadDetail";
import { updateLead } from "context/actions/leadAction/updateLead";
import { updateInternalQcSubmit } from "context/actions/internalDashboardAction/updateInternalQcSubmit";
import { updateLeadReasonSubmit } from "context/actions/qcAction/updateLeadReasonSubmit";
const useStyles = makeStyles(styles);



const LeadDetails = React.memo((props) => {

    const params = useParams()
    const classes = useStyles();
    const [storeTagValue, setStoreTagValue] = useState('');
    const [reasonList, setReasonList] = useState("");
    const [loader, setLoader] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [imageType, setImageType] = useState('');
    const history = useHistory()
    const [helperText, setHelperText] = React.useState('');
    const [value, setValue] = React.useState('');
    const [remarkValue, setRemarkValue] = React.useState('');
    const [shopName, setShopName] = React.useState('');
    const { leadState, leadDispatch } = useContext(GlobalContext);
    const { qcState, qcDispatch } = useContext(GlobalContext);
    const [leadStatus, setLeadStatus] = useState('');
    const [leadDetail, setLeadDetail] = useState({})
    const [editShopName, setEditShopName] = useState(false);
    const { internalDashboardState, internalDashboardDispatch } = useContext(GlobalContext);
    const handleChange = (event) => {
        setRemarkValue(event.target.value);
    };


    const handleRadioChange = (event) => {
        setValue(event.target.value);
        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "lead_id": leadDetail.lead_id,
            "ssr_poster_status": event.target.value,
            "stage_1_ssr_poster_status": event.target.value
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        console.log('requestOptions', requestOptions)

        return fetch("https://amazon.web.api.taskmo.co/api/update-lead", requestOptions)
            .then(response => {
                setHelperText(' ');
            })
            .catch(error => {

            })


    };

    const handleStoreTagChange = (event) => {
        setStoreTagValue(event.target.value);
        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "lead_id": leadDetail.lead_id,
            "stage_1_store_tag_status": event.target.value,
            "store_tag_status": event.target.value
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        console.log('requestOptions', requestOptions)

        return fetch("https://amazon.web.api.taskmo.co/api/update-lead", requestOptions)
            .then(response => {
                setHelperText(' ');
            })
            .catch(error => {

            })


    };

    const checkImage = (value, type) => {
        setImageUrl(value)
        setImageType(type)
    }

    useEffect(() => {
        getLead(params.lead_id)(leadDispatch)
        getReasonList()(leadDispatch)
        getQcLeadDetail(params.lead_id)(qcDispatch)
        getAnalysis(params.lead_id)(leadDispatch)
    }, [])

    useEffect(() => {
        // console.log('leadState', leadState.getLead)
        let status = leadState.getLead && leadState.getLead.data && leadState.getLead.status
        if (status == true) {

            console.log("leadState.getLead", leadState.getLead)
            setShopName(leadState.getLead.data.shop_name)
            setLeadDetail(leadState.getLead.data)

            leadState.getLead = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            leadState.getLead = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [leadState])

    useEffect(() => {
        // console.log('qcState', qcState.getQcLeadDetail)
        let status = qcState.getQcLeadDetail && qcState.getQcLeadDetail.data && qcState.getQcLeadDetail.status
        if (status == true) {

            console.log("leadState.getQcLeadDetail", qcState.getQcLeadDetail)
            setLeadStatus(qcState.getQcLeadDetail.data[0])
            qcState.getQcLeadDetail = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            qcState.getQcLeadDetail = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [qcState])


    useEffect(() => {
        console.log('Stage1 submit', internalDashboardState.updateInternalQcSubmit)
        let status = internalDashboardState.updateInternalQcSubmit && internalDashboardState.updateInternalQcSubmit.status
        if (status == true) {
            setLoader(false)
            console.log("leadState.updateInternalQcSubmit", internalDashboardState.updateInternalQcSubmit)
            Swal.fire(
                'Lead Submitted',
                'Lead has been Submitted.',
                'success'
            )
            getQcLeadDetail(params.lead_id)(qcDispatch)
            internalDashboardState.updateInternalQcSubmit = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            internalDashboardState.updateInternalQcSubmit = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [internalDashboardState])



    useEffect(() => {
        // console.log('qcState', qcState.getQcLeadDetail)
        let status = qcState.updateLead && qcState.updateLead.data && qcState.updateLead.status
        if (status == true) {

            console.log("leadState.updateLead", qcState.updateLead.data)
            setShopName(qcState.updateLead.data.shop_name)
            setLeadDetail(qcState.updateLead.data)
            setEditShopName(false);
            qcState.updateLead = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            qcState.updateLead = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [qcState])


    useEffect(() => {
        // console.log('leadState', qcState.updateQcAllSubmit)
        let status = qcState.updateQcAllSubmit && qcState.updateQcAllSubmit.status
        if (status == true) {

            console.log("leadState.updateQcAllSubmit", qcState.updateQcAllSubmit)
            Swal.fire(
                'Lead Submitted',
                'Lead has been Submitted.',
                'success'
            )
            getQcLeadDetail(params.lead_id)(qcDispatch)
            qcState.updateQcAllSubmit = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            qcState.updateQcAllSubmit = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [qcState])

    useEffect(() => {
        // console.log('leadState', leadState.getReasonList)
        let status = leadState.getReasonList && leadState.getReasonList.data && leadState.getReasonList.status
        if (status == true) {

            console.log("leadState.getReasonList", leadState.getReasonList)
            setReasonList(leadState.getReasonList.data)
            leadState.getLead = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            leadState.getLead = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [leadState])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (value == '') {
            setHelperText('Please select any one option');
        } else {
            if (remarkValue.id == "16") {
                Swal.fire({
                    title: 'Enter the Duplicate Lead ID',
                    input: 'number',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Update',
                    showLoaderOnConfirm: true,

                    preConfirm: (login) => {

                        // console.log('logintest', login)

                        if (login) {
                            if (login.charAt(0) == '0') {
                                Swal.showValidationMessage(
                                    `Entered number cannot be start with 0`
                                )
                            } else if (login.length > 7) {
                                Swal.showValidationMessage(
                                    `Entered Number cannot be more than 7`
                                )
                            } else if (login == leadDetail.lead_id) {
                                Swal.showValidationMessage(
                                    `Entered Number cannot be equal to Lead Id`
                                )
                            } else {
                                var myHeaders = new Headers();
                                myHeaders.append("Content-Type", "application/json");

                                var raw = JSON.stringify({
                                    "lead_id": leadDetail.lead_id,
                                    "duplicate_ref_lead_id": login
                                });

                                var requestOptions = {
                                    method: 'PATCH',
                                    headers: myHeaders,
                                    body: raw,
                                    redirect: 'follow'
                                };

                                return fetch("https://amazon.web.api.taskmo.co/api/update-lead", requestOptions)
                                    .then(response => {
                                        if (!response.ok) {
                                            throw new Error(response.statusText)
                                        }
                                        return response.json()
                                    })
                                    .catch(error => {
                                        Swal.showValidationMessage(
                                            `Request failed: ${error}`
                                        )
                                    })
                            }
                        }
                        else {
                            Swal.showValidationMessage(
                                `Please Enter the Lead ID`
                            )
                        }
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if (leadStatus && leadStatus.stage_1_qc_status == "0" && leadStatus.final_qc_status == "0") {
                                let postData = {
                                    lead_id: leadDetail.lead_id,
                                    id: localStorage.getItem('login_id'),
                                    qc_remark: remarkValue.reason,
                                    qc_status: remarkValue.subcode,
                                    agency_id: leadDetail.agency_id,
                                    qc_reason_code: remarkValue.id,
                                    ref_lead_id: leadDetail.ref_lead_id
                                }


                                Swal.fire({
                                    title: 'Are you sure want to update this status?',
                                    html: '<b style="margin-bottom:"10px"">Reason</b> : &nbsp;' + remarkValue.reason + '<br /><br /><b>Color Code</b> : &nbsp;' + remarkValue.color + '<br />',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    // confirmButtonText: 'Yes, delete it!'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        // setLoader(true)
                                        console.log('postData', postData)
                                        updateQcAllSubmit(postData)(qcDispatch)

                                    }
                                })
                            }
                            else if (leadStatus && leadStatus.stage_1_qc_status != "0" && leadStatus.final_qc_status == "0") {
                                console.log('else if')
                                let postData = {
                                    lead_id: leadDetail.lead_id,
                                    id: localStorage.getItem('login_id'),
                                    qc_remark: remarkValue.reason,
                                    qc_status: remarkValue.subcode,
                                    agency_id: leadDetail.agency_id,
                                    qc_reason_code: remarkValue.id,
                                    ref_lead_id: leadDetail.ref_lead_id
                                }


                                Swal.fire({
                                    title: 'Are you sure want to update this status?',
                                    html: '<b style="margin-bottom:"10px"">Reason</b> : &nbsp;' + remarkValue.reason + '<br /><br /><b>Color Code</b> : &nbsp;' + remarkValue.color + '<br />',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    // confirmButtonText: 'Yes, delete it!'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setLoader(true)
                                        console.log('postData', postData)
                                        updateInternalQcSubmit(postData)(internalDashboardDispatch)

                                    }
                                })

                            }
                            else {
                                console.log('else')
                                let postData = {
                                    lead_id: leadDetail.lead_id,
                                    qc_remark: remarkValue.reason,
                                    qc_status: remarkValue.subcode,
                                    agency_id: leadDetail.agency_id,
                                    qc_reason_code: remarkValue.id,
                                    ref_lead_id: leadDetail.ref_lead_id
                                }

                                Swal.fire({
                                    title: 'Are you sure want to update this status?',
                                    html: '<b style="margin-bottom:"10px"">Reason</b> : &nbsp;' + remarkValue.reason + '<br /><br /><b>Color Code</b> : &nbsp;' + remarkValue.color + '<br />',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    // confirmButtonText: 'Yes, delete it!'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        // setLoader(true)
                                        console.log('postData', postData)
                                        updateLeadReasonSubmit(postData)(qcDispatch)

                                    }
                                })


                            }
                        }
                    })

            }
            else {
                if (leadStatus && leadStatus.stage_1_qc_status == "0" && leadStatus.final_qc_status == "0") {
                    let postData = {
                        lead_id: leadDetail.lead_id,
                        id: localStorage.getItem('login_id'),
                        qc_remark: remarkValue.reason,
                        qc_status: remarkValue.subcode,
                        agency_id: leadDetail.agency_id,
                        qc_reason_code: remarkValue.id,
                        ref_lead_id: leadDetail.ref_lead_id
                    }


                    Swal.fire({
                        title: 'Are you sure want to update this status?',
                        html: '<b style="margin-bottom:"10px"">Reason</b> : &nbsp;' + remarkValue.reason + '<br /><br /><b>Color Code</b> : &nbsp;' + remarkValue.color + '<br />',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        // confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // setLoader(true)
                            console.log('postData', postData)
                            updateQcAllSubmit(postData)(qcDispatch)

                        }
                    })
                }
                else if (leadStatus && leadStatus.stage_1_qc_status != "0" && leadStatus.final_qc_status == "0") {
                    console.log('else if')
                    let postData = {
                        lead_id: leadDetail.lead_id,
                        id: localStorage.getItem('login_id'),
                        qc_remark: remarkValue.reason,
                        qc_status: remarkValue.subcode,
                        agency_id: leadDetail.agency_id,
                        qc_reason_code: remarkValue.id,
                        ref_lead_id: leadDetail.ref_lead_id
                    }


                    Swal.fire({
                        title: 'Are you sure want to update this status?',
                        html: '<b style="margin-bottom:"10px"">Reason</b> : &nbsp;' + remarkValue.reason + '<br /><br /><b>Color Code</b> : &nbsp;' + remarkValue.color + '<br />',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        // confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setLoader(true)
                            console.log('postData', postData)
                            updateInternalQcSubmit(postData)(internalDashboardDispatch)

                        }
                    })

                }
                else {
                    console.log('else')
                    let postData = {
                        lead_id: leadDetail.lead_id,
                        qc_remark: remarkValue.reason,
                        qc_status: remarkValue.subcode,
                        agency_id: leadDetail.agency_id,
                        qc_reason_code: remarkValue.id,
                        ref_lead_id: leadDetail.ref_lead_id
                    }

                    Swal.fire({
                        title: 'Are you sure want to update this status?',
                        html: '<b style="margin-bottom:"10px"">Reason</b> : &nbsp;' + remarkValue.reason + '<br /><br /><b>Color Code</b> : &nbsp;' + remarkValue.color + '<br />',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        // confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // setLoader(true)
                            console.log('postData', postData)
                            updateLeadReasonSubmit(postData)(qcDispatch)

                        }
                    })


                }
            }
        }

    }


    useEffect(() => {
        // console.log('leadState', qcState.updateQcAllSubmit)
        let status = qcState.updateLeadReasonSubmit && qcState.updateLeadReasonSubmit.status
        if (status == true) {

            console.log("leadState.updateLeadReasonSubmit", qcState.updateLeadReasonSubmit)
            Swal.fire(
                'Lead Submitted',
                'Lead has been Submitted.',
                'success'
            )
            getQcLeadDetail(params.lead_id)(qcDispatch)
            qcState.updateLeadReasonSubmit = {
                loading: false,
                error: null,
                data: null,
            }
        }


        if (status === 401) {
            // setLoader(false)

            qcState.updateLeadReasonSubmit = {
                loading: false,
                error: null,
                data: [],
            }
        }


    }, [qcState])


    const hideLightBox = () => {
        setImageUrl(null);
    };


    const hanldeEditShopNameSubmit = () => {


        let postData = {
            lead_id: params.lead_id,
            shop_name: shopName
        }
        console.log('postData', postData)
        updateLead(postData)(qcDispatch)

    }

    const hanldeEditShopName = () => {
        setEditShopName(true);
    }


    const handleInputChange = (e) => {
        setShopName(e.target.value)

    }
    return (
        <>

            <div className={classes.leadDetails} >
                <Analysis />
                <div className={classes.pleadStatus} >
                    <div className={classes.leadStatus} >
                        <div>Lead</div>
                        <div className={classes.leadId}>{leadDetail && leadDetail.lead_id}</div>
                        {/* <div>is</div>
                        <div className={classes.leadCurrentStatus}>Pending</div> */}
                    </div>

                    <div>

                        {/* <Grid xs={12} container style={{ textAlign: 'center' }}>
                            <img
                                src={timeImg}
                                alt="ott"
                            />
                            <p className={classes.timer}>00:{counter}</p>
                        </Grid> */}
                    </div>

                </div>
                <div style={{ fontSize: 12, marginTop: 10, background: "bisque", padding: 10, borderRadius: 10 }}><b>Stage 1</b> Is
                    {leadStatus.stage_1_qc_status === "1" ? <b style={{ color: 'green' }}>&nbsp;Approved</b> : ""}
                    {leadStatus.stage_1_qc_status === "0" ? <b style={{ color: 'grey' }}>&nbsp;Pending</b> : ""}
                    {leadStatus.stage_1_qc_status === "2" ? <b style={{ color: '#838319' }}>&nbsp;Poor</b> : ""}
                    {leadStatus.stage_1_qc_status === "3" ? <b style={{ color: 'red' }}>&nbsp;Rejected</b> : ""}
                    {leadStatus.stage_1_qc_status != 0 && <>&nbsp; By &nbsp;<b>{leadStatus.stage_1_full_name}</b> &nbsp;Reason - <b>{leadStatus.stage_1_qc_remark}</b> - Updated On - <b>{leadStatus.stage_1_qc_updated_on}</b></>}
                </div>

                <div style={{ fontSize: 12, marginTop: 10, background: "bisque", padding: 10, borderRadius: 10 }}><b>Stage 2</b> Is
                    {leadStatus.final_qc_status === "1" ? <b style={{ color: 'green' }}>&nbsp;Approved</b> : ""}
                    {leadStatus.final_qc_status === "0" ? <b style={{ color: 'grey' }}>&nbsp;Pending</b> : ""}
                    {leadStatus.final_qc_status === "2" ? <b style={{ color: '#838319' }}>&nbsp;Poor</b> : ""}
                    {leadStatus.final_qc_status === "3" ? <b style={{ color: 'red' }}>&nbsp;Rejected</b> : ""}
                    {leadStatus.final_qc_status != 0 && <>&nbsp; By &nbsp;<b>{leadStatus.final_full_name}</b> &nbsp;Reason - <b>{leadStatus.final_qc_remark}</b> - Updated On - <b>{leadStatus.final_qc_updated_on}</b></>}
                </div>
                <Card variant="outlined" className={classes.merchantDetails} >
                    <CardContent>
                        <div className={classes.merchantName}>Merchant Name</div>
                        <div>{leadDetail && leadDetail.merchant_name}</div>
                    </CardContent>
                </Card>

                <div className={classes.merchantNote}>
                    <div style={{ color: "#dd42ea;" }} >Note:</div>
                    <div style={{ color: "#4875ef" }} >Check and verify Merchant Name</div>
                </div>

                {/* <div className={classes.viewSimilarImages} >
                    <button className={classes.viewSimilarImagesButton}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >View similar images of user</button>
                </div> */}

                <Card variant="outlined" className={classes.shopDetails} >
                    <CardContent>
                        {editShopName == false &&

                            <div style={{ display: "flex", justifyContent: "space-between" }} >
                                <div className={classes.merchantName} >Shop Name</div>
                                <div onClick={hanldeEditShopName} style={{ cursor: "pointer" }} > <EditIcon /> </div>
                            </div>
                        }
                        {editShopName ?
                            (<div>

                                <TextField
                                    fullWidth
                                    id="input-with-icon-textfield"
                                    label="Shop Name"
                                    value={shopName}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton style={{ padding: 0 }}>
                                                    <Tooltip title="submit">
                                                        <DoneIcon onClick={hanldeEditShopNameSubmit} style={{ fontSize: "20px", cursor: "pointer", color: "black", background: '#3f51b5', padding: 5, borderRadius: 5 }} />
                                                    </Tooltip>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    variant="outlined"
                                    size="medium"
                                    multiline
                                    className={classes.shopNameTextField}
                                    onChange={handleInputChange}
                                />

                            </div>) :
                            (<div>{leadDetail && leadDetail.shop_name}</div>)}

                    </CardContent>
                </Card>

                <div className={classes.merchantNote} >
                    <div style={{ color: "#dd42ea;" }} >Note:</div>
                    <div style={{ color: "#4875ef" }} >Check and verify Shop Name</div>
                </div>

                <Card variant="outlined" className={classes.storeFront}>
                    <CardContent>
                        <div className={classes.storeFrontText}>Store Front image with sticker</div>
                        <div className={classes.storeFrontSticker} onClick={() => checkImage(leadDetail.store_front_image, 'Store Front image with sticker')} >
                            <img src={leadDetail && leadDetail.store_front_image} alt="/" style={{ height: "100px", width: "100px", borderRadius: "5px", cursor: "pointer" }} />
                        </div>
                    </CardContent>
                </Card>

                <div className={classes.merchantNote} >
                    <div style={{ color: "#dd42ea;" }} >Note:</div>
                    <div style={{ color: "#4875ef" }} >Verify Store Front image with sticker</div>
                </div>


                <Card variant="outlined" className={classes.upi}>
                    <CardContent>
                        <div className={classes.upiId} >Upi id</div>
                        <div>{leadDetail && leadDetail.upi_id}</div>
                    </CardContent>
                </Card>

                <div className={classes.merchantNote} >
                    <div style={{ color: "#dd42ea;" }} >Note:</div>
                    <div style={{ color: "#4875ef" }} >Check and verify upi id</div>
                </div>

                <Card variant="outlined" style={{ marginTop: "20px", background: "#855bf533", border: "1px solid #855bf5" }} >
                    <CardContent>
                        <div className={classes.storeFrontText} >Competative QR image</div>
                        <div className={classes.storeFrontSticker} onClick={() => checkImage(leadDetail.competative_qr, 'Competative QR image')} >
                            <img src={leadDetail && leadDetail.competative_qr == '' || leadDetail.competative_qr == 'NA' ? "https://amazonqc.taskmo.com/images/image-unavailable-icon-1.jpeg" : leadDetail.competative_qr} alt="/" style={{ height: "100px", width: "100px", borderRadius: "5px", cursor: "pointer" }} />
                        </div>
                    </CardContent>
                </Card>

                <div className={classes.merchantNote} >
                    <div style={{ color: "#dd42ea;" }} >Note:</div>
                    <div style={{ color: "#4875ef" }} >Verify Competative QR code image</div>
                </div>

                <Card variant="outlined" className={classes.branding} >
                    <CardContent>
                        <div className={classes.brandingText} >Branding material Images</div>
                        <div className={classes.brandingMaterialImages} >
                            <div onClick={() => checkImage(leadDetail.first_branding_image, 'First Branding Material Image')}>
                                <div className={classes.firstBranding} >first branding material image</div>
                                <img src={leadDetail && leadDetail.first_branding_image == '' || leadDetail.first_branding_image == 'NA' ? "https://amazonqc.taskmo.com/images/image-unavailable-icon-1.jpeg" : leadDetail.first_branding_image} alt="/" style={{ height: "100px", width: "100px", borderRadius: "5px", margin: "auto", cursor: "pointer" }} />
                            </div>
                            <div onClick={() => checkImage(leadDetail.second_branding_image, 'Second Branding Material Image')} >
                                <div className={classes.firstBranding} >second branding material image</div>
                                <img src={leadDetail && leadDetail.second_branding_image == '' || leadDetail.second_branding_image == 'NA' ? "https://amazonqc.taskmo.com/images/image-unavailable-icon-1.jpeg" : leadDetail.second_branding_image} alt="/" style={{ height: "100px", width: "100px", borderRadius: "5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </CardContent>
                </Card>


                <div className={classes.merchantNote} >
                    <div style={{ color: "#dd42ea;" }} >Note:</div>
                    <div style={{ color: "#4875ef" }} >Check and Verify Branding material Images</div>
                </div>

                <Card variant="outlined" className={classes.qr} >
                    <CardContent>
                        <div className={classes.qrText} >Amazon QR image</div>
                        <div className={classes.qrImage} onClick={() => checkImage(leadDetail.qr_image, 'Amazon QR image')} >
                            <img src={leadDetail && leadDetail.qr_image == '' || leadDetail.qr_image == 'NA' ? "https://amazonqc.taskmo.com/images/image-unavailable-icon-1.jpeg" : leadDetail.qr_image} alt="/" style={{ height: "100px", width: "100px", borderRadius: "5px", cursor: "pointer" }} />
                        </div>
                    </CardContent>
                </Card>

                <div className={classes.merchantNote} >
                    <div style={{ color: "#dd42ea;" }} >Note:</div>
                    <div style={{ color: "#4875ef" }} >Verify Amazon QR code image</div>
                </div>

                <Card variant="outlined" className={classes.required} >
                    <CardContent>
                        <div className={classes.isRequiredText}>is reqc</div>
                        <div style={{ marginTop: "5px", }} >{leadDetail && leadDetail.is_reqc}</div>
                    </CardContent>
                </Card>

                <div className={classes.merchantNote} >
                    <div style={{ color: "#dd42ea;" }} >Note:</div>
                    <div style={{ color: "#4875ef" }} >SSR Poster Status & Store Tagging Status</div>
                </div>

                <Card variant="outlined" className={classes.required} >
                    <CardContent>
                        <Grid container>
                            <Grid xs={6} item>
                                <div style={{ color: "#4875ef", fontSize: 12 }} >SSR Poster Status </div><hr />
                                <div className={classes.isRequiredText}>Stage 1</div>
                                <div style={{ marginTop: "5px", }} >{leadDetail && leadDetail.stage_1_ssr_poster_status ? leadDetail.stage_1_ssr_poster_status : 'NA'}</div>
                                <div className={classes.isRequiredText}>Stage 2</div>
                                <div style={{ marginTop: "5px", }} >{leadDetail && leadDetail.ssr_poster_status ? leadDetail.ssr_poster_status : 'NA'}</div>
                            </Grid>
                            <Grid xs={6} item>
                                <div style={{ color: "#4875ef", fontSize: 12 }} >Store Tagging Status</div><hr />
                                <div className={classes.isRequiredText}>Stage 1</div>
                                <div style={{ marginTop: "5px", }} >{leadDetail && leadDetail.stage_1_store_tag_status != "none" ? leadDetail.stage_1_store_tag_status : 'NA'}</div>
                                <div className={classes.isRequiredText}>Stage 2</div>
                                <div style={{ marginTop: "5px", }} >{leadDetail && leadDetail.store_tag_status != "none" ? leadDetail.store_tag_status : 'NA'}</div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>





                <form onSubmit={handleSubmit}>
                    <Card variant="outlined" className={classes.LeadRemarks}>
                        <CardContent>
                            <div style={{ fontSize: "16px" }} >Lead remarks</div>
                            <div style={{ marginTop: "10px" }} >

                                <FormControl fullWidth required>
                                    <InputLabel id="demo-simple-select-label">Choose Remark</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={remarkValue}
                                        label="Choose Remark"
                                        onChange={handleChange}
                                    >
                                        {reasonList && reasonList.map((item) => {
                                            return (
                                                <MenuItem value={item}>{item.reason}</MenuItem>
                                            )
                                        })}


                                    </Select>
                                </FormControl>
                            </div><br />
                            {remarkValue &&
                                <>
                                    <div style={{ fontSize: "16px", marginBottom: 10 }} >Store Tagging Status</div>
                                    <FormControl fullWidth required style={{ marginBottom: 20 }}>
                                        <InputLabel id="demo-simple-select-label">Choose Store tagging Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={storeTagValue}
                                            label="Choose Store tagging Status"
                                            onChange={handleStoreTagChange}
                                        >
                                            <MenuItem value='silver'>Silver</MenuItem>
                                            <MenuItem value='brownz'>Brownz</MenuItem>
                                            <MenuItem value='gold'>Gold</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="standard">
                                        <FormLabel id="demo-error-radios">Is SSR poster pasted in the outlet?</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-error-radios"
                                            name="quiz"
                                            value={value}
                                            onChange={handleRadioChange}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                        <FormHelperText style={{ color: 'red' }}>{helperText}</FormHelperText>
                                    </FormControl>

                                </>
                            }
                            <div className={classes.psubmit} >
                                <Button
                                    type="submit"
                                    style={{ textTransform: 'none', color: "#fff", padding: '8px 40px', background: '#141e2d' }}
                                    size="large"
                                >
                                    {
                                        loader ? <CircularProgress color='white' style={{ padding: '2px 10px' }} size={20} /> : "Submit"
                                    }
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </form>
                <Lightbox image={imageUrl} onClose={hideLightBox} title={imageType} />
            </div >
        </>
    );
});

export default withRouter(LeadDetails);
