import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyANdKuHOXNe_SwmzwLB-TAOypl5HeiSAoc",
  authDomain: "react-notification-9517c.firebaseapp.com",
  projectId: "react-notification-9517c",
  storageBucket: "react-notification-9517c.appspot.com",
  messagingSenderId: "556405200866",
  appId: "1:556405200866:web:418abf34c3cfc3c7f6cbbb",
  measurementId: "G-SWJGNZL2FT"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();


const publicKey = process.env.REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  // //console.log('setTokenFound',setTokenFound)
  let currentToken = "";

  //console.log('publicKey',publicKey)
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey});
    if (currentToken) {
      //console.log('currentToken',currentToken)
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    //console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
