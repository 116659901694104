import React from 'react'
import TextField from '@material-ui/core/TextField';

export default function Input(props) {

    const { name, label, value, error = null, onChange,disabled } = props;
    return (
        <TextField
            id="outlined-basic" variant="outlined"
            label={label}
            name={name}
            value={value}
            disabled={disabled}
            fullWidth

            onChange={onChange}
            autoComplete="current-fullname"
            {...(error && { error: true })}
        />
    )
}
