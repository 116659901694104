import { GET_EXTERNAL_REPORTS_STATS,GET_EXTERNAL_REPORTS_STATS_ERR } from '../../actionTypes/rootType'
import { getExternalReportStatsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const getExternalReportStats = payload => async dispatch => {
    try {
        //console.log('payload',payload)
        let results = await axios.get(`${getExternalReportStatsApi}/${payload.date}`, await accessToken())
        //console.log("GET Dashboard Stats", results)
        let data = results && results.data
        dispatch({
            type: GET_EXTERNAL_REPORTS_STATS,
            payload: data || [],
        });
    } catch (error) {
        if ([403].includes(error && error.response && error.response.status)) {
            localStorage.clear();
        }
        dispatch({
            type: GET_EXTERNAL_REPORTS_STATS_ERR,
            payload: error && error.response
        });
    }
}