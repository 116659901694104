import { getVendorReportStatsApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
import { GET_VENDOR_REPORT_STATS, GET_VENDOR_REPORT_STATS_ERR } from 'context/actionTypes/vendorType';

export const getVendorReportStats = payload => async dispatch => {
    try {
        //console.log('payload',payload)
        let results = await axios.get(`${getVendorReportStatsApi}/${payload.vendorId}/${payload.dateFrom}/${payload.dateTo}`, await accessToken())
        let data = results && results.data
        dispatch({
            type: GET_VENDOR_REPORT_STATS,
            payload: data || [],
        });
    } catch (error) {

        dispatch({
            type: GET_VENDOR_REPORT_STATS_ERR,
            payload: error && error.response
        });
    }
}
