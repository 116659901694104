import { UPDATE_INTERNAL_QC_SUBMIT, UPDATE_INTERNAL_QC_SUBMIT_ERR } from 'context/actionTypes/internalDashboardType';
import { updateInternalQcSubmitApi } from 'api/Api';
import axios from "axios";
import { accessToken } from 'api/Token'
export const updateInternalQcSubmit = (payload) => async (dispatch) => {
    try {
        //console.log(payload)
        let results = await axios.put(`${updateInternalQcSubmitApi}`, payload, await accessToken())
        //console.log(results)
        let data = results && results.data
        //console.log('UPDATE STEP 1 JOB', data)
        dispatch({
            type: UPDATE_INTERNAL_QC_SUBMIT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_INTERNAL_QC_SUBMIT_ERR,
            payload: error.response,
        });
    }
};