import React, { useEffect, useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Button, TextField } from '@material-ui/core';
import { localRoute } from "../../routes/localRoutes";
import loginBgImage from '../../assets/images/loginBgImage.svg'
import { styles } from "./ForgotPasswordStyle";
import { GlobalContext } from "../../context/GlobalState";
import { loginUser } from '../../context/actions/userAction/loginUser'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import { Link } from "@material-ui/core";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ForgotPassword1 from "./forgotPasswordForm.jsx";
const useStyles = makeStyles(styles);

const Login = React.memo((props) => {
  //console.log('props', props)
  const { userState, userDispatch } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const handleLogin = async (e) => {
    setMessage("")
    setLoader(true)
    const postData = {
      username: username,
    }
    setTimeout(() => {
      // loginUser(postData)(userDispatch)
    }, 1000)
  }

  function validateForm() {
    return username.length > 0;
  }

  const back = () => {
    history.push('/')
  }


  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={6} spacing={0} style={{ padding: "0px", margin: "0px" }} lg={6}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <div className={classes.loginBg}>
          </div>
        </Grid>
        <Grid item xs={6}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh', background: "#fff" }}
        >
          <ForgotPassword1 />
  
        </Grid>
      </Grid>
    </>
  );
});

export default withRouter(Login);
