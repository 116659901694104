export default {
    blockFse: {
        loading: false,
        error: null,
        data: {},
        status: false,
        message: '',
    },
    fseLead: {
        loading: false,
        error: null,
        data: {},
        status: false,
        message: '',
    }
}